import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NetworkErrorService } from '../network-error.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import {
  PatientDemographics,
  PatientReportImages,
  PatientReportSummary,
} from '../patient';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DermatologyService {
  public mdermUrl = environment.mdermUrl;
  private permissionsUrl = environment.permissionsUrl;

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {}

  getPatientReports(patientId: string): Observable<PatientReportSummary> {
    let params = new HttpParams();
    params = params.append('patientGuid', patientId);
    return this.http
      .get<PatientReportSummary>(`${this.mdermUrl}/api/getReportsForPatient`, {
        params,
      })
      .pipe(
        tap((_) => console.log('fetched patient reports')),
        catchError(
          this.networkErrorService.handleError<PatientReportSummary>(
            'getPatientReports',
            undefined,
            400,
          ),
        ),
      );
  }

  getImagesForReport(
    patientId: string,
    id: number,
  ): Observable<PatientReportImages> {
    let params = new HttpParams();
    params = params.append('patientGuid', patientId).append('reportId', id);

    return this.http
      .get<PatientReportImages>(`${this.mdermUrl}/api/getImagesForReport`, {
        params,
      })
      .pipe(
        tap((_) => console.log('fetched patient report images ')),
        catchError(
          this.networkErrorService.handleError<PatientReportImages>(
            'getImagesForReport',
            undefined,
          ),
        ),
      );
  }
}
