// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview {
  max-width: 400px;
  max-height: 225px;
}

.center {
  display: flex;
  justify-content: center;
}

mat-form-field {
  width: 450px;
}

.spinner {
  max-width: 25px;
  max-height: 25px;
}

.file-input {
  font-size: 20px;
}

.error-text {
  padding-left: 10px;
  color: red;
}

.success-text {
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/data-set-upload/data-set-upload.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".preview {\n    max-width: 400px;\n    max-height: 225px;\n  }\n\n.center {\n    display: flex;\n    justify-content: center;\n  }\n\nmat-form-field {\n    width: 450px;\n  }\n\n.spinner {\n    max-width: 25px;\n    max-height: 25px;\n}\n\n.file-input {\n    font-size: 20px;\n}\n\n.error-text {\n    padding-left: 10px;\n    color: red;\n}\n\n.success-text {\n    padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
