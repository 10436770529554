import { Component } from '@angular/core';
import { AsyncPipe, CommonModule, NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PatientService } from '../../patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterService } from '../../filter.service';
import {
  NewClinician,
  ClinicianCreateService,
} from '../clinician-create.service';

@Component({
  selector: 'app-new-clinician',
  standalone: true,
  imports: [
    MatSelectModule,
    MatTableModule,
    MatListModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    NgIf,
    MatCheckboxModule,
    AsyncPipe,
    NgForOf,
  ],
  templateUrl: './new-clinician.component.html',
  styleUrls: ['./new-clinician.component.scss'],
})
export class NewClinicianComponent {
  clinician: NewClinician = new NewClinician();

  loading: boolean = false;
  error: string = '';

  constructor(
    private clinicianCreateService: ClinicianCreateService,
    public filterService: FilterService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  create(): void {
    this.clinicianCreateService.createClinician(this.clinician).subscribe({
      next: (x) => {

        this.loading = false;

        if( x != undefined )
        {
          this.router.navigate(['/home', 'admin']);
        }
      },
      error: (x) => {
        this.loading = false;
        if ( x.status == 409 ) {
          this.error = 'Could not create clinician, username or email exists.'
        } else {
          this.error = 'Could not create clinician: ' + x.statusText
        }
      },
    });
  }
}
