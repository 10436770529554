import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  PatientReportSummary,
  PatientDescription,
  PatientReport,
  PatientReportImages,
  PatientDemographics,
  ScheduleInfo,
  TestList,
  TestDetails,
  SurveyDetails,
  SurveyResponses,
  SurveyList,
} from './patient';
import { StudyCohort } from './filter.service';
import { DiagnosisTemplate } from './attribute.service';
import { environment } from 'src/environments/environment';
import { NetworkErrorService } from './network-error.service';

export class ListPatients {
  patients: PatientDescription[] = [];
}

export interface PatientReportUpdate {
  patientGuid: string;
  drDiagnosis: DiagnosisTemplate;
}

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  private permissionsUrl = environment.permissionsUrl;

  private patientsUrl = `${this.permissionsUrl}/api/getPatients`; // URL to web api

  // private updatePatientProfileUrl = `${this.permissionsUrl}/api/updatePatientProfile`; // URL to web api

  private membershipUrl = `${this.permissionsUrl}/api/getMembership`; // URL to web api
  private patientDescriotionUrl = `${this.permissionsUrl}/api/getPatient`; // URL to web api
  private patientDemographicsPostUrl = `${this.permissionsUrl}/api/patient/update`;

  private movePatientUrl: string = `${this.permissionsUrl}/api/changeMembership`;

  private setActiveUrl: string = `${this.permissionsUrl}/patient/setActive`;

  private _patients: BehaviorSubject<ListPatients> = new BehaviorSubject(
    new ListPatients(),
  );
  public readonly patients: Observable<ListPatients> =
    this._patients.asObservable();

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {}

  /** GET patients from the server */
  getPatients(): Observable<ListPatients> {
    let params = new HttpParams();

    var x = this.http
      .get<ListPatients>(this.patientsUrl, { params })
      .pipe(
        catchError(
          this.networkErrorService.handleError<ListPatients>(
            'getPatients',
            undefined,
          ),
        ),
      );
    return x;
  }

  /** GET patient details from the server */
  getPatientProfile(patientId: string): Observable<PatientDemographics> {
    let params = new HttpParams();
    params = params.append('guid', patientId);

    return this.http
      .get<PatientDemographics>(this.patientDescriotionUrl, { params })
      .pipe(
        tap((_) => console.log('fetched patient profile')),
        catchError(
          this.networkErrorService.handleError<PatientDemographics>(
            'getPatientProfile',
            undefined,
          ),
        ),
      );
  }

  getCohortForPatient(patientId: string): Observable<StudyCohort> {
    let params = new HttpParams();
    params = params.append('uuid', patientId);

    return this.http.get<StudyCohort>(this.membershipUrl, { params }).pipe(
      tap((_) => console.log('fetched patient cohort')),
      catchError(
        this.networkErrorService.handleError<StudyCohort>(
          'getPatientCohort',
          undefined,
        ),
      ),
    );
  }

  movePatientToCohort(
    selectedPatient: PatientDemographics,
    targetCohortGuid: string,
  ): Observable<StudyCohort> {
    let params = new HttpParams();
    params = params.append('uuid', selectedPatient.uuid);
    params = params.append('cohortId', targetCohortGuid);

    return this.http.get<StudyCohort>(this.movePatientUrl, { params }).pipe(
      tap((_) => console.log('movePatientToCohort')),
      catchError(
        this.networkErrorService.handleError<StudyCohort>(
          'movePatientToCohort',
          undefined,
        ),
      ),
    );
  }

  updatePatient(
    patientId: string,
    demographics: PatientDemographics,
  ): Observable<PatientDemographics> {
    let url = this.patientDemographicsPostUrl.concat('/', patientId);
    let data = JSON.stringify(demographics);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8',
    );

    return this.http
      .post<PatientDemographics>(url, data, { headers: headers })
      .pipe(
        tap((_) => console.log('saved Patient Demographics')),
        catchError(
          this.networkErrorService.handleError<PatientDemographics>(
            'updatePatient',
            undefined,
          ),
        ),
      );
  }

  saveActiveState(patientId: string, active: boolean): Observable<string> {
    let params = { guid: patientId, isActive: active };

    return this.http.post<string>(this.setActiveUrl, params).pipe(
      tap((_) => console.log('set patient state')),
      catchError(
        this.networkErrorService.handleError<string>(
          'saveActiveState',
          undefined,
        ),
      ),
    );
  }

  getDisplayName(
    patient: PatientDemographics,
    includeUsername: boolean = false,
  ): string {
    if (patient.firstName == undefined && patient.lastName == undefined) {
      return `@${patient.username}`;
    } else {
      if (includeUsername) {
        return `${patient.firstName} ${patient.lastName} (@${patient.username})`;
      } else {
        return `${patient.firstName} ${patient.lastName}`;
      }
    }
  }

  getDisplayNameDesc(patient: PatientDescription): string {
    if (patient.firstName == undefined && patient.lastName == undefined) {
      return `@${patient.patientId}`;
    } else {
      return `${patient.firstName} ${patient.lastName}`;
    }
  }

  sync(patient: PatientDemographics) {
    let uuid = patient.uuid;
    let params = new HttpParams();
    params = params.append('uuid', uuid);

    return this.http
      .get<string>(`${this.permissionsUrl}/patient/ensureKeyCloakUser`, {
        params,
      })
      .pipe(
        tap((_) => console.log('Synced keycloak user')),
        catchError(
          this.networkErrorService.handleError<string>(
            'Sync Keycloak',
            undefined,
          ),
        ),
      );
  }

  resetPassword(patient: PatientDemographics) {
    let uuid = patient.uuid;
    let params = new HttpParams();
    params = params.append('uuid', uuid);

    return this.http
      .get<string>(`${this.permissionsUrl}/patient/resetPassword`, { params })
      .pipe(
        tap((_) => console.log('resetPassword for user')),
        catchError(
          this.networkErrorService.handleError<string>(
            'resetPassword',
            undefined,
          ),
        ),
      );
  }
}
