import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientMdermReportListComponent } from '../patient-mderm-report-list/patient-mderm-report-list.component';
import { PatientMindcapHomeComponent } from '../patient-mindcap-home/patient-mindcap-home.component';
import { MatIconModule } from '@angular/material/icon';
import { PatientDemographics, MdermPatientProfile } from '../patient';
import { PatientService } from '../patient.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PatientProfilePopupComponent } from '../patient-profile-popup/patient-profile-popup.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  CognitiveDashboardComponent,
  SelectionButtonInfo,
} from '../Cognitive/cognitive-dashboard/cognitive-dashboard.component';
import { VestibularPatientDashboardComponent } from '../vestibular/vestibular-patient-dashboard/vestibular-patient-dashboard.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { SelectionService } from '../selection.service';
import { KeycloakService } from 'keycloak-angular';
import { Role } from '../guards/role.guard';
import { VestibularReportsComponent } from '../vestibular/vestibular-reports/vestibular-reports.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { FeatureServiceService } from '../feature-service.service';
import { PatientCalendarComponent } from '../patient-calendar/patient-calendar.component';
import { PatientCalendarVestaidComponent } from '../patient-calendar-vestaid/patient-calendar-vestaid.component';

@Component({
  selector: 'app-patient-home',
  standalone: true,
  imports: [
    CommonModule,
    PatientMdermReportListComponent,
    PatientMindcapHomeComponent,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    MatCardModule,
    CognitiveDashboardComponent,
    VestibularPatientDashboardComponent,
    VestibularReportsComponent,
    MatGridListModule,
    MatExpansionModule,
    PatientCalendarComponent,
    PatientCalendarVestaidComponent,
  ],
  templateUrl: './patient-home.component.html',
  styleUrls: ['./patient-home.component.scss'],
})
export class PatientHomeComponent {
  patientId: string;
  profile: MdermPatientProfile = {};
  currentPatient?: PatientDemographics;
  displayName: string = '';
  selectionButtons: SelectionButtonInfo[] = [];
  isHidden: boolean;
  cohortId: string = '';
  studyId: number = 0;

  activeFeatureObs: Observable<String> = this.featureService.activeFeatureObs;
  activeFeature: String = '';

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private dialogRef: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private selectionService: SelectionService,
    private keycloakService: KeycloakService,
    private featureService: FeatureServiceService
  ) {
    this.patientId = this.route.snapshot.paramMap.get('patientGuid') || '';
    this.isHidden = true;
    this.getPatientProfile();
    this.selectionButtons = this.selectionService.getData();
  }

  ngOnInit(): void {
    const isLoggedIn = this.keycloakService.isLoggedIn();
    isLoggedIn.then(() => {
      this.isHidden = !this.keycloakService.isUserInRole(Role.PartnerAdmin);
    });

    this.activeFeatureObs.subscribe((x) => {
      this.activeFeature = x;
    });
  }

  featureChanged(feature: String) {
    if (this.featureService.activeFeature != feature) {
      this.featureService.activeFeature = feature;
    }
  }

  getPatientProfile(): void {
    this.patientService
      .getPatientProfile(this.patientId)
      .subscribe((patient) => {
        this.currentPatient = patient;
        this.cohortId = patient?.cohort.guid || '';
        this.studyId = patient?.study.id || 0;
        if (patient != null) {
          this.displayName = this.patientService.getDisplayName(patient, true);
          this.breadcrumbService.set(
            'home/study/:id/cohort/:id/patient/:id',
            'Patient: ' + patient.username
          );
          this.breadcrumbService.set(
            'home/patient/:id',
            'Patient: ' + patient.username
          );
        }
      });
  }

  showPatientDetails() {
    this.dialogRef.open(PatientProfilePopupComponent, {
      data: {
        patient_profile: this.profile,
        selected_patient: this.currentPatient,
      },
    });
  }
}
