// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card {
  margin: 20px;
  background-color: pink;
}

.outlet {
  flex: 1;
  display: flex;
}

:host {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,sBAAA;AACJ;;AAGA;EACE,OAAA;EACA,aAAA;AAAF;;AAGA;EACI,aAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AAAJ","sourcesContent":["mat-card {\n    margin:20px;\n    background-color: pink;\n}\n\n\n.outlet {\n  flex: 1;\n  display: flex;\n}\n\n:host {\n    display: flex;\n    flex: 1 1 100%;\n    flex-direction: column;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
