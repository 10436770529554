import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkErrorService } from './network-error.service';

export class SystemMessage {
  id: string = '';
  active: boolean = false;
  messageText: string = '';
  oneTime: boolean = false;
}

@Injectable({
  providedIn: 'root',
})
export class SystemMessageService {
  private _messages: BehaviorSubject<SystemMessage[]> = new BehaviorSubject<
    SystemMessage[]
  >([]);
  public readonly messages: Observable<SystemMessage[]> =
    this._messages.asObservable();

  // TODO: Move to config value
  private permissionsUrl = environment.permissionsUrl;
  private systemMessageUrl: string = `${this.permissionsUrl}/api/systemMessagesPortal`; // URL to web api

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {
    // Get messages from server
    this.getMessages();
  }

  /** GET patient details from the server */
  getMessages() {
    let params = new HttpParams();
    this.http
      .get<SystemMessage[]>(this.systemMessageUrl, { params })
      .pipe(
        tap((_) => console.log('fetched system messages')),
        catchError(
          this.networkErrorService.handleError<SystemMessage[]>(
            'getMessages',
            undefined,
          ),
        ),
      )
      .subscribe((messages) => {
        this._messages.next(messages);
        console.log('Got system messages: ' + JSON.stringify(messages));
      });
  }
}
