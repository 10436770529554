import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {BehaviorSubject, catchError, Observable, of, tap, throwError} from 'rxjs';
import { environment } from '../environments/environment';
import {ClinicianDescription, CohortDescription} from './patient';
import { NetworkErrorService } from './network-error.service';

@Injectable({
  providedIn: 'root',
})
export class CohortService {
  cohortUrl: string = environment.permissionsUrl + '/cohort/show';

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {}

  getCohortInfo(cohortId: string): Observable<CohortDescription> {
    return this.http
      .get<CohortDescription>(this.cohortUrl + '?guid=' + cohortId)
      .pipe(
        tap((_) => this.networkErrorService.log('fetched study list')),
        catchError(
          this.networkErrorService.handleError<CohortDescription>(
            'getCohortsByStudy',
            undefined,
          ),
        ),
      );
  }

  getMembership(uuid: any) {

    console.log( "getting for uuid: " + uuid  )

        let params = new HttpParams();
    params = params.append('uuid', uuid);


    return this.http
      .get<CohortDescription[]>(
        environment.permissionsUrl + '/account/getCohortsByUser',
        { params }
      )
      .pipe(
        tap((_) => this.networkErrorService.log('fetched membership list')),
        catchError( errorResponse => {
          console.log( errorResponse )
            this.networkErrorService.handleError<ClinicianDescription>('getCohortsByStudy', undefined);
            return throwError(() => errorResponse);
          }
        ),
      );
  }

  renameCohort(
    cohort: CohortDescription,
    name: string,
  ): Observable<CohortDescription> {
    let params = new HttpParams();
    params = params.append('uuid', cohort.uuid);
    params = params.append('name', name);

    return this.http
      .get<CohortDescription>(
        environment.permissionsUrl + '/cohort/renameCohort',
        { params },
      )
      .pipe(
        tap((_) => this.networkErrorService.log('Completed renameCohort')),
        catchError(
          this.networkErrorService.handleError<CohortDescription>(
            'renameCohort',
            undefined,
          ),
        ),
      );
  }
}
