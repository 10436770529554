import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';

@Component({
  selector: 'app-vjs-player',
  templateUrl: './vjs-player.component.html',
  styleUrls: ['./vjs-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class VjsPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('target') target?: ElementRef = undefined;

  player?: Player;

  _src: string = '';

  @Input()
  set src(f: string) {
    if (this.player && f) {
      console.log('Set file to ' + f);
      this.player.src(f);
    }
    this._src = f;
  }

  // // See options: https://videojs.com/guides/options
  // @Input() options: {
  //   fluid: boolean;
  //   aspectRatio: string;
  //   autoplay: boolean;
  //   sources: {
  //     src: string;
  //     type: string;
  //   }[];
  // };

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    console.log('Creating player with url=' + this._src);
    this.player = videojs(this.target?.nativeElement, {
      sources: {
        src: this._src,
        type: 'video/mp4',
      },
    });
    // this.player.src(this._src);
  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
