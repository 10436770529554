import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NetworkErrorService} from "../network-error.service";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {NewStudy} from "./new-study/new-study.component";
import {StudyDescription} from "../filter.service";
import {environment} from "../../environments/environment";




@Injectable({
  providedIn: 'root'
})
export class StudyCreateService {

  private studyCreateUrl = `${environment.permissionsUrl}/api/createStudy`;  // URL to web api

  constructor(
    private http: HttpClient,
    private networkErrorService : NetworkErrorService
  ) {

  }

  createStudy(study:  NewStudy ) : Observable<StudyDescription> {

    var x = this.http.post<StudyDescription>(this.studyCreateUrl, study )
      .pipe(
        catchError(this.networkErrorService.handleError<StudyDescription>('createStudy', undefined))
      );
    return x;
  }

}
