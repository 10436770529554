// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: auto;
}

.mainBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}`, "",{"version":3,"sources":["webpack://./src/app/main-layout/main-layout.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,cAAA;AACF;;AAEA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,sBAAA;AACJ","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 100%;\n  overflow: auto;\n}\n\n.mainBody {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    align-content: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
