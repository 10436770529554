import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AsyncPipe, CommonModule, NgFor} from '@angular/common';
import {ClinicianDescription} from "../../patient";
import {filter, Observable} from "rxjs";
import {ClinicianService} from "../../clinician.service";
import {MatOptionModule} from "@angular/material/core";
import {MatAutocomplete, MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-clinician-select',
  standalone: true,
  imports: [    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgFor,
    AsyncPipe],
  templateUrl: './clinician-select.component.html',
  styleUrls: ['./clinician-select.component.scss']
})
export class ClinicianSelectComponent implements OnInit {
  myControl = new FormControl('');

  allClinicians : Observable<ClinicianDescription[]> = new Observable<ClinicianDescription[]>();

  @Input()
  exclude : ClinicianDescription[] = []

  @Input()
  cohortUuid : string = "";

  @Output( "onupdate") onupdate = new EventEmitter<ClinicianDescription>();



  @ViewChild('list') list !: MatAutocomplete;

  constructor(
    private clinicianService : ClinicianService
  ) {
  }

  ngOnInit()
  {
    this.allClinicians = this.clinicianService.getAllClinicians( this.exclude )
    .pipe(
       filter( ( array : ClinicianDescription[], idx : number ) => {
         return !array[idx]?.isAdmin && !array[idx]?.isPartnerAdmin
     }));
  }


  formatName( clinician : ClinicianDescription ) : string
  {
    if( !clinician.firstName  && !clinician.lastName )
    {
      return `@${clinician.username}`
    } else {
      return `${clinician.firstName} ${clinician.lastName} (@${clinician.username})`
    }
  }


  UserSelected( event : any )
  {
    this.clinicianService.AddClinicianToCohort( event.option.value.username, this.cohortUuid ).subscribe(
        x =>
        {
            this.onupdate.emit( event.option.value );
        }
    )
  }


}
