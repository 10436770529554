import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { TrainingRequestListService } from '../training-request-list.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgIf, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router, RouterModule } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule, MatSortable } from '@angular/material/sort';

export interface TrainingRequestTable {
  name: string;
  date: string;
  state: string;
  requester: string;
  dataSetIds: string[];
  guid: TrainingRequestURL;
  comment?: string;
  select?: boolean;
  spinner?: boolean;
}

export interface TrainingRequestURL {
  url: string;
}

export interface DialogData {
  comment: string;
}

@Component({
  selector: 'app-training-request-list',
  templateUrl: './training-request-list.component.html',
  styleUrls: ['./training-request-list.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    NgIf,
    NgFor,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule,
    MatCheckboxModule,
    MatSortModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
  ],
})
export class TrainingRequestListComponent implements OnInit {
  dataSource = new MatTableDataSource<TrainingRequestTable>();
  selection = new SelectionModel<TrainingRequestTable>(true, []);
  loading = true;
  displayedColumns: string[] = [
    'select',
    'name',
    'state',
    'datasets',
    'date',
    'comment',
  ];
  trainingRequestInfo = [];
  dataArray: TrainingRequestURL[] = [];
  enableButton = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null =
    null;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private listService: TrainingRequestListService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'date':
          return new Date(item.date).toISOString();
        default:
          return item[property as keyof TrainingRequestTable] as string;
      }
    };
  }

  ngOnInit(): void {
    this.getDataSet();
  }

  getDataSet() {
    const filteredArray: TrainingRequestTable[] = [];
    this.listService.getData().subscribe({
      next: (event: any) => {
        this.trainingRequestInfo = event.body;
        for (let item in this.trainingRequestInfo) {
          filteredArray.push({
            name: this.trainingRequestInfo[item]['name'],
            comment: this.trainingRequestInfo[item]['comment'],
            date: new Date(
              this.trainingRequestInfo[item]['requestDate']
            ).toString(),
            requester: this.trainingRequestInfo[item]['requester'],
            state: this.trainingRequestInfo[item]['state'],
            dataSetIds: this.trainingRequestInfo[item]['dataSetIds'],
            guid: this.trainingRequestInfo[item]['guid'],
            select: false,
            spinner: false,
          });
        }
      },
      error: (err: any) => {
        this.loading = false;
      },
      complete: () => {
        this.dataSource.data = filteredArray;
        this.loading = false;
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  generateURL(dataset: any) {
    dataset.spinner = true;
    this.listService.getURL(dataset.guid).subscribe({
      next: (event: any) => {
        window.location.href = event.body.url;
      },
      error: (err: any) => {
        dataset.spinner = false;
      },
      complete: () => {
        dataset.spinner = false;
      },
    });
  }

  openDialog(patientComments: string): void {
    console.log(patientComments);
    const dialogRef = this.dialog.open(TrainingRequestListComponentDialog, {
      data: { comment: patientComments },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dataSource.data.forEach((row) => {
        row.select = false;
      });
      this.enableButton = false;
    } else {
      this.dataSource.data.forEach((row) => {
        this.selection.select(row);
        row.select = true;
      });
      this.enableButton = true;
    }
  }

  getDisplayedColumns() {
    return this.displayedColumns;
  }

  delete() {
    console.log('delete');
  }

  checkRow(row: any) {
    this.enableButton = true;
    if (row.select == true) {
      this.selection.toggle(row);
      row.select = false;
    } else if (row.select == false) {
      this.selection.toggle(row);
      row.select = true;
    }
    if (this.dataSource.data.every((item) => !item.select)) {
      this.enableButton = false;
    }
  }
}

@Component({
  selector: 'app-training-request-set-list',
  templateUrl: './training-request-list-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
})
export class TrainingRequestListComponentDialog {
  constructor(
    public dialogRef: MatDialogRef<TrainingRequestListComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
