import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientService } from '../../patient.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ClinicianDescription, PatientDemographics } from '../../patient';
import { PatientComponent } from '../../patient/patient.component';
import { ClinicianService } from '../../clinician.service';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { CohortMembershipListComponent } from '../../cohort-membership-list/cohort-membership-list.component';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-clinician-admin',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatCardModule,
    CohortMembershipListComponent,
    RouterLink,
  ],
  templateUrl: './clinician-admin.component.html',
  styleUrls: ['./clinician-admin.component.scss'],
})
export class ClinicianAdminComponent implements OnInit {
  private clinicianUuid: string;

  public profile: Observable<ClinicianDescription> | null = null;

  constructor(
    private clinicianService: ClinicianService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.clinicianUuid = this.route.snapshot.paramMap.get('id') || '';
    this.breadcrumbService.set('@clinicianName', 'Clinician');
  }

  ngOnInit(): void {
    this.profile = this.clinicianService.getClinicianProfile(
      this.clinicianUuid,
    );
    this.profile?.subscribe((x) => {
      this.breadcrumbService.set('@clinicianName', 'Clinician: ' + x.username);
    });
  }
}
