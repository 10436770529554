import { Component, OnInit, ViewChild } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { PatientService } from '../patient.service';
import { MatSelect } from '@angular/material/select';
import {
  CohortsByStudy,
  FilterService,
  StudyDescription,
} from '../filter.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { environment } from 'src/environments/environment';
import { StudyService } from '../study.service';
import { Role } from '../guards/role.guard';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  showUserTooltip: boolean = false;
  username: string = '';
  cohortsByStudy: StudyDescription[] = [];
  permissionsUrl = environment.permissionsUrl;
  isPartnerAdmin = false;

  constructor(
    private keycloakService: KeycloakService,
    private filterService: FilterService,
    private studyService: StudyService
  ) {}

  ngOnInit(): void {
    try {
      this.username = this.keycloakService.getUsername();
      this.isPartnerAdmin = this.keycloakService.isUserInRole(
        Role.PartnerAdmin
      );
    } catch (Error) {
      this.username = 'Not logged in.';
    }

    this.studyService.allStudies.subscribe((studyDescriptions) => {
      this.cohortsByStudy = studyDescriptions;
    });
  }

  onIconClick() {
    this.showUserTooltip = !this.showUserTooltip;
  }

  logout() {
    sessionStorage.setItem('alreadyLoggedIn', 'false');
    this.keycloakService.logout();
  }
}
