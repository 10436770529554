import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { PatientService } from '../../patient.service';
import { ActivatedRoute } from '@angular/router';
import { CohortDescription, PatientDemographics } from '../../patient';
import { PatientComponent } from '../../patient/patient.component';
import { MatButtonModule } from '@angular/material/button';
import { FilterService, StudyCohort } from '../../filter.service';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'app-patient-admin',
  standalone: true,
  templateUrl: './patient-admin.component.html',
  styleUrls: ['./patient-admin.component.scss'],
  imports: [
    CommonModule,
    PatientComponent,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    ReactiveFormsModule,
  ],
})
export class PatientAdminComponent implements OnInit {
  private patientId: string;

  public selectedPatient: PatientDemographics | undefined;

  currentCohort: StudyCohort | null = null;

  targetCohort: string = "";
  targetCohortInFlight: boolean = false;

  breadcrumbName: string = '';

  constructor(
    private patientService: PatientService,
    public filterService: FilterService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.patientId = this.route.snapshot.paramMap.get('patientGuid') || '';
  }

  ngOnInit(): void {
    this.getPatientProfile();
    this.getCohortInformation();
  }

  getPatientProfile(): void {
    this.patientService
      .getPatientProfile(this.patientId)
      .subscribe((patient: PatientDemographics | undefined) => {
        this.selectedPatient = patient;
        this.breadcrumbService.set(
          '@patientName',
          'Patient: ' + patient?.username,
        );
      });
  }

  getCohortInformation(): void {
    this.patientService
      .getCohortForPatient(this.patientId)
      .subscribe((cohort) => {
        this.currentCohort = cohort;
        this.targetCohort = cohort.cohortGuid;
      });
  }

  sync() {
    if (this.selectedPatient) {
      this.patientService.sync(this.selectedPatient).subscribe();
    }
  }

  resetPassword() {
    if (this.selectedPatient) {
      this.patientService.resetPassword(this.selectedPatient).subscribe();
    }
  }

  movePatient() {
    if (this.selectedPatient) {
      this.targetCohortInFlight = true;
      this.patientService
        .movePatientToCohort(this.selectedPatient, this.targetCohort)
        .subscribe((x) => (this.targetCohortInFlight = false));
    }
  }

  updateDataSource(newValue: any) {
    console.log(newValue);
    //send http post request to update values
  }

  save() {
    if (this.selectedPatient != undefined) {
      this.selectedPatient.cohort.guid = this.targetCohort
      this.patientService
        .updatePatient(this.patientId, this.selectedPatient)
        .subscribe((selectedPatient) => {
          if (selectedPatient != null) {
            this.selectedPatient = selectedPatient;
          }
        });
    }
  }

  cohortSelectionChanged(targetCohortGuid: string) {
    this.targetCohort = targetCohortGuid;
  }

  protected readonly formatDate = formatDate;
}
