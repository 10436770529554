import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NetworkErrorService} from "../network-error.service";
import {NewStudy} from "./new-study/new-study.component";
import {Observable} from "rxjs";
import {StudyDescription} from "../filter.service";
import {catchError} from "rxjs/operators";
import {CohortDescription} from "../patient";

export class NewCohort {
  // Name for this cohort
  treatment: string = "";

  // Is this cohort in use?
  active: boolean = true;

  treatments: string[] = [];

  study: number = -1;

  notes: string = "";
}

@Injectable({
  providedIn: 'root'
})
export class CohortCreateService {

  private cohortCreateUrl = `${environment.permissionsUrl}/api/createCohort`;  // URL to web api

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService
  ) {

  }


  createCohort(cohort: NewCohort): Observable<CohortDescription> {

    var x = this.http.post<CohortDescription>(this.cohortCreateUrl, cohort)
      .pipe(
        catchError(this.networkErrorService.handleError<CohortDescription>('createCohort', undefined))
      );
    return x;
  }
}
