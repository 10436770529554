// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
}

.mdc-data-table__table {
  max-width: 50%;
  min-width: 50%;
}

.mat-mdc-row {
  height: 80px;
}

td {
  padding-right: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/vestibular/svv-editor/svv-editor.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAGA;EACI,cAAA;EACA,cAAA;AAAJ;;AAEA;EACI,YAAA;AACJ;;AAIA;EACA,oBAAA;AADA","sourcesContent":[":host {\n  flex-grow: 1;\n}\n\n\n.mdc-data-table__table {\n    max-width: 50%;\n    min-width: 50%;\n}\n.mat-mdc-row {\n    height: 80px;\n}\n// .mat-mdc-form-field {\n//     display: block;\n// }\ntd {\npadding-right: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
