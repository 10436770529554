import { Component, Input, OnInit } from '@angular/core';
import { PatientService } from '../patient.service';
import { SurveyDetails, SurveyList, SurveyListEntry } from '../patient';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { MindcapService } from '../mindcap.service';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.css'],
  standalone: true,
  imports: [
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgFor,
  ],
})
export class SurveyListComponent implements OnInit {
  @Input('selectedPatient')
  selectedPatient: string = '';
  cohortId: string = '';
  studyId: number = 0;

  displayedColumns: string[] = ['surveyName', 'time'];

  public surveyList: SurveyList | undefined;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private mindcapService: MindcapService,
  ) {
    // @ts-ignore
    this.selectedPatient = this.route.snapshot.paramMap.get('guid') || '';
    this.patientService
      .getPatientProfile(this.selectedPatient)
      .subscribe((x) => {
        this.cohortId = x?.cohort.guid || '';
        this.studyId = x?.study.id || 0;
      });
  }

  ngOnInit() {
    this.getSurveyList();
  }

  getSurveyList(): void {
    this.mindcapService
      .getSurveysForPatient(this.selectedPatient)
      .subscribe((surveyList) => {
        this.surveyList = surveyList;
        console.log(this.surveyList);
      });
  }
}
