// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 100%;
}

.text-inside-grid {
  width: 100% !important;
  height: 100% !important;
}

.profile_btn {
  vertical-align: bottom;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/patient-mderm-report-list/patient-mderm-report-list.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;EACE,sBAAA;EACA,uBAAA;AAEF;;AACA;EACE,sBAAA;EACA,eAAA;AAEF","sourcesContent":["mat-form-field {\n  width: 100%;\n}\n.text-inside-grid {\n  width: 100% !important;\n  height: 100% !important;\n}\n\n.profile_btn {\n  vertical-align: bottom;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
