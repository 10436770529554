import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PatientDemographics } from './patient';
import { ListPatients } from './patient.service';
import { Study, StudyCohort, StudyDescription } from './filter.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NetworkErrorService } from './network-error.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private _studies: BehaviorSubject<Study[]>;
  public readonly studies: Observable<Study[]>;

  private permissionsUrl = environment.permissionsUrl;
  private studiesurl = `${this.permissionsUrl}/api/listStudies`; // URL to web api

  public loading: boolean = true;

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {
    this._studies = new BehaviorSubject<Study[]>([]);
    this.studies = this._studies.asObservable();

    this.getStudies().subscribe(
      (studyList) => {
        this._studies.next(studyList);
        this.loading = false;
      },
      // TODO  Error
    );
  }

  getStudies(): Observable<Study[]> {
    let params = new HttpParams();

    var x = this.http
      .get<Study[]>(this.studiesurl, { params })
      .pipe(
        catchError(
          this.networkErrorService.handleError<Study[]>(
            'getStudies',
            undefined,
          ),
        ),
      );
    return x;
  }
}
