import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudyDescription } from '../../filter.service';

@Component({
  selector: 'app-dermatology-study-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dermatology-study-dashboard.component.html',
  styleUrls: ['./dermatology-study-dashboard.component.scss'],
})
export class DermatologyStudyDashboardComponent {
  @Input('study')
  public study!: StudyDescription;
}
