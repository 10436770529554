import { Component, OnInit, Input } from '@angular/core';
import { ScheduleInfo } from '../patient';
import { PatientService } from '../patient.service';
import { Observable } from 'rxjs';
import { NgIf, NgFor } from '@angular/common';
import { MindcapService } from '../mindcap.service';

@Component({
  selector: 'app-Schedule-Reminder',
  templateUrl: './Schedule-Reminder.component.html',
  styleUrls: ['./Schedule-Reminder.component.css'],
  standalone: true,
  imports: [NgIf, NgFor],
})
export class ScheduleReminderComponent implements OnInit {
  @Input('patientId')
  patientId: string = '';

  public Schedule: ScheduleInfo | undefined;

  constructor(
    private patientService: PatientService,
    private mindcapService: MindcapService,
  ) {}

  ngOnInit() {
    this.getPatientSchedule();
  }

  getPatientSchedule(): void {
    this.mindcapService
      .getPatientScheduleReminder(this.patientId)
      .subscribe((schedule: ScheduleInfo | undefined) => {
        this.Schedule = schedule;
      });
  }

  getPatientScheduleById(patientId: string): Observable<ScheduleInfo> {
    return this.mindcapService.getPatientScheduleReminder(patientId);
  }
}
