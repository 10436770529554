import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
} from '@angular/core';
import {
  CalendarDayModule,
  CalendarEvent,
  CalendarMonthModule,
  CalendarView,
  CalendarWeekModule,
} from '@nxthealth/angular-calendar-noscarf';
import { colors } from './colors';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import {
  InsufficientDays,
  MindcapCalendar,
  MindcapCalendarEvent,
  MindcapCalendarWeek,
  MindcapPatientCalendarService,
  PhaseChange,
} from './mindcap-patient-calendar.service';
import { Patient, PatientDemographics, PatientDescription } from '../patient';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-patient-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    CalendarHeaderComponent,
  ],
  templateUrl: './patient-calendar.component.html',
  styleUrls: ['./patient-calendar.component.scss'],
})
export class PatientCalendarComponent implements OnInit {
  @Input() patientGuid!: string;

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  events$!: Observable<CalendarEvent[]>;

  refresh = new Subject<void>();

  constructor(
    protected mindcapPatientCalendarService: MindcapPatientCalendarService
  ) {}

  ngOnInit() {
    if (this.patientGuid) {
      this.events$ = this.mindcapPatientCalendarService
        .LoadCalendar(this.patientGuid)
        .pipe(
          map((cal) => {
            let events: CalendarEvent[] = [];
            cal.events.forEach((evt) =>
              events.push({
                title: evt.title,
                start: new Date(evt.start),
                end: new Date(evt.end),
              })
            );
            cal.phaseChanges.forEach((evt) =>
              events.push({
                title: evt.title,
                start: new Date(evt.start),
                end: new Date(evt.end),
              })
            );

            events.push({
              title: 'Study Start',
              start: new Date(cal.studyStart),
            });
            events.push({
              title: 'Baseline',
              start: new Date(cal.baselineDate),
            });
            events.push({
              title: 'Next Badge Popup',
              start: cal.nextBadgePopup,
            });
            // weeks: MindcapCalendarWeek[];
            // insufficientDays: InsufficientDays[];
            // chemoStart: Date;
            // chemoEnd: Date;
            // nextPromis: Date;
            // nextPromis2: Date;
            return events;
          })
        );
    }
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    console.log('Event clicked', event);
  }
}
