import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataSetUploadService {

  constructor(private http: HttpClient) {}

  upload(file: File, dataSetName: string, dataSetComments: string, dataSetSource: string, dataSetPOC: string, dataSetIsDeidentified: boolean): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('archiveFile', file);
    formData.append('fileType', 'zip');
    formData.append('name', dataSetName);
    formData.append('comments', dataSetComments);
    formData.append('source', dataSetSource);
    formData.append('pointOfContact', dataSetPOC);
    formData.append('isDeidentified', dataSetIsDeidentified.toString())

    const req = new HttpRequest('POST', `${environment.mdermUrl}/api/saveDataset`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }
}
