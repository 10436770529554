// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  background-color: var(--color-primary-default);
}

.toolbar-content {
  display: flex;
}

.rhs {
  display: flex;
  margin-left: auto;
  gap: 2rem;
}

.logo-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 16px;
}

.logo-container:hover {
  cursor: pointer;
}

.logo {
  height: 25px;
  width: auto;
}

.icon-button mat-icon {
  color: white;
}

.account-button {
  color: white;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 56px;
  right: 16px;
  z-index: 1000;
}

.username {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.tab-selection {
  color: var(--color-primary-default-contrast);
  font-size: 18px;
}

.tabs {
  margin-left: auto;
}

.selection {
  margin-right: 2rem;
  margin-left: 0.5rem;
  margin-bottom: -1.25rem;
}

a {
  color: white;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/app/nav-bar/nav-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,8CAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,yCAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,4CAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,qBAAA;AACF","sourcesContent":[".toolbar {\n  background-color: var(--color-primary-default);\n}\n\n.toolbar-content {\n  display: flex;\n}\n\n.rhs {\n  display: flex;\n  margin-left: auto;\n  gap: 2rem;\n}\n\n.logo-container {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  padding-right: 16px;\n}\n\n.logo-container:hover {\n  cursor: pointer;\n}\n\n.logo {\n  height: 25px;\n  width: auto;\n}\n\n.icon-button mat-icon {\n  color: white;\n}\n\n.account-button {\n  color: white;\n}\n\n.tooltip-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #fff;\n  padding: 8px;\n  border-radius: 4px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);\n  position: absolute;\n  top: 56px;\n  right: 16px;\n  z-index: 1000;\n}\n\n.username {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 15px;\n}\n\n.tab-selection {\n  color: var(--color-primary-default-contrast);\n  font-size: 18px;\n}\n\n.tabs {\n  margin-left: auto;\n}\n\n.selection {\n  margin-right: 2rem;\n  margin-left: 0.5rem;\n  margin-bottom: -1.25rem;\n}\n\na {\n  color: white;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
