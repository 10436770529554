import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { Therapies } from '../vestibular.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { VestibularPreviewComponent } from '../vestibular-preview/vestibular-preview.component';
import { VorEditorComponent } from '../vor-editor/vor-editor.component';
import { SvvEditorComponent } from '../svv-editor/svv-editor.component';

@Component({
  selector: 'app-therapy',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    RouterLink,
    MatCheckboxModule,
    MatButtonModule,
    RouterModule,
    ReactiveFormsModule,
    MatListModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    MatRadioModule,
    VestibularPreviewComponent,
    VorEditorComponent,
    SvvEditorComponent,
  ],
  templateUrl: './therapy.component.html',
  styleUrls: ['./therapy.component.scss'],
})
export class TherapyComponent implements OnInit {
  patientGuid: string = '';
  therapyId?: number;
  studyId: string = '';
  isFavorite: number = 0;

  activity: Therapies = Therapies.Vorx1Exercise;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe({
      next: (val: any) => {
        console.log(JSON.stringify(val));
        this.therapyId = parseInt(val['therapyId']);
        this.patientGuid = val['patientGuid'];
        this.isFavorite = val['isFavorite'];
        this.studyId = val['id'];
        if (val['activity']) {
          this.activity = +val['activity'];
        }
      },
    });

    ///need to initialize here for some reason otherwise console errors for ngmodel///
    ///still functions without this initializer//////////////////////////////////////
  }
  protected readonly Therapies = Therapies;
}
