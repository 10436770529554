import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { StudyService } from '../../study.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StudyCohort, StudyDescription } from '../../filter.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterService } from '../../filter.service';
import { VestibularService } from 'src/app/vestibular/vestibular.service';

@Component({
  selector: 'app-admin-home',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    RouterLink,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss'],
})
export class AdminHomeComponent {
  study: StudyDescription[] = [];
  displayedColumns: string[] = [
    'studyName',
    'cohortName',
    'editStudy',
    'editCohort',
  ];
  public dataSource = new MatTableDataSource<StudyCohort>();
  loading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  constructor(
    public studyService: StudyService,
    private route: ActivatedRoute,
    private filterService: FilterService,
    public vestibularService: VestibularService,
  ) {}
  //    this.patientId = this.route.snapshot.paramMap.get('patientGuid') || '';

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    // this.vestibularService.getReports().subscribe((data) => {
    //   console.log(data);
    // });
    this.getStudies();
  }

  getStudies(): void {
    this.studyService.allStudies.subscribe((study) => {
      this.study = study;
      this.getTableData();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTableData(): void {
    const studyDataArray: StudyCohort[] = [];
    this.study.flatMap((s) => {
      s.cohorts.map((c) => {
        studyDataArray.push(
          new StudyCohort(s.id, +c.id, c.guid, s.name, c.treatment),
        );
      });
    });

    this.dataSource.data = studyDataArray;
    this.loading = false;
  }

  protected readonly JSON = JSON;
}
