import {Component, Input, OnInit} from '@angular/core';
import {CommonModule, NgFor, NgForOf} from '@angular/common';
import {PatientService} from "../patient.service";
import {AttributeList, AttributeService} from "../attribute.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {Gallery} from "ng-gallery";
import {CohortService} from "../cohort.service";
import {Observable} from "rxjs";
import {CohortDescription} from "../patient";

@Component({
  selector: 'app-cohort-membership-list',
  standalone: true,
  imports: [CommonModule, NgFor, NgForOf, RouterLink],
  templateUrl: './cohort-membership-list.component.html',
  styleUrls: ['./cohort-membership-list.component.scss']
})
export class CohortMembershipListComponent implements OnInit {

  membership? : Observable<CohortDescription[]>;

  @Input( "uuid" )
  uuid : string = "";

  constructor(
    private cohortService: CohortService,
  ) {
  }

  ngOnInit()
  {
    this.membership = this.cohortService.getMembership( this.uuid );
  }

  protected readonly JSON = JSON;
}
