import { CommonModule, DatePipe, NgFor } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  CalendarDayModule,
  CalendarEvent,
  CalendarMonthModule,
  CalendarView,
  CalendarWeekModule,
  CalendarCommonModule,
} from '@nxthealth/angular-calendar-noscarf';
import { colors } from './colors';
import { CalendarHeaderVestaidComponent } from './calendar-header-vestaid/calendar-header-vestaid.component';
import { Patient, PatientDemographics, PatientDescription } from '../patient';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { VestibularService } from '../vestibular/vestibular.service';
@Component({
  selector: 'app-patient-calendar-vestaid',
  standalone: true,
  imports: [
    CommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    CalendarHeaderVestaidComponent,
    DatePipe,
    CalendarCommonModule,
    NgFor,
  ],
  templateUrl: './patient-calendar-vestaid.component.html',
  styleUrls: ['./patient-calendar-vestaid.component.scss'],
})
export class PatientCalendarVestaidComponent implements OnInit {
  @Input() patientGuid!: string;

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  events$!: Observable<CalendarEvent[]>;

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];
  durations = 0;

  constructor(public vestibularService: VestibularService) {}

  ngOnInit() {
    if (this.patientGuid) {
      // this.events$ = this.vestibularService.getReports(this.patientGuid).pipe(
      this.vestibularService
        .getReports(this.patientGuid)
        .subscribe((event: any) => {
          console.log(event.reports);
          for (let item in event.reports) {
            this.durations = this.durations + event.reports[item].duration;
            console.log(event.reports[item].description);
            this.events.push({
              title: event.reports[item].description,
              start: new Date(event.reports[item].exerciseStartTime),
              end: new Date(event.reports[item].exerciseEndTime),
              meta: {
                value: event.reports[item].duration,
              },
            });
          }
          console.log(this.events);
          this.refresh.next();
          return this.events;
        });
    }
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    console.log('Event clicked', event);
  }

  checkVal(x: any) {
    let num = 0;
    for (let item in x) {
      // console.log(x[item].meta.value);
      num = num + x[item].meta.value;
    }
    return num;
  }
}
