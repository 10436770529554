// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.form-width {
  width: 400px;
}

.form-row {
  width: 100%;
}

.backgroundList {
  display: flex;
  flex-wrap: wrap;
}

.patternSelect {
  background-color: gray;
  border: solid darkblue 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/vestibular/therapy/therapy.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,aAAA;EACA,eAAA;AAAF;;AAGA;EACE,sBAAA;EACA,0BAAA;AAAF","sourcesContent":[".example-form {\n  min-width: 150px;\n  max-width: 500px;\n  width: 100%;\n}\n\n.form-width {\n  width: 400px;\n}\n\n\n.form-row {\n  width: 100%;\n}\n\n.backgroundList {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.patternSelect {\n  background-color: gray;\n  border: solid darkblue 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
