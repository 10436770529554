import { Injectable } from '@angular/core';
import {ListPatients, PatientService} from "../patient.service";
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Observable, of, throwError} from "rxjs";
import {ClinicianDescription, PatientDemographics} from "../patient";
import {ClinicianService} from "../clinician.service";
import {NetworkErrorService} from "../network-error.service";


export class NewClinician
{
  username : string = "";
  emailAddress: string = "";
  givenName: string= "";
  familyName: string = "";
  cohortId: number = -1;
  active: boolean = true;
  administrator: boolean = false;
}


@Injectable({
  providedIn: 'root'
})
export class ClinicianCreateService {

  private permissionsUrl = environment.permissionsUrl;

  private clinicianCreateUrl = `${this.permissionsUrl}/api/createClinician`;  // URL to web api

  constructor( private clinicianService: ClinicianService,
               private http: HttpClient,
               private networkErrorService : NetworkErrorService
               )
  {
  }

  createClinician(clinician:  NewClinician ) : Observable<ClinicianDescription> {

    var x = this.http.post<ClinicianDescription>(this.clinicianCreateUrl, clinician )
      .pipe(
        catchError( errorResponse => {
          this.networkErrorService.handleError<ClinicianDescription>('createClinician', undefined);
          return throwError(() => errorResponse );
        })
      );
    return x;
  }

}
