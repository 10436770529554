import { Component } from '@angular/core';
import { AsyncPipe, CommonModule, NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PatientService } from '../../patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterService } from '../../filter.service';
import { NewPatient, PatientCreateService } from '../patient-create.service';

@Component({
  selector: 'app-new-patient',
  standalone: true,
  imports: [
    MatSelectModule,
    MatTableModule,
    MatListModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    NgIf,
    MatCheckboxModule,
    AsyncPipe,
    NgForOf,
  ],
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.scss'],
})
export class NewPatientComponent {
  patient: NewPatient = new NewPatient();

  loading: boolean = false;
  error: string = '';

  constructor(
    private patientCreateService: PatientCreateService,
    public filterService: FilterService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  create(): void {
    this.patientCreateService.createPatient(this.patient).subscribe({
      next: (x) => {
        this.loading = false;
        // this.router.navigate(['/home', 'admin']);
        this.router.navigate(['/home/patient', x.uuid]);
      },
      error: (x) => {
        this.loading = false;
        this.error = `Error creating patient, ${x.error.errorMessage}`;
      },
    });
  }
}
