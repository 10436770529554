import { NgIf } from '@angular/common';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis, ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import {MindcapEvents, MindcapPatientCharts} from "../../patient";
import {Observable, Subject, Subscription} from "rxjs";
import * as ApexCharts from 'apexcharts';

export type HistoryGraphOptions = {
  series?: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  yaxis: ApexYAxis;
  markers: ApexMarkers;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'app-mindcap-event-graph',
  templateUrl: './mindcap-event=graph.component.html',
  styleUrls: ['./mindcap-event=graph.component.css'],
  standalone: true,
  imports: [NgApexchartsModule, NgIf],
})
export class MindcapEventGraphComponent implements OnInit, OnDestroy {
  public chartOptions!: HistoryGraphOptions;

  @Input()
  patientGuid : string = ""


  @Input()
  loadData? : Observable<MindcapPatientCharts>;

  @Output()
  lineSelected : EventEmitter<number> = new EventEmitter<number>();

  private eventsSubscription? : Subscription;

  @ViewChild("chart") chart?: ChartComponent;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: 'Testing History',
          data: [
            { x: new Date('2020-03-19'), y: 0 },
          ],
        },
      ],
      chart: {
        height: 350,
        type: 'area',
        events: {
          markerClick: (event : MouseEvent, chartContext, params ) => {
            this.lineSelected.emit( params.dataPointIndex )
          }
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM \'yyyy',
          day: 'dd MMM',
          hour: 'HH:mm'
      }
        },
      },
      yaxis: {
        min: -1,
        max: 1,
        tickAmount: 2,
        labels: {
          formatter: function (val) {
            if (val === 1) {
              return 'Improvement';
            } else if (val === -1) {
              return 'Decline';
            } else {
              return 'Normal';
            }
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 90, 100],
        },
      },
      markers: {
        size: 1,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
    };
  }

  ngOnInit() {
    this.eventsSubscription = this.loadData?.subscribe(( x : MindcapPatientCharts) => this.loadGraphData( x ));
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }

  loadGraphData( data : MindcapPatientCharts) {
      let evts = data.events.sort( (a, b) => { return new Date( a.start ).getTime() - new Date( b.start ).getTime() } );

      let tests = data.tN.sort(
        (a, b) => a - b
      ).map(
        (  (testTime ) => ({x: new Date(testTime*1000), y:0}) )
      );

      let s = 0;
      let t = 0;

      for( t=0; t<tests.length; ++t ) {
        let current = (tests[t].x).getTime()
        if (current < new Date(evts[0].start).getTime()) {
          tests[t].y = 0
        } else {
          break;
        }
      }

      for( ; t<tests.length; ++t ) {
        let current = (tests[t].x).getTime()
        if (current >= new Date(evts[s + 1].start).getTime()) {
           s++;
           // Clamp to end of events array
           if (s >= evts.length-1) {
             break;
           }
         }
        tests[t].y = this.eventToY(evts[s].type)
      }

      for( ; t<tests.length; ++t ) {
        tests[t].y = this.eventToY(evts[ evts.length - 1].type)
      }





      this.chartOptions.series = [
        {
          name: 'Testing History',
          data: tests
        }
      ]
      this.chart?.render()
  }

  eventToY( type : string ) : number
  {
    if( type == "improvement") { return 1; }
    if( type == "decline") { return -1; }
    return 0;
  }
}
