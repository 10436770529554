import { Injectable } from '@angular/core';

export interface SelectionButtonInfo {
  name: string;
  guid: string;
}

@Injectable({
  providedIn: 'root',
})
export class SelectionService {
  constructor() {}

  private selectedPatients: SelectionButtonInfo[] = [];

  setData(data: SelectionButtonInfo[]) {
    this.selectedPatients = data;
  }

  getData(): SelectionButtonInfo[] {
    return this.selectedPatients;
  }
}
