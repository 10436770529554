// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-tooltip-line-break {
  white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./src/app/vestibular/vestibular-cohort-dashboard/vestibular-cohort-dashboard.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ","sourcesContent":["::ng-deep .mat-tooltip-line-break{\n    white-space: pre-line;\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
