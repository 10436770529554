import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudyCreateService } from '../study-create.service';
import { NewStudy } from '../new-study/new-study.component';
import { CohortCreateService, NewCohort } from '../cohort-create.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { StudyService } from '../../study.service';

@Component({
  selector: 'app-new-cohort',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    ReactiveFormsModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
  ],
  templateUrl: './new-cohort.component.html',
  styleUrls: ['./new-cohort.component.scss'],
})
export class NewCohortComponent {
  loading: boolean = false;
  error: string = '';

  cohort: NewCohort = new NewCohort();

  constructor(
    public cohortCreateService: CohortCreateService,
    public studyService: StudyService,
    private router: Router
  ) {}

  create(): void {
    this.cohortCreateService.createCohort(this.cohort).subscribe({
      next: (x) => {
        this.loading = false;
        this.router.navigate(['/home', 'admin']);
      },
      error: (x) => {
        this.loading = false;
        this.error = 'Error creating cohort';
      },
    });
  }
}
