import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DataSetListService } from '../data-set-list.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgIf, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router, RouterModule } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';

export interface DataSetTable {
  dataSet: string;
  date: string;
  source: string;
  poc: string;
  ailments: string;
  bodyParts: string;
  virusStatus: string;
  guid: DataSetURL;
  comments: string;
  select?: boolean;
  spinner?: boolean;
  isDeidentified?: boolean;
}

export interface DataSetURL {
  url: string;
}

export interface DialogData {
  comments: string;
}

@Component({
  selector: 'app-data-set-list',
  templateUrl: './data-set-list.component.html',
  styleUrls: ['./data-set-list.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgIf,
    NgFor,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule,
    MatCheckboxModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
  ],
})
export class DataSetListComponent implements OnInit {
  dataSource = new MatTableDataSource<DataSetTable>();
  selection = new SelectionModel<DataSetTable>(true, []);
  loading = true;
  displayedColumns: string[] = [
    'select',
    'dataSet',
    'date',
    'source',
    'poc',
    'ailments',
    'bodyParts',
    'virusStatus',
    'download',
    'comments',
    'edit',
  ];
  dataSetInfo = [];
  dataArray: DataSetURL[] = [];
  enableButton = false;
  modelGenerationList: DataSetURL[] = [];
  selectedRowsList: DataSetTable[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(
    private listService: DataSetListService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'date':
          return new Date(item.date).toISOString();
        default:
          return item[property as keyof DataSetTable] as string;
      }
    };
  }

  ngOnInit(): void {
    this.getDataSet();
  }

  getDataSet() {
    const filteredArray: DataSetTable[] = [];
    this.listService.getData().subscribe({
      next: (event: any) => {
        this.dataSetInfo = event.body;
        for (let item in this.dataSetInfo) {
          let ailments = 0;
          let bodyParts = 0;
          let format = JSON.parse(
            JSON.parse(JSON.stringify(this.dataSetInfo[item]['metadata']))
          );
          if (format['bodyParts']) {
            ailments = Object.keys(format['ailments']).length;
            bodyParts = Object.keys(format['bodyParts']).length;
          }
          filteredArray.push({
            dataSet: this.dataSetInfo[item]['name'],
            date: new Date(this.dataSetInfo[item]['uploadedDate']).toString(),
            source: this.dataSetInfo[item]['source'],
            poc: this.dataSetInfo[item]['pointOfContact'],
            ailments: ailments.toString(),
            bodyParts: bodyParts.toString(),
            virusStatus: this.dataSetInfo[item]['scanStatus'],
            guid: this.dataSetInfo[item]['guid'],
            comments: this.dataSetInfo[item]['comments'],
            select: false,
            spinner: false,
            isDeidentified: this.dataSetInfo[item]['isDeidentified'],
          });
        }
      },
      error: (err: any) => {
        this.loading = false;
      },
      complete: () => {
        this.dataSource.data = filteredArray;
        this.loading = false;
        console.log(this.dataSource.data);
      },
    });
  }

  generateURL(dataset: any) {
    dataset.spinner = true;
    this.listService.getURL(dataset.guid).subscribe({
      next: (event: any) => {
        if (event && event.body && event.body.url) {
          window.location.href = event.body.url;
        }
      },
      error: (err: any) => {
        dataset.spinner = false;
      },
      complete: () => {
        dataset.spinner = false;
      },
    });
  }

  openDialog(patientComments: string): void {
    const dialogRef = this.dialog.open(DataSetListComponentDialog, {
      data: { comments: patientComments },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    const filteredArray: DataSetTable[] = [];
    if (this.isAllSelected()) {
      this.selection.clear();
      this.modelGenerationList = [];
      this.selectedRowsList = [];
      this.dataSource.data.forEach((row) => {
        row.select = false;
      });
      this.enableButton = false;
      console.log(this.modelGenerationList);
      console.log(this.selectedRowsList);
    } else {
      this.dataSource.data.forEach((row, index) => {
        this.selection.select(row);
        row.select = true;
        if (this.modelGenerationList.includes(row.guid)) {
          console.log(row.guid + ' is included');
        } else {
          this.selectedRowsList.push(row);
          this.modelGenerationList.push(row.guid);
          console.log(this.modelGenerationList);
          console.log(this.selectedRowsList);
        }
      });
      this.enableButton = true;
    }
  }

  getDisplayedColumns() {
    return this.displayedColumns;
  }

  checkRow(row: any) {
    this.enableButton = true;
    if (row.select == true) {
      this.selection.toggle(row);
      row.select = false;
      this.modelGenerationList.forEach((item, index) => {
        if (item === row.guid) {
          this.modelGenerationList.splice(index, 1);
          this.selectedRowsList.splice(index, 1);
        }
      });
      console.log(this.modelGenerationList);
      console.log(this.selectedRowsList);
    } else if (row.select == false) {
      this.selection.toggle(row);
      row.select = true;
      this.selectedRowsList.push(row);
      this.modelGenerationList.push(row.guid);
      console.log(this.modelGenerationList);
      console.log(this.selectedRowsList);
    }
    if (this.dataSource.data.every((item) => !item.select)) {
      this.enableButton = false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openModelGenerationDialog(): void {
    const dialogDataSource = this.selectedRowsList;
    const dialogRef = this.dialog.open(
      DataSetListModelGenerationComponentDialog,
      {
        data: dialogDataSource,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result.event);
      if (result.event == true) {
        this.selection.clear();
        this.modelGenerationList = [];
        this.selectedRowsList = [];
        this.dataSource.data.forEach((row) => {
          row.select = false;
        });
        this.enableButton = false;
      }
    });
  }

  tableReload() {
    this.dataSource.data = this.dataSource.data;
  }

  deleteDataset() {
    this.selectedRowsList.forEach((row) => {
      this.listService.deleteTrainingRequest(row.guid).subscribe({
        next: (event: any) => {
          console.log(event);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          this.tableReload();
          this.ngOnInit();
        },
      });
    });
  }

  openEditDatasetDialog(dataset: any): void {
    console.log(dataset.guid);
    const dialogRef = this.dialog.open(DataSetListEditDatasetComponentDialog, {
      data: dataset,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.tableReload();
      this.ngOnInit();
    });
  }
}

@Component({
  selector: 'app-data-set-list',
  templateUrl: './data-set-list-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
})
export class DataSetListComponentDialog {
  constructor(
    public dialogRef: MatDialogRef<DataSetListComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-data-set-list',
  templateUrl: './data-set-list-model-generation-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatTableModule,
    MatGridListModule,
    CommonModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
  ],
})
export class DataSetListModelGenerationComponentDialog implements OnInit {
  modelName = '';
  modelComment = '';
  action = 'hello';
  isGenerated = false;
  selection = new SelectionModel<DataSetTable>(true, []);
  selectionSource = new MatTableDataSource<DataSetTable>();
  selectionColumns: string[] = [
    'dataSet',
    'source',
    'ailments',
    'bodyParts',
    'virusStatus',
    'delete',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null =
    null;

  constructor(
    public dialogRef: MatDialogRef<DataSetListModelGenerationComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DataSetTable[] = [],
    private listService: DataSetListService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.selectionSource.data = this.data;
    this.selectionSource.paginator = this.paginator;
  }

  onNoClick(): void {
    this.isGenerated = false;
    this.dialogRef.close({ event: this.isGenerated });
  }

  onGeneration(): void {
    let idArray = [];
    for (let item in this.selectionSource.data) {
      idArray.push(this.selectionSource.data[item]['guid']);
    }
    this.listService
      .createTrainingRequest(idArray, this.modelName, this.modelComment)
      .subscribe({
        next: (event: any) => {
          console.log(event);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          this.router.navigate(['/home', 'trainingrequestlist']);
        },
      });
    this.isGenerated = true;
    this.dialogRef.close({ event: this.isGenerated });
  }

  deleteButton(index: any): void {
    this.checkForData(index);
    this.selectionSource.data = this.selectionSource.data;
  }

  checkForData(index: any): void {
    this.selectionSource.data.splice(index, 1);
  }
}

@Component({
  selector: 'app-data-set-list',
  templateUrl: './data-set-list-edit-dataset-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
  ],
})
export class DataSetListEditDatasetComponentDialog {
  guid = this.dataset.guid;
  updateName = this.dataset.dataSet;
  updateSource = this.dataset.source;
  updateComment = this.dataset.comments;
  updatePointOfContact = this.dataset.poc;
  updateDataSetIsDeidentified = this.dataset.isDeidentified;

  constructor(
    public dialogRef: MatDialogRef<DataSetListComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public dataset: any,
    private listService: DataSetListService
  ) {}

  saveChanges() {
    this.listService
      .updateDataset(
        this.guid,
        this.updateName,
        this.updateSource,
        this.updateComment,
        this.updatePointOfContact,
        this.updateDataSetIsDeidentified
      )
      .subscribe({
        next: (event: any) => {
          console.log(event);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          // this.router.navigate(['/home', 'trainingrequestlist']);
          this.dialogRef.close();
        },
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
