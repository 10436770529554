// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .cal-day-badge {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/patient-calendar-vestaid/patient-calendar-vestaid.component.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ","sourcesContent":["::ng-deep .cal-day-badge {\n    display: none !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
