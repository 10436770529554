import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule, formatDate, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { Exercises, Patterns, VestibularService } from '../vestibular.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { saveAs } from 'file-saver';
import { MatCardModule } from '@angular/material/card';
import { VjsPlayerComponent } from '../../vjs-player/vjs-player.component';
import { PatientService } from 'src/app/patient.service';
import { MatTooltipModule } from '@angular/material/tooltip';

export type ChartOptions = {
  series: ApexNonAxisChartSeries | ApexAxisChartSeries;
  colors: any;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

export interface DataSetTable {
  patientName: string;
  patientId: string;
  diagnosis: string;
  exerciseDate: string;
  exerciseType: string;
  visitNumber: string;
}

export interface therapyReport {
  therapyId: number;
  videoUrl: any;
  exerciseType: string;
  description: string;
  effectiveStart: Date;
  csvFile: any;
  errorCode: number;
  errorMessage: null;
  exerciseStartTime: Date;
  exerciseEndTime: Date;
  beforeHeadacheScale: number;
  beforeDizzinessScale: number;
  beforeNauseaScale: number;
  beforeFogginessScale: number;
  beforeComments: string;
  afterHeadacheScale: number;
  afterDizzinessScale: number;
  afterNauseaScale: number;
  afterFogginessScale: number;
  difficulty: number;
  afterComments: string;
  duration: number;
  numberOfBreaks: number;
  speedAvg: number;
  speedStd: number;
  gazeDevAvg: number;
  gazeDevStd: number;
  headMoveRightAvg: number;
  headMoveRightStd: number;
  headMoveLeftAvg: number;
  headMoveLeftStd: number;
  headMoveUpAvg: number;
  headMoveUpStd: number;
  headMoveDownAvg: number;
  headMoveDownStd: number;
  gazeCompliance: number;
  gazeIncorrected: number;
  headMotionCompliance: number;
  headMotionFast: number;
  headMotionSlow: number;
  sessionNumber: number;
  includeInEvaluation: boolean;
  dateCreated: Date;
  lastUpdated: Date;
  filteredInstantaneousBpm: [];
  interPeakTimes: [];
  alertsCleared: number;
}

export interface subjectiveData {
  symptom: string;
  preSessionScore?: number;
  postSessionScore?: number;
}

@Component({
  selector: 'app-vor-results',
  templateUrl: './vor-results.component.html',
  styleUrls: ['./vor-results.component.scss'],
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgIf,
    NgFor,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule,
    MatCheckboxModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatGridListModule,
    RouterLink,
    MatCardModule,
    NgApexchartsModule,
    VjsPlayerComponent,
    MatTooltipModule,
  ],
  standalone: true,
})
export class VorResultsComponent implements OnInit {
  @ViewChild('chart')
  chart!: ChartComponent;

  videoUrl: string = '';

  public headMotionChart: ChartOptions;
  public eyeGazeChart: ChartOptions;
  dataSource = new MatTableDataSource<DataSetTable>();
  selection = new SelectionModel<DataSetTable>(true, []);
  loading = true;
  displayedColumns: string[] = ['name', 'id', 'diagnosis', 'date', 'visit'];
  subjectiveDataSource = new MatTableDataSource<subjectiveData>();
  subjectiveDisplayedColumns: string[] = [
    'symptom',
    'preSessionScore',
    'postSessionScore',
  ];

  patientId = '';
  reportId = 0;
  csvFile = null;
  therapyReport?: therapyReport = undefined;

  // background?: Patterns;
  background = '';
  backgroundColor = '';
  optoType = '';
  suggestedSpeed = 0;
  direction = '';
  size = '';
  backgroundSpeed = '';
  fontSize = 0;
  fontColor = '';
  exerciseType = '';
  dosage = 0;
  displayName: string = '';
  cleared = false;
  callComplete = false;

  constructor(
    public vestibularService: VestibularService,
    private route: ActivatedRoute,
    private patientService: PatientService
  ) {
    this.headMotionChart = {
      series: [0, 0, 0],
      colors: ['#008000', '#FF0000', '#FFC000'],
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Compliant', 'Slow', 'Fast'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };

    this.eyeGazeChart = {
      series: [0, 0],
      colors: ['#008000', '#FF0000'],
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Correct', 'Incorrect'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  ngOnInit() {
    document.body.scrollTop = 0;
    this.patientId = this.route.snapshot.paramMap.get('patientGuid') || '';
    this.reportId = +(this.route.snapshot.paramMap.get('reportId') || '-1');
    this.patientService
      .getPatientProfile(this.patientId)
      .subscribe((patient) => {
        console.log(patient);
        if (patient != null) {
          this.displayName = this.patientService.getDisplayName(patient, true);
        }
      });
    this.loadTable();
  }

  ngAfterContentChecked() {
    document.body.scrollTop = 0;
  }

  loadTable() {
    const subjectiveData: subjectiveData[] = [];

    this.vestibularService
      .getVorReport(this.reportId)
      .subscribe((report: therapyReport) => {
        this.therapyReport = report;
        this.videoUrl = this.therapyReport.videoUrl;

        subjectiveData.push(
          {
            symptom: 'Dizziness',
            preSessionScore: this.therapyReport.beforeDizzinessScale,
            postSessionScore: this.therapyReport.afterDizzinessScale,
          },
          {
            symptom: 'Headache',
            preSessionScore: this.therapyReport.beforeHeadacheScale,
            postSessionScore: this.therapyReport.afterHeadacheScale,
          },
          {
            symptom: 'Nausea',
            preSessionScore: this.therapyReport.beforeNauseaScale,
            postSessionScore: this.therapyReport.afterNauseaScale,
          },
          {
            symptom: 'Fogginess',
            preSessionScore: this.therapyReport.beforeFogginessScale,
            postSessionScore: this.therapyReport.afterFogginessScale,
          },
          {
            symptom: 'Difficulty',
            preSessionScore: undefined,
            postSessionScore: this.therapyReport.difficulty,
          }
        );
        this.subjectiveDataSource.data = subjectiveData;

        this.eyeGazeChart.series = [
          this.therapyReport.gazeCompliance || 0,
          this.therapyReport.gazeIncorrected || 0,
        ];
        this.headMotionChart.series = [
          this.therapyReport.headMotionCompliance || 0,
          this.therapyReport.headMotionSlow || 0,
          this.therapyReport.headMotionFast || 0,
        ];

        console.log(JSON.stringify(this.therapyReport));
        console.log(this.therapyReport);
        console.log(this.therapyReport.therapyId);
        console.log('chart update: ' + this.headMotionChart.series);
        console.log('chart update 2: ' + this.eyeGazeChart.series);

        this.vestibularService.getReports(this.patientId).subscribe({
          next: (therapy: any) => {
            console.log(therapy.reports);
            let result = therapy.reports.find(
              (x: any) => x.therapyId === this.therapyReport?.therapyId
            );
            console.log(this.therapyReport?.therapyId);
            console.log(result);
            // this.background = therapy.therapies[item].background;
            this.backgroundColor = result.backgroundColor;
            this.optoType = result.optoType;
            this.suggestedSpeed = result.speed;
            this.fontSize = result.fontSize;
            this.fontColor = result.fontColor;
            this.exerciseType = result.exerciseType;
            this.dosage = result.dosage;
            if (result.backgroundPattern == 0) {
              this.background = 'No Pattern';
            } else if (result.backgroundPattern == 1) {
              this.background = 'Vertical Bars';
            } else if (result.backgroundPattern == 2) {
              this.background = 'Horizontal Bars';
            } else if (result.backgroundPattern == 3) {
              this.background = 'Checkerboard';
            } else if (result.backgroundPattern == 4) {
              this.background = 'Contrasting ';
            } else if (result.backgroundPattern == 5) {
              this.background = 'Dots';
            }
            ///case for direction
            if (result.backgroundDirection == 0) {
              this.direction = 'None';
            } else if (result.backgroundDirection == 1) {
              this.direction = 'Left to Right';
            } else if (result.backgroundDirection == 2) {
              this.direction = 'Right to Left';
            } else if (result.backgroundDirection == 3) {
              this.direction = 'Up to Down';
            } else if (result.backgroundDirection == 4) {
              this.direction = 'Down to Up';
            } else if (result.backgroundDirection == 5) {
              this.direction = 'Horizontal Oscillate';
            } else if (result.backgroundDirection == 6) {
              this.direction = 'Vertical Oscillate';
            } else if (result.backgroundDirection == 7) {
              this.direction = 'Rotate Clockwise';
            } else if (result.backgroundDirection == 8) {
              this.direction = 'Rotate Counterclockwise';
            }

            ///case for size
            if (result.backgroundSize == 0) {
              this.size = 'Small';
            } else if (result.backgroundSize == 1) {
              this.size = 'Medium';
            } else if (result.backgroundSize == 2) {
              this.size = 'Large';
            }

            ///case for background speed
            if (result.backgroundSpeed == 0) {
              this.backgroundSpeed = 'Slow';
            } else if (result.backgroundSpeed == 1) {
              this.backgroundSpeed = 'Medium Slow';
            } else if (result.backgroundSpeed == 2) {
              this.backgroundSpeed = 'Medium';
            } else if (result.backgroundSpeed == 3) {
              this.backgroundSpeed = 'Medium Fast';
            } else if (result.backgroundSpeed == 4) {
              this.backgroundSpeed = 'Fast';
            }
          },
          complete: () => {
            this.callComplete = true;
          },
        });
        console.log(this.exerciseType);
      });

    // this.vestibularService.getTherapies(this.patientId).subscribe((therapy) => {
    //   for (let item in therapy.therapies) {
    //     console.log(this.therapyReport?.therapyId);
    //     console.log(therapy.therapies[item].id);
    //     if (this.therapyReport?.therapyId == therapy.therapies[item].id) {
    //       console.log('MATCHED WITH ' + therapy.therapies[item].id);
    //     }
    //   }
    // });

    // console.log(subjectiveData);

    // this.updateChart();
    console.log(this.exerciseType);
  }

  downloadCSV(reportId: number) {
    this.vestibularService
      .getCSV(reportId)
      .subscribe((blob: Blob | undefined) => {
        if (blob) {
          saveAs(blob, `csv_${reportId}.csv`);
        }
      });
  }

  clearAlerts() {
    this.vestibularService.clearAlerts(this.reportId).subscribe((data: any) => {
      console.log(data);
      this.therapyReport = data;
    });
  }

  getDate(date?: Date) {
    if (date) {
      return formatDate(date, 'medium', 'EN-us');
    } else {
      return '';
    }
  }

  protected readonly Exercises = Exercises;
}
