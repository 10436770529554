import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CohortsByStudy,
  StudyCohort,
  StudyDescription,
} from './filter.service';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { NetworkErrorService } from './network-error.service';

export interface Feature {
  name: string;
  icon: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class StudyService {
  private _list: BehaviorSubject<StudyDescription[]> = new BehaviorSubject<
    StudyDescription[]
  >([]);
  public readonly allStudies: Observable<StudyDescription[]> =
    this._list.asObservable();

  features: Feature[];

  studiesUrl: string = environment.permissionsUrl + '/study/index';
  studyUrl: string = environment.permissionsUrl + '/study/show';
  featureUrl: string = environment.permissionsUrl + '/study/toggleFeature';

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {
    this.features = [
      { name: 'mderm', icon: 'pan_tool', description: 'Dermatology' },
      { name: 'mindcap', icon: 'perm_identity', description: 'Cognitive' },
      { name: 'vestibular', icon: 'rotate_right', description: 'Vestibular' },
      { name: 'delux', icon: 'highlight', description: 'Light' },
      { name: 'hipaa', icon: 'https', description: 'HIPAA' },
    ];

    this.loadInitialData();
  }

  loadInitialData() {
    return this.http
      .get<CohortsByStudy>(this.studiesUrl)
      .pipe(
        tap((_) => this.networkErrorService.log('fetched study list')),
        catchError(
          this.networkErrorService.handleError<CohortsByStudy>(
            'getCohortsByStudy',
            undefined,
          ),
        ),
      )
      .subscribe((x) => {
        this._list.next(x.studies);
      });
  }

  loadStudy(studyId: number) {
    return this.http.get<StudyDescription>(this.studyUrl + '/' + studyId).pipe(
      tap((_) => this.networkErrorService.log('fetched study')),
      catchError(
        this.networkErrorService.handleError<StudyDescription>(
          'loadStudy',
          undefined,
        ),
      ),
    );
  }

  toggleFeature(
    studyId: number,
    feature: string,
    enable: boolean,
  ): Observable<StudyDescription> {
    let params = {
      id: studyId,
      feature: feature,
      enable: enable,
    };

    return this.http.post<StudyDescription>(this.featureUrl, params).pipe(
      tap((_) => this.networkErrorService.log('set features')),
      catchError(
        this.networkErrorService.handleError<StudyDescription>(
          'loadStudy',
          undefined,
        ),
      ),
    );
  }

  getFeature(name: string): Feature | undefined {
    let found: Feature | undefined = this.features.find(
      (feat: Feature, index: number) => feat.name == name,
    );
    return found;
  }
}
