import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  Animations,
  Contrast,
  Exercises,
  Patterns,
  Size,
  Speed,
  SvvTherapy,
  Therapies,
  Therapy,
  VestibularService,
} from '../vestibular.service';
import { MatListModule } from '@angular/material/list';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { VestibularPreviewComponent } from '../vestibular-preview/vestibular-preview.component';
import { Background } from '../background';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { every } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';

export interface SvvTherapyTrials {
  trials: number;
  background: string;
  parameters: string;
  delete: boolean;
}

@Component({
  selector: 'app-svv-editor',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    RouterLink,
    MatCheckboxModule,
    MatButtonModule,
    RouterModule,
    ReactiveFormsModule,
    MatListModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    MatRadioModule,
    VestibularPreviewComponent,
    MatTableModule,
    MatGridListModule,
    MatIconModule,
  ],
  templateUrl: './svv-editor.component.html',
  styleUrls: ['./svv-editor.component.scss'],
})
export class SvvEditorComponent implements OnInit {
  @ViewChild('save')
  saveButton?: MatButton;

  @Input()
  patientGuid: string = '';

  @Input()
  therapyId?: number;

  creationMode: boolean = false;

  cancellationFrequency = 50;
  therapy?: SvvTherapy;
  selectedBackground = '';

  constructor(
    private router: Router,
    private vestibularService: VestibularService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.therapyId) {
      this.vestibularService
        .getSVVTherapy(this.therapyId)
        .subscribe((therapy: SvvTherapy) => {
          console.log(therapy);
          this.therapy = therapy;
        });
    } else {
      this.creationMode = true;
      this.therapy = {
        id: -1,
        patientGuid: this.patientGuid,
        trialType: 'RRR',
        comment: '',
        description: '',
      };
    }
  }

  onSave() {
    if (this.therapy) {
      if (this.saveButton) {
        let saveButton = this.saveButton;
        saveButton.disabled = true;

        this.vestibularService.saveSVV(this.therapy).subscribe({
          next: (x) => {
            saveButton.disabled = false;
            this.router.navigate(['../../'], { relativeTo: this.route });
          },

          error: (x) => {
            saveButton.disabled = false;
          },
        });
      }
    }
  }

  onCreate() {
    if (this.therapy) {
      console.log(this.therapy);
      this.vestibularService.createSvvTherapy(this.therapy).subscribe(() => {
        this.router.navigate(['../../'], { relativeTo: this.route });
      });
    }
  }
}
