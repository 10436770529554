import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FeatureServiceService {

  activeFeatureSource: BehaviorSubject<String>  = new BehaviorSubject<String>("");
  activeFeatureObs = this.activeFeatureSource.asObservable();

  set activeFeature(v: any) {
  this.activeFeatureSource.next(v);
}



  constructor() { }
}
