import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MdermPatientProfile, PatientDemographics } from '../patient';
import { PatientComponent } from '../patient/patient.component';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-profile-popup',
  templateUrl: './patient-profile-popup.component.html',
  styleUrls: ['./patient-profile-popup.component.scss'],
  standalone: true,
  imports: [PatientComponent, MatDialogModule],
})
export class PatientProfilePopupComponent {
  profile: MdermPatientProfile;
  selectedPatient?: PatientDemographics;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.profile = data.patient_profile;
    this.selectedPatient = data.selected_patient;
  }
}
