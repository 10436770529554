import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { PatientDemographics } from '../../patient';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { PatientCalendarComponent } from '../../patient-calendar/patient-calendar.component';
import { ScheduleReminderComponent } from '../../Schedule-Reminder/Schedule-Reminder.component';
import { TestListComponent } from '../../test-list/test-list.component';
import {
  SvvActivity,
  SvvTherapy,
  Therapy,
  TherapyList,
  VestibularService,
} from '../vestibular.service';
import { RouterLink, RouterModule } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PatientService } from 'src/app/patient.service';
import { VorFavoritesService } from '../vor-favorites.service';
import { interval, Observable } from 'rxjs';

export interface ReportDataSetTable {
  id: number;
  exerciseType: string;
  source: string;
  poc: string;
  ailments: string;
  bodyParts: string;
  virusStatus: string;
  comments: string;
  select?: boolean;
  spinner?: boolean;
  isDeidentified?: boolean;
}

export interface SVVReportDataSetTable {
  dataSet: string;
  date: string;
  source: string;
  poc: string;
  ailments: string;
  bodyParts: string;
  virusStatus: string;
  comments: string;
  select?: boolean;
  spinner?: boolean;
  isDeidentified?: boolean;
}

// interface TableData {
//   patient: string;
//   id: number;
//   dosage: number;
//   duration: number;
//   speed: number;
//   distance: number;
//   exerciseType: string;
//   background: string;
//   optoType: string;
//   comment: string;
//   description: string;
//   recordVideo: boolean;
//   uploadVideo: boolean;
//   fontSize: number;
//   fontColor: string;
//   backgroundColor: string;
//   backgroundPattern: number;
//   backgroundSize: number;
//   backgroundSpeed: number;
//   backgroundDirection: number;
//   backgroundContrast: number;
//   effectiveStart: Date;
//   effectiveEnd: Date;
// }

@Component({
  selector: 'app-vestibular-patient-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    PatientCalendarComponent,
    ScheduleReminderComponent,
    TestListComponent,
    RouterLink,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatSortModule,
    NgIf,
    NgFor,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
  ],
  templateUrl: './vestibular-patient-dashboard.component.html',
  styleUrls: ['./vestibular-patient-dashboard.component.scss'],
})
export class VestibularPatientDashboardComponent
  implements OnInit, AfterViewInit
{
  @Input('patientId')
  patientId: string = '';

  @Input('currentPatient')
  selectedPatient?: PatientDemographics;

  @ViewChild('vorPaginator') vorPaginator: MatPaginator | null = null;
  @ViewChild('#vorSort') vorSort: MatSort | null = null;

  @ViewChild('svvPaginator') svvPaginator: MatPaginator | null = null;
  @ViewChild('#svvSort') svvSort: MatSort | null = null;

  public therapies!: TherapyList;

  isVOR = 1;
  isSVV = 2;

  studyId = '';

  favorites: any[] = [];

  favoriteTherapy?: Therapy;

  commandsQueued = false;
  intervalId: any;

  vorActive: Observable<number> = this.vestibularService.activeTabObs;
  vorActiveTab: number = 0;

  constructor(
    public vestibularService: VestibularService,
    private patientService: PatientService,
    private vorFavoritesService: VorFavoritesService
  ) {}

  vorDataSource = new MatTableDataSource<Therapy>();
  displayedColumns: string[] = [
    'id',
    'exerciseType',
    'description',
    'dosage',
    'duration',
    'speed',
    'distance',
    // 'details',
    'remove',
  ];

  svvDataSource = new MatTableDataSource<SvvActivity>();
  svvDisplayedColumns: string[] = [
    'id',
    'description',
    'trialType',
    'start',
    'remove',
  ];

  ngAfterViewInit(): void {
    this.vorDataSource.paginator = this.vorPaginator;
    this.vorDataSource.sort = this.vorSort;

    this.svvDataSource.paginator = this.svvPaginator;
    this.svvDataSource.sort = this.svvSort;

    this.vorActive.subscribe((x) => {
      this.vorActiveTab = x;
    });
  }

  ngOnInit() {
    this.checkForCommandsQueued();
    this.intervalId = setInterval(() => {
      this.checkForCommandsQueued();
    }, 10000);
    this.getStudyId();
    // if (this.selectedPatient) {
    this.vestibularService
      .getTherapies(this.selectedPatient?.uuid)
      .subscribe((therapy) => {
        console.log(therapy);
        therapy.therapies.sort((a: any, b: any) => {
          return b.id - a.id;
        });
        this.therapies = therapy;
        this.vorDataSource.data = therapy.therapies;
      });
    // }

    this.vestibularService
      .getSvvAssignments(this.selectedPatient?.uuid)
      .subscribe((data: any) => {
        console.log(data);
        data.sort((a: any, b: any) => {
          return b.id - a.id;
        });
        for (let item in data) {
          switch (data[item].trialType) {
            case 'RRR':
              data[item].trialType = 'Random Trials';
              break;
            case 'SFD':
              data[item].trialType = 'Batch 1: Standard, Frame, Dots';
              break;
            case 'SDF':
              data[item].trialType = 'Batch 2: Standard, Dots, Frame';
              break;
            case 'FSD':
              data[item].trialType = 'Batch 3: Frame, Standard, Dots';
              break;
            case 'FDS':
              data[item].trialType = 'Batch 4: Frame, Dots, Standard';
              break;
            case 'DSF':
              data[item].trialType = 'Batch 5: Dots, Standard, Frame';
              break;
            case 'DFS':
              data[item].trialType = 'Batch 6: Dots, Frame, Standard';
              break;
            case 'DEM':
              data[item].trialType = 'Demo Trial';
              break;
          }
        }
        this.svvDataSource.data = data;
      });

    // this.vestibularService
    //   .getSvv this.selectedPatient?.uuid)
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.therapies = data.reports;
    //     console.log(this.therapies);
    //     // this.dataSource.data = data.reports;
    //   });
    // console.log(this.dataSource);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.vorDataSource.filter = filterValue.trim().toLowerCase();

    if (this.vorDataSource.paginator) {
      this.vorDataSource.paginator.firstPage();
    }
  }

  refreshVorTherapyTable() {
    this.vorDataSource.data = this.vorDataSource.data;
  }

  remove(therapy: Therapy) {
    this.callRemove(therapy);
    this.refreshVorTherapyTable();
  }

  callRemove(therapy: Therapy) {
    this.vestibularService.activateTherapy(therapy, false).subscribe({
      next: () => {
        console.log('Removed');
      },
      complete: () => {
        this.ngOnInit();
      },
    });
  }

  removeSvv(therapy: SvvTherapy) {
    this.callRemoveSvv(therapy);
    this.svvDataSource.data = this.svvDataSource.data;
  }

  callRemoveSvv(therapy: SvvTherapy) {
    this.vestibularService.activateSvvTherapy(therapy, false).subscribe(() => {
      console.log('Removed');
    });
  }

  getStudyId(): void {
    if (this.selectedPatient) {
      this.patientService
        .getCohortForPatient(this.selectedPatient?.uuid)
        .subscribe((cohort) => {
          console.log(cohort.studyId);
          this.studyId = cohort.studyId.toString();
          console.log(this.studyId);
          this.getFavorites();
        });
    }
  }

  getFavorites() {
    this.vorFavoritesService
      .getFavorites(this.studyId)
      .subscribe((therapy: any) => {
        let sortedTherapy = therapy['favoriteTherapies'].sort(
          (a: any, b: any) => a.id - b.id
        );
        for (let index in sortedTherapy) {
          this.favorites.push({
            name:
              'Favorite ' +
              (+index + 1) +
              ': ' +
              therapy['favoriteTherapies'][index].description +
              ' / Dosage: ' +
              therapy['favoriteTherapies'][index].dosage +
              ' / Duration: ' +
              therapy['favoriteTherapies'][index].duration +
              ' / Speed: ' +
              therapy['favoriteTherapies'][index].speed +
              ' / ' +
              therapy['favoriteTherapies'][index].exerciseType,
            therapy: therapy['favoriteTherapies'][index],
          });
        }
      });
  }

  setFavorite(fav: any) {
    this.favoriteTherapy = fav;
  }

  assignFavorite() {
    if (this.favoriteTherapy && this.selectedPatient) {
      const newFavorite: Therapy = {
        id: -1,
        patientGuid: this.selectedPatient.uuid,
        copiedFrom: null,
        effectiveStart: this.favoriteTherapy?.effectiveStart,
        dateCreated: '2023-11-01T18:17:20Z',
        backgroundContrast: this.favoriteTherapy?.backgroundContrast,
        lastUpdated: '2023-11-01T18:17:20Z',
        exerciseType: this.favoriteTherapy?.exerciseType,
        recordVideo: this.favoriteTherapy?.recordVideo,
        backgroundDirection: this.favoriteTherapy?.backgroundDirection,
        effectiveEnd: this.favoriteTherapy?.effectiveEnd,
        duration: this.favoriteTherapy?.duration,
        speed: this.favoriteTherapy?.speed,
        uploadVideo: this.favoriteTherapy?.uploadVideo,
        dosage: this.favoriteTherapy?.dosage,
        fontSize: this.favoriteTherapy?.fontSize,
        comment: this.favoriteTherapy?.comment,
        backgroundPattern: this.favoriteTherapy?.backgroundPattern,

        fontColor: this.favoriteTherapy?.fontColor,
        background: this.favoriteTherapy?.background,
        backgroundSpeed: this.favoriteTherapy?.backgroundSpeed,
        distance: this.favoriteTherapy?.distance,
        backgroundSize: this.favoriteTherapy?.backgroundSize,
        optoType: this.favoriteTherapy?.optoType,
        description: this.favoriteTherapy?.description,
        backgroundColor: this.favoriteTherapy?.backgroundColor,
      };
      console.log(this.favoriteTherapy);
      console.log(newFavorite);
      this.vestibularService.createTherapy(newFavorite).subscribe({
        next: (x) => {
          this.vestibularService
            .getTherapies(this.selectedPatient?.uuid)
            .subscribe((therapy) => {
              therapy.therapies.sort((a: any, b: any) => {
                return b.id - a.id;
              });
              console.log(therapy);
              this.therapies = therapy;
              this.vorDataSource.data = therapy.therapies;
            });
        },
      });
    }
  }

  checkForCommandsQueued() {
    this.vestibularService.checkForCommands().subscribe((x: any) => {
      if (x.commandQueueSize < 1) {
        this.commandsQueued = false;
      } else {
        this.commandsQueued = true;
      }
    });
  }

  startTest(command: any) {
    console.log(command);
    let runCommand = {
      therapyId: command,
    };
    this.vestibularService.runCommand(runCommand).subscribe({
      next: (x) => {
        this.checkForCommandsQueued();
      },
    });
  }

  tabChanged(event: any) {
    if (this.vestibularService.activeTab != event.index) {
      this.vestibularService.activeTab = event.index;
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
}
