import { Contrast, Patterns, Size } from './vestibular.service';

export class Background {
  root: string;
  backgroundThumb: string = '';

  src: string = '';
  size: number = 0;
  contrast: number = 0;

  constructor(
    public name: string,
    public id: Patterns,
    root: string,
    public useContrast: boolean,
    public useSize: boolean,
    public useDirection: boolean,
    public useMediumSize: boolean,
  ) {
    this.root = root;
    this.update(this.contrast, this.size);
  }

  get thumbValue() {
    return this.backgroundThumb;
  }

  set thumbValue(src) {
    this.backgroundThumb = src;
  }

  update(contrast: number, size: number) {
    this.contrast = contrast;
    this.size = size;

    var file = `${this.root}${
      this.useContrast ? this.convertContrast(this.contrast) : ''
    }${this.useSize ? this.convertSize(this.size) : ''}`;

    this.src = `/assets/vestibular/optokinetic/${file}_preview.png`;
    this.thumbValue = `/assets/vestibular/optokinetic/${file}_thumbnail.png`;
  }

  convertContrast(id: Contrast) {
    switch (+id) {
      case Contrast.Low:
        return '_low_contrast';
      case Contrast.Medium:
        return '_medium_contrast';
      case Contrast.High:
        return '_high_contrast';
      default:
        return `[${id}]`;
    }
  }

  convertSize(id: Size) {
    switch (+id) {
      case Size.Small:
        return '_sm';
      case Size.Medium:
        return this.useMediumSize ? '_med' : '_sm';
      case Size.Large:
        return '_lg';
      default:
        return `[${id}]`;
    }
  }
}
