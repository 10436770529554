import { Component } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { HomeService } from '../home.service';
import { StudySummaryComponent } from '../study-summary/study-summary.component';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { Role } from '../guards/role.guard';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    StudySummaryComponent,
    NgIf,
    NgFor,
    MatGridListModule,
    RouterModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(
    public homeService: HomeService,
  ) {}

  protected readonly Role = Role;
}
