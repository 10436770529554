import { AfterViewInit, Component, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { PatientDemographics, MdermPatientProfile } from '../patient';
import { PatientService } from '../patient.service';

@Component({
  selector: 'patient-profile',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  standalone: true,
  imports: [
    MatSelectModule,
    MatTableModule,
    MatListModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    MatCheckboxModule,
    NgIf,
  ],
})
export class PatientComponent {
  @Input()
  @Output()
  public mdermProfile: MdermPatientProfile | undefined;

  @Input()
  public selectedPatient: PatientDemographics | undefined;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
  ) {}

  saveProfile() {}

  setActive(isActive: boolean) {
    if (this.selectedPatient) {
      this.patientService
        .saveActiveState(this.selectedPatient.uuid, isActive)
        .subscribe();
    }

    console.log(this.selectedPatient?.uuid);
  }
}
