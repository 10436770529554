import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';

export enum Role {
  PartnerAdmin = 'ROLE_PARTNER_ADMIN',
  Clinician = 'ROLE_CLINICIAN',
  Patient = 'ROLE_PATIENT',
}

export const RoleGuard: CanActivateFn = 
(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot):
Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean => {

  const keycloakService = inject(KeycloakService)
  const router = inject(Router)
  
  if (keycloakService.isUserInRole(Role.Patient as string)) {
    router.navigate(['/error'], {
      queryParams: {
        message: 'Please log in as a clinician!',
      }
    })
    return false
  }
  
  if (activatedRouteSnapshot.data['roles']) {
    const roles = activatedRouteSnapshot.data['roles'] as string[]
    let hasRole = false;
    for (const role of roles) hasRole = hasRole || keycloakService.isUserInRole(role)
    if (!hasRole) {
      router.navigate(['/error'], {
        queryParams: {
          message: 'You do not have permission to access this page!',
        }
      })
    }
    return hasRole
  } else {
    return true
  }
}
