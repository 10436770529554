import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import {
  Patient,
  PatientDemographics,
  PatientOnsets,
  PatientReport,
  PatientReportSummary,
  PatientSymptom,
} from '../patient';
import { PatientService } from '../patient.service';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { Diagnosis } from '../attribute.service';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { PatientComponent } from '../patient/patient.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { BodyMapModule, BodyPart } from '@nxthealth/bodymap';
import { DermatologyService } from '../services/dermatology.service';
import * as moment from 'moment';

export interface PatientReportTableData {
  id: string;
  bodyPartPictures: any[];
  symptoms: PatientSymptom[];
  onsets: PatientOnsets[];
  aiDiagnosis: Diagnosis | null;
  drDiagnosis: Diagnosis | null;
  feedback: boolean;
  bodyMapLocation?: string;
  clinicalName?: string;
  reportDate: string;
}

@Component({
  selector: 'patient-mderm-report-list-component',
  templateUrl: './patient-mderm-report-list.component.html',
  styleUrls: ['./patient-mderm-report-list.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    MatTableModule,
    MatDividerModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgFor,
    MatGridListModule,
    MatListModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    PatientComponent,
    BodyMapModule,
    MatProgressSpinnerModule,
  ],
})
export class PatientMdermReportListComponent implements OnInit {
  reports?: PatientReportSummary;

  // Human Map Vars
  selectedBodyParts: BodyPart[] = [];
  filteredBodyParts: BodyPart[] = [];
  readonly: boolean = false;
  bodyMapFilter: boolean = false;
  loading = true;

  filteredArray: PatientReportTableData[] = [];

  @Input('patientId')
  patientId: string = '';

  @Input('currentPatient')
  selectedPatient?: PatientDemographics;

  displayedColumns = [
    'id',
    'reportDate',
    'bodyMapLocation',
    'aiDiagnosis',
    'drDiagnosis',
    'feedback',
    'numPhotos',
  ];
  // dataToDisplay = [...REPORT_DATA];
  dataSource = new MatTableDataSource<PatientReportTableData>();

  clinicalNames: Map<BodyPart, string> = new Map([
    ['front_skull_right', 'right frontal scalp'],
    ['front_skull_left', 'left frontal scalp'],
    ['front_face_right', 'right face'],
    ['front_face_left', 'left face'],
    ['front_neck_right', 'right anterior neck'],
    ['front_neck_left', 'left anterior neck'],
    ['front_shoulder_right', 'right anterior shoulder'],
    ['front_shoulder_left', 'left anterior shoulder'],
    ['front_chest_right', 'right chest'],
    ['front_chest_left', 'left chest'],
    ['front_upper_arm_right', 'right anterior upper arm'],
    ['front_upper_arm_left', 'left anterior upper arm'],
    ['front_abdomen_right', 'right abdomen'],
    ['front_abdomen_left', 'left abdomen'],
    ['front_elbow_right', 'right antecubital fossa'],
    ['front_elbow_left', 'left antecubital fossa'],
    ['front_lower_arm_right', 'right ventral forearm'],
    ['front_lower_arm_left', 'left ventral forearm'],
    ['front_hip_right', 'right anterior hip'],
    ['front_hip_left', 'left anterior hip'],
    ['front_wrist_right', 'right ventral wrist'],
    ['front_wrist_left', 'left ventral wrist'],
    ['front_groin_right', 'right groin'],
    ['front_groin_left', 'left groin'],
    ['front_hand_right', 'right palm'],
    ['front_hand_left', 'left palm'],
    ['front_thigh_right', 'right anterior thigh'],
    ['front_thigh_left', 'left anterior thigh'],
    ['front_knee_right', 'right knee'],
    ['front_knee_left', 'left knee'],
    ['front_lower_leg_right', 'right anterior lower leg'],
    ['front_lower_leg_left', 'left anterior lower leg'],
    ['front_ankle_right', 'right anterior ankle'],
    ['front_ankle_left', 'left anterior ankle'],
    ['front_foot_right', 'right foot'],
    ['front_foot_left', 'left foot'],
    ['back_skull_left', 'left posterior scalp'],
    ['back_skull_right', 'right posterior scalp '],
    ['back_head_left', 'left posterior head'],
    ['back_head_right', 'left posterior head'],
    ['back_neck_left', 'left posterior neck'],
    ['back_neck_right', 'right posterior neck'],
    ['back_shoulder_left', 'left posterior shoulder'],
    ['back_shoulder_right', 'right posterior shoulder'],
    ['back_upper_back_left', 'left posterior upper back'],
    ['back_upper_back_right', 'right posterior upper back'],
    ['back_upper_arm_left', 'left posterior upper arm'],
    ['back_upper_arm_right', 'right posterior upper arm'],
    ['back_mid_back_left', 'left posterior mid-back'],
    ['back_mid_back_right', 'right posterior mid-back'],
    ['back_elbow_left', 'left elbow'],
    ['back_elbow_right', 'right elbow'],
    ['back_lower_arm_left', 'left dorsal forearm'],
    ['back_lower_arm_right', 'right dorsal forearm'],
    ['back_lower_back_left', 'left lower back'],
    ['back_lower_back_right', 'right lower back'],
    ['back_hip_left', 'left posterior hip'],
    ['back_hip_right', 'right posterior hip'],
    ['back_buttocks_left', 'left buttocks'],
    ['back_buttocks_right', 'right buttocks'],
    ['back_wrist_left', 'left dorsal wrist'],
    ['back_wrist_right', 'right dorsal wrist'],
    ['back_hand_left', 'left dorsal hand'],
    ['back_hand_right', 'right dorsal hand'],
    ['back_thigh_left', 'left posterior thigh'],
    ['back_thigh_right', 'right posterior thigh'],
    ['back_knee_left', 'left popliteal fossa'],
    ['back_knee_right', 'right popliteal fossa'],
    ['back_calf_left', 'left posterior lower leg'],
    ['back_calf_right', 'right posterior lower leg'],
    ['back_ankle_left', 'left posterior ankle'],
    ['back_ankle_right', 'right posterior ankle'],
    ['back_foot_left', 'left sole'],
    ['back_foot_right', 'right sole'],
  ]);

  clinicalName? = '';

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @ViewChild('input') filterInputDiv: ElementRef | undefined;

  constructor(
    private patientService: PatientService,
    private dermatologyService: DermatologyService
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.bodyMapFilter = true;
    // Handle sorting objects
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'reportDate':
          return new Date(item.reportDate).getTime();
        case 'aiDiagnosis':
          return item.aiDiagnosis?.name!;
        case 'drDiagnosis':
          return item.drDiagnosis?.name!;
        case 'feedback':
          return item.feedback ? 'Yes' : 'No';
        case 'numPhotos':
          return item.bodyPartPictures?.length;
        default:
          return item[property as keyof PatientReportTableData] as string;
      }
    };
  }

  ngOnInit(): void {
    this.getPatientProfile();
  }

  getPatientProfile(): void {
    this.dermatologyService
      .getPatientReports(this.patientId)
      .subscribe((reports) => {
        this.reports = reports;
        this.getTableData();
      });
  }

  filteredBodyPartsChange() {
    const filterPartsReportArray: PatientReportTableData[] = [];
    if (this.filteredBodyParts.length == 0) {
      // Reset data
      this.dataSource.data = this.filteredArray;
    } else {
      // Apply body parts filter to data
      this.filteredArray.forEach((rep) => {
        rep.bodyMapLocation?.split(', ').forEach((repBodyMapLocation) => {
          if (this.filteredBodyParts.includes(repBodyMapLocation as BodyPart)) {
            filterPartsReportArray.push(rep);
          }
        });
      });

      this.dataSource.data = filterPartsReportArray;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTableData(): void {
    const bpm: Map<string, string> = new Map();
    this.reports?.reports?.forEach((report: PatientReport) => {
      this.filteredArray.push({
        id: report?.id,
        bodyMapLocation: report?.bodyMapLocation,
        clinicalName: this.ClinicalName(report?.bodyMapLocation),
        symptoms: report?.symptoms,
        onsets: report.onsets,
        aiDiagnosis: report.aiDiagnosis,
        drDiagnosis: report.drDiagnosis,
        feedback: report.feedback,
        reportDate: moment(report.reportDate).format('MMM D, YYYY, h:mm A'),
        bodyPartPictures: report.bodyPartPictures,
      });

      if (report?.bodyMapLocation != '') {
        report?.bodyMapLocation.split(', ').forEach((bodyPart: string) => {
          const bp = bodyPart.trim();
          if (this.clinicalNames.has(bp as BodyPart)) {
            bpm.set(bp, bp);
          }
        });
      }
    });
    this.dataSource.data = this.filteredArray;
    this.selectedBodyParts = [...bpm.keys()] as BodyPart[];
    this.loading = false;
  }

  ClinicalName(bodyPart: string) {
    const name = bodyPart.split(', ');
    const clinicalNameList = [];
    for (let item in name) {
      this.clinicalName = this.clinicalNames.get(name[item] as BodyPart);
      if (this.clinicalName != 'undefined') {
        clinicalNameList.push(this.clinicalName);
      }
    }
    return clinicalNameList.join(', ');
  }

  humanReadableBodyPartName(bodyPart: string) {
    const name = bodyPart.split('_');
    name.forEach((e, i, a) => (a[i] = e[0]?.toUpperCase() + e.slice(1)));
    name.unshift(name.pop() || '');
    if (name.toString() === 'undefined') {
      return '';
    } else {
      return name.join(' ');
    }
  }
}
