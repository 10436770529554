import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
import { List } from 'immutable';
import { SimpleCohortDescription } from './patient';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { StudyService } from './study.service';

export interface Study {
  id: number;
  name: string;
  features: string[];
  timezone: string;
  notes: string;
  lastUpdated: string;
  numParticipants: number;
}

export interface StudyDescription {
  id: number;
  name: string;
  features: string[];
  notes: string;
  cohorts: SimpleCohortDescription[];
}

export interface CohortsByStudy {
  studies: StudyDescription[];
}

export class StudyCohort {
  studyId: number = 0;
  cohortId: number;
  cohortGuid: string = '';
  studyName: string = '';
  cohortName: string = '';

  constructor(
    study: number,
    cohortId: number,
    cohortGuid: string,
    studyName: string,
    cohortName: string,
  ) {
    this.studyId = study;
    this.cohortId = cohortId;
    this.cohortGuid = cohortGuid;
    this.studyName = studyName;
    this.cohortName = cohortName;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private _list: BehaviorSubject<StudyCohort[]> = new BehaviorSubject<
    StudyCohort[]
  >([]);

  public readonly allCohorts: Observable<StudyCohort[]> =
    this._list.asObservable();

  studiesUrl: string = environment.permissionsUrl + '/study/index';

  constructor(
    private studyService: StudyService,
    private http: HttpClient,
  ) {
    this.loadInitialData();
  }

  loadInitialData() {
    this.studyService.allStudies.subscribe((studyDescriptions) => {
      let list: StudyCohort[] = [];
      studyDescriptions.forEach((description) =>
        description.cohorts.forEach((cohort) => {
          list.push(
            new StudyCohort(
              description.id,
              +cohort.id,
              cohort.guid,
              description.name,
              cohort.treatment,
            ),
          );
        }),
      );

      this._list.next(list);
    });
  }
}
