import { Component, Input, OnInit } from '@angular/core';
import { PatientService } from '../patient.service';
import { TestDetails } from '../patient';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { MindcapService } from '../mindcap.service';

@Component({
  selector: 'app-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.css'],
  standalone: true,
  imports: [
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgFor,
  ],
})
export class TestResultComponent implements OnInit {
  @Input('testId')
  testId: number = 208;

  public test: TestDetails | undefined;

  topColumns: string[] = ['startTime', 'completeTime'];

  trailsColumns: string[] = [
    'testSection',
    'currentIndex',
    'currentIndexText',
    'clickedIndexText',
    'clickedIndex',
    'timeClicked',
  ];

  novaColumns: string[] = [
    'nPreviousDenominator',
    'nNumerator',
    'nPreviousNumerator',
    'dUserCorrect',
    'questionIndexPosition',
    'dLevel',
    'sUserReactionTime',
    'nUserCorrect',
    'precedingInterTrialInterval',
    'dReactionTime',
    'nResponse',
    'nDenominator',
  ];

  constructor(
    private route: ActivatedRoute,
    private mindcapService: MindcapService,
  ) {
    // @ts-ignore
    this.testId = +this.route.snapshot.paramMap.get('id') || 0;
  }

  ngOnInit() {
    this.getTest();
  }

  getTest(): void {
    this.mindcapService.getTest(this.testId).subscribe((test) => {
      this.test = test;
    });
  }
}
