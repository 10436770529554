// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
}

.user-card {
  max-width: 500px;
  margin: auto;
  margin-top: 3%;
  margin-left: 3%;
}

.card-header {
  justify-content: center;
}

.input-row {
  display: flex;
  flex-wrap: nowrap;
}

.input-column {
  flex: 1;
  padding: 10px;
}

.input-column > mat-form-field {
  width: 100%;
  margin-bottom: 5px;
}

.checkbox-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.checkbox-item {
  margin: 0 10px;
}

.input-column > .checkbox-row {
  width: 100%;
}

.footer-buttons {
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
}

.footer-button {
  flex-grow: 1;
  margin: 0 5px;
}

.footer-button.middle {
  flex-basis: 40%;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/patient-admin/patient-admin.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAGA;EACI,gBAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;AAAJ;;AAGA;EACI,uBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,OAAA;EACA,aAAA;AAAJ;;AAGA;EACI,WAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,cAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ;;AAGA;EACI,aAAA;EACA,6BAAA;EACA,cAAA;AAAJ;;AAGA;EACI,YAAA;EACA,aAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ","sourcesContent":[":host {\n  flex-grow: 1;\n}\n\n\n.user-card {\n    max-width: 500px;\n    margin: auto;\n    margin-top: 3%;\n    margin-left: 3%;\n}\n\n.card-header {\n    justify-content: center;\n}\n\n.input-row {\n    display: flex;\n    flex-wrap: nowrap;\n}\n\n.input-column {\n    flex: 1;\n    padding: 10px;\n}\n\n.input-column > mat-form-field {\n    width: 100%;\n    margin-bottom: 5px;\n}\n\n.checkbox-row {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 5px;\n}\n\n.checkbox-item {\n    margin: 0 10px;\n}\n\n.input-column > .checkbox-row {\n    width: 100%;\n}\n\n.footer-buttons {\n    display: flex;\n    justify-content: space-around;\n    padding: 5px 0;\n}\n\n.footer-button {\n    flex-grow: 1;\n    margin: 0 5px;\n}\n\n.footer-button.middle {\n    flex-basis: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
