import { Injectable } from '@angular/core';
import { ListPatients, PatientService } from '../patient.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PatientDemographics } from '../patient';
import { NetworkErrorService } from '../network-error.service';

export class NewPatient {
  patientId: string = '';
  emailAddress: string = '';
  givenName: string = '';
  familyName: string = '';
  cohortId: number = -1;
  active: boolean = true;
  notes: string = '';
}

@Injectable({
  providedIn: 'root',
})
export class PatientCreateService {
  private permissionsUrl = environment.permissionsUrl;

  private patientCreateUrl = `${this.permissionsUrl}/api/createPatient`; // URL to web api

  constructor(
    private patientService: PatientService,
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {}

  createPatient(patient: NewPatient): Observable<PatientDemographics> {
    var x = this.http.post<PatientDemographics>(this.patientCreateUrl, patient);
    // .pipe(
    //catchError(this.networkErrorService.handleError<PatientDemographics>('getPatients', undefined))
    //);

    return x;
  }
}
