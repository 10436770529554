import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  CalendarCommonModule,
  CalendarView,
} from '@nxthealth/angular-calendar-noscarf';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-calendar-header-vestaid',
  standalone: true,
  imports: [CalendarCommonModule, MatButtonModule, MatIconModule],
  templateUrl: './calendar-header-vestaid.component.html',
  styleUrls: ['./calendar-header-vestaid.component.scss'],
})
export class CalendarHeaderVestaidComponent {
  @Input() view!: CalendarView;

  @Input() viewDate!: Date;

  @Input() locale: string = 'en';

  @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;
}
