// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
}

.mat-expansion-panel-header-title {
  font-weight: bold;
  font-size: 1.5em;
}

.mat-sidenav-container {
  height: 100%;
}

.button-row {
  display: table-cell;
  max-width: 600px;
}

.button-row .mat-mdc-button-base {
  margin: 8px 8px 8px 0;
}

::ng-deep .mat-drawer-inner-container {
  overflow-y: hidden !important;
}

.sidenav {
  width: 350px;
  padding-left: 10px;
  padding-right: 10px;
}

.sidenav-header-container {
  z-index: 5;
  background-color: white;
  margin-right: 25px;
}

.sidenav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidenav-header-title {
  flex-grow: 1;
  text-align: center;
}

.sidenav-body {
  height: 90%;
  overflow-y: auto;
}

.sidenav-card {
  margin-top: 5px;
}

.sidenav-card-content {
  padding: 0 !important;
}

.sidenav-filter {
  width: 95%;
  font-size: large;
  border-radius: 5px;
  padding: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/cohort/cohort.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACE,mBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,6BAAA;AAAF;;AAGA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;AAAF;;AAIA;EACE,UAAA;EACA,uBAAA;EACA,kBAAA;AADF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;;AAIA;EACE,YAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AADF","sourcesContent":[":host {\n  flex-grow: 1;\n}\n\n.mat-expansion-panel-header-title {\n  font-weight: bold;\n  font-size: 1.5em;\n}\n\n\n.mat-sidenav-container {\n    height: 100%;\n}\n\n.button-row {\n  display: table-cell;\n  max-width: 600px;\n}\n\n.button-row .mat-mdc-button-base {\n  margin: 8px 8px 8px 0;\n}\n\n::ng-deep .mat-drawer-inner-container {\n  overflow-y: hidden !important;\n}\n\n.sidenav {\n  width: 350px;\n  padding-left: 10px;\n  padding-right: 10px;\n\n}\n\n.sidenav-header-container {\n  z-index: 5;\n  background-color: white;\n  margin-right: 25px;\n}\n\n.sidenav-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.sidenav-header-title {\n  flex-grow: 1;\n  text-align: center;\n}\n\n.sidenav-body {\n  height: 90%;\n  overflow-y: auto;\n}\n\n.sidenav-card {\n  margin-top: 5px;\n}\n\n.sidenav-card-content {\n  padding: 0 !important;\n}\n\n.sidenav-filter {\n  width: 95%;\n  font-size: large;\n  border-radius: 5px;\n  padding: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
