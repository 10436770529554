import { Injectable } from '@angular/core';
import {
  CohortDescription, MindcapPatientCharts,
  ScheduleInfo, SpiderChart,
  SurveyDetails,
  SurveyList,
  SurveyResponses,
  TestDetails,
  TestList,
} from './patient';
import { catchError, Observable, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AttributeData } from './attribute.service';
import { NetworkErrorService } from './network-error.service';
import { environment } from '../environments/environment';

// export interface CognitiveDashboardTableData {
//   patientId: string;
//   patientGuid: string;
//   patientName: string;
//   studyStart: Date;
//   lastApp: Date;
//   lastSurveyTest: Date;
//   appVersion: string;
//   initialSurvey: string;
//   practiceStatus: string;
//   daysPractice: number;
//   normalStatus: string;
//   daysNormal: number;
//   treatementStart: Date;
//   testingStatus: string;
//   weeksIntoTesting: number;
//   treatmentEnd: Date;
//   postTreatmentSurveys: number;
//   finalSurveyStatus: string;
// }

export interface DashboardData {
  patients: CognitiveDashboardTableData[];
}

export interface CognitiveDashboardTableData {
  patientGuid: string;
  patientId: string;
  patientName: string;
  engagement: string;
  stage: string;
  lastChange: string;
  lastOnset: string;
  lastEnd: string;
  duration: string;
}

@Injectable({
  providedIn: 'root',
})
export class MindcapService {
  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {}

  dashboardUrl: string = `${environment.mindcapUrl}/api/dashboard`;

  private scheduleUrl: string = `${environment.mindcapUrl}/api/getSchedule`;
  private listUrl: string = `${environment.mindcapUrl}/mindCapTest`;
  private testUrl: string = `${environment.mindcapUrl}/api/test`;
  private surveyDetailsUrl: string = `${environment.mindcapUrl}/api/v1/survey/get`;
  private surveyResponseUrl: string = `${environment.mindcapUrl}/api/v1/survey/response`;
  private surveyListUrl: string = `${environment.mindcapUrl}/survey/index`;
  private spiderChartUrl: string = `${environment.mindcapUrl}/mindCapTest/analyticsGraphs`;

  getDashboard(
    cohort: CohortDescription,
  ): Observable<CognitiveDashboardTableData[]> {
    let patients = cohort.patients.map((x) => x.uuid);
    return this.http
      .post<CognitiveDashboardTableData[]>(this.dashboardUrl, {
        patientGuids: patients,
      })
      .pipe(
        tap((_) =>
          this.networkErrorService.log(
            'fetched dashboard for cohort ' + cohort.uuid,
          ),
        ),
        catchError(
          this.networkErrorService.handleError<CognitiveDashboardTableData[]>(
            'getDashboard',
            undefined,
          ),
        ),
      );
  }

  getDashboardNew(cohortGuid: string): Observable<DashboardData> {
    let params = new HttpParams();
    params = params.append('selectedCohorts', cohortGuid);
    params = params.append('filterAction', 'selectedCohorts');

    return this.http.get<DashboardData>(this.dashboardUrl, { params }).pipe(
      tap((_) => console.log('fetched dashboard')),
      catchError(
        this.networkErrorService.handleError<DashboardData>(
          'getDashboardNew',
          undefined,
        ),
      ),
    );
  }

  updateMetrics(patientGuid: string): Observable<string> {
    let params = new HttpParams();
    params = params.append('uuid', patientGuid);

    return this.http.get<string>(
      environment.mindcapUrl + '/mindCapTest/updateMetrics',
      { params },
    );
  }

  //GET patient schedule reminder details from the server
  getPatientScheduleReminder(patientGuid: string): Observable<ScheduleInfo> {
    let params = new HttpParams();
    params = params.append('guid', patientGuid);

    return this.http.get<ScheduleInfo>(this.scheduleUrl, { params }).pipe(
      tap((_) => console.log('fetched patient schedule reminders')),
      catchError(
        this.networkErrorService.handleError<ScheduleInfo>(
          'getPatientScheduleReminder',
          undefined,
        ),
      ),
    );
  }

  //Get List of tests
  getTestList(selectedPatients: string): Observable<TestList> {
    let params = new HttpParams();
    params = params.append('selectedPatients', selectedPatients);
    params = params.append('filterAction', 'selectedPatients');

    return this.http.get<TestList>(this.listUrl, { params }).pipe(
      tap((_) => console.log('fetched test list')),
      catchError(
        this.networkErrorService.handleError<TestList>(
          'getTestList',
          undefined,
        ),
      ),
    );
  }

  //get Specific test
  getTest(id: number): Observable<TestDetails> {
    let params = new HttpParams();
    params = params.append('id', id);

    return this.http.get<TestDetails>(this.testUrl, { params }).pipe(
      tap((_) => console.log('fetched test detail')),
      catchError(
        this.networkErrorService.handleError<TestDetails>('getTest', undefined),
      ),
    );
  }

  //Get Survey
  getSurvey(id: number): Observable<SurveyDetails> {
    let params = new HttpParams();
    params = params.append('id', id);

    return this.http.get<SurveyDetails>(this.surveyDetailsUrl, { params }).pipe(
      tap((_) => console.log('fetched survey details')),
      catchError(
        this.networkErrorService.handleError<SurveyDetails>(
          'getSurvey',
          undefined,
        ),
      ),
    );
  }

  //Get Survey response
  getSurveyResponses(id: number): Observable<SurveyResponses> {
    let params = new HttpParams();
    params = params.append('id', id);

    return this.http
      .get<SurveyResponses>(this.surveyResponseUrl, { params })
      .pipe(
        tap((_) => console.log('fetched survey responses')),
        catchError(
          this.networkErrorService.handleError<SurveyResponses>(
            'getSurveyResponses',
            undefined,
          ),
        ),
      );
  }

  //Get All surveys for patient
  getSurveysForPatient(selectedPatient: string): Observable<SurveyList> {
    let params = new HttpParams();
    params = params.append('selectedPatients', selectedPatient);

    return this.http.get<SurveyList>(this.surveyListUrl, { params }).pipe(
      tap((_) => console.log('fetched survey list')),
      catchError(
        this.networkErrorService.handleError<SurveyList>(
          'getSurveysForPatient',
          undefined,
        ),
      ),
    );
  }


  getPatientCharts( selectedPatient: string ) : Observable<MindcapPatientCharts>
  {
    let params = new HttpParams();
    params = params.append('patientGuid', selectedPatient);

    return this.http.get<MindcapPatientCharts>(this.spiderChartUrl, { params }).pipe(
      tap((_) => console.log('fetched spider chart')),
      catchError(
        this.networkErrorService.handleError<MindcapPatientCharts>(
          'getSurveysForPatient',
          undefined,
        ),
      ),
    );
  }


}
