import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { NgIf } from '@angular/common';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexMarkers,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import {MindcapService} from "../../mindcap.service";
import {Observable, Subscription} from "rxjs";
import {MindcapEvents, MindcapPatientCharts} from "../../patient";

export type ChartOptions  = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  fill: ApexFill;
  markers: ApexMarkers;
  xaxis: ApexXAxis;
};

@Component({
  selector: 'app-spider-chart',
  templateUrl: './spider-chart.component.html',
  styleUrls: ['./spider-chart.component.css'],
  standalone: true,
  imports: [NgApexchartsModule, NgIf],
})
export class SpiderChartComponent implements OnInit, OnDestroy {

  @ViewChild("chart") chart?: ChartComponent;

  public chartOptions: ChartOptions;

  @Input()
  patientGuid : string = "";

  @Input()
  loadData? : Observable<MindcapPatientCharts>;

  private eventsSubscription? : Subscription;
  private lineSelectedSubscription? : Subscription;

  private graphData? : MindcapPatientCharts;


  @Input()
  lineSelected : Observable<number> = new Observable<number>();


  constructor(
    private mindcapService : MindcapService
  )  {
    this.chartOptions = {
      series: [ ],
      chart: {
        height: 350,
        type: 'radar',
        dropShadow: {
          enabled: false,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      title: {
        text: 'Relative Cognitive Capacity (%)',
      },
      stroke: {
        width: 1,
      },
      fill: {
        opacity: 0,
      },
      markers: {
        size: 3,
      },
      xaxis: {
        categories: [
          'Attention',
          'Exec Function',
          'Memory',
          'PsychMotor',
          'Verbal',
          'Visual',
        ],
      },
    };
  }


  ngOnInit() {
    this.eventsSubscription = this.loadData?.subscribe(( x : MindcapPatientCharts) => this.loadGraphData( x ));
    this.lineSelectedSubscription = this.lineSelected.subscribe( (x : any ) => this.onLineSelected(x) )
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
    this.lineSelectedSubscription?.unsubscribe();
  }

  loadGraphData( data : MindcapPatientCharts) {

    data.spider.forEach( (x) => x.relcap.sort( (a, b) =>  - (a.domain > b.domain ? 1 : -1) ))

    this.graphData = data;


    this.loadGraph(data.spider.length - 1)
  }

  loadGraph( eventIndex : number )
  {
    if( this.chartOptions && this.graphData ) {
      this.chartOptions.series = [
        {
          name: 'START: Date',
          data: this.graphData.spider[eventIndex].relcap.map(c => c.start)
        },
        {
          name: 'LOWEST: Date',
          data: this.graphData.spider[eventIndex].relcap.map(c => c.mid)
        },
        {
          name: 'END: Date',
          data: this.graphData.spider[eventIndex].relcap.map(c => c.end)
        }
      ]
      this.chart?.updateOptions( this.chartOptions)
    }
  }

  onLineSelected( index: number )
  {
    if( this.graphData )
    {
      let selectedTime =this.graphData.tN[ index ]
      let evt = this.getClosest( this.graphData.events, selectedTime )
      this.loadGraph( evt )
    }

  }


  getClosest( events : MindcapEvents[], selectedTime: number )
  {
    selectedTime *= 1000;
    for( let x = 0; x< events.length; ++x )
    {
      if( selectedTime < new Date(events[x].start).getTime() )
      {
        return Math.max( x - 1, 0 );
      }
    }
    return events.length - 1
  }
}
