import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { PatientDemographics } from '../../patient';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { PatientCalendarComponent } from '../../patient-calendar/patient-calendar.component';
import { ScheduleReminderComponent } from '../../Schedule-Reminder/Schedule-Reminder.component';
import { TestListComponent } from '../../test-list/test-list.component';
import {
  SvvTherapy,
  Therapies,
  Therapy,
  TherapyList,
  VestibularService,
} from '../vestibular.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouterLink } from '@angular/router';
import { CognitiveDashboardTableData } from '../../mindcap.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { mixinColor } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { svvTherapyReport } from '../svv-results/svv-results.component';
import { therapyReport } from '../vor-results/vor-results.component';
import { PatientService } from '../../patient.service';
import { VorFavoritesService } from '../vor-favorites.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-vestibular-reports',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    PatientCalendarComponent,
    ScheduleReminderComponent,
    TestListComponent,
    RouterLink,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatGridListModule,
    MatSortModule,
    MatTooltipModule,
  ],
  templateUrl: './vestibular-reports.component.html',
  styleUrls: ['./vestibular-reports.component.scss'],
})
export class VestibularReportsComponent implements OnInit, AfterViewInit {
  @Input('patientId')
  patientId: string = '';

  @Input('currentPatient')
  selectedPatient?: PatientDemographics;

  @ViewChild('vorPaginator') vorPaginator: MatPaginator | null = null;

  @ViewChild('svvPaginator') svvPaginator: MatPaginator | null = null;
  // @ts-ignore
  @ViewChildren(MatSort) sort: QueryList<MatSort>;

  public therapies!: TherapyList;

  vorDataSource = new MatTableDataSource<therapyReport>();
  svvDataSource = new MatTableDataSource<svvTherapyReport>();

  vorDisplayedColumns: string[] = [
    'alert',
    'exerciseStartTime',
    'description',
    'therapyId',
    'action',
  ];

  svvDisplayedColumns: string[] = [
    'exerciseStartTime',
    'description',
    'therapyId',
    'action',
  ];

  vorActive: Observable<number> = this.vestibularService.activeTabObs;
  vorActiveTab: number = 0;

  constructor(public vestibularService: VestibularService) {
    this.vorDataSource.data = [];
    this.svvDataSource.data = [];
  }

  ngAfterViewInit(): void {
    this.vorDataSource.paginator = this.vorPaginator;
    if (this.sort) {
      // @ts-ignore
      this.vorDataSource.sort = this.sort.get(0);
    }

    this.svvDataSource.paginator = this.svvPaginator;

    if (this.sort) {
      // @ts-ignore
      this.svvDataSource.sort = this.sort.get(1);
    }

    this.vorActive.subscribe((x) => {
      this.vorActiveTab = x;
    });
  }

  ngOnInit() {
    if (this.selectedPatient) {
      this.vestibularService
        .getReports(this.selectedPatient.uuid)
        .subscribe((result: any) => {
          for (let item in result.svvReports) {
            switch (result.svvReports[item].type) {
              case 'RRR':
                result.svvReports[item].type = 'Random Trials';
                break;
              case 'SFD':
                result.svvReports[item].type = 'Batch 1: Standard, Frame, Dots';
                break;
              case 'SDF':
                result.svvReports[item].type = 'Batch 2: Standard, Dots, Frame';
                break;
              case 'FSD':
                result.svvReports[item].type = 'Batch 3: Frame, Standard, Dots';
                break;
              case 'FDS':
                result.svvReports[item].type = 'Batch 4: Frame, Dots, Standard';
                break;
              case 'DSF':
                result.svvReports[item].type = 'Batch 5: Dots, Standard, Frame';
                break;
              case 'DFS':
                result.svvReports[item].type = 'Batch 6: Dots, Frame, Standard';
                break;
              case 'DEM':
                result.svvReports[item].type = 'Demo Trial';
                break;
            }
          }
          console.log(result);
          this.vorDataSource.data = result.reports;
          this.svvDataSource.data = result.svvReports;
        });
    }
  }

  tabChanged(event: any) {
    if (this.vestibularService.activeTab != event.index) {
      this.vestibularService.activeTab = event.index;
    }
  }
  protected readonly formatDate = formatDate;
  protected readonly Therapies = Therapies;
}
