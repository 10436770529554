import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule, DatePipe, NgIf } from '@angular/common';
import {
  CohortDescription,
  PatientDescription,
  PatientReport,
} from '../../patient';
import {
  CohortDashboard,
  DermatologyCohortDashboardService,
  ReportCount,
  ReportTime,
} from './dermatology-cohort-dashboard.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import {
  DermatologyCohortDashboardItem,
  DermatologyCohortDatasource,
} from './dermatology-cohort-datasource';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import * as moment from 'moment';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-dermatology-cohort-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    NgIf,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DatePipe,
    RouterLink,
    MatButtonModule,
  ],
  templateUrl: './dermatology-cohort-dashboard.component.html',
  styleUrls: ['./dermatology-cohort-dashboard.component.scss'],
})
export class DermatologyCohortDashboardComponent implements AfterViewInit {
  @Input('cohort')
  cohort!: CohortDescription;

  displayedColumns = ['name', 'count', 'date'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<DermatologyCohortDashboardItem>;
  dataSource = new DermatologyCohortDatasource();
  guidList: String[] = [];

  guidArray: string[] = [];

  constructor(private dermService: DermatologyCohortDashboardService) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.table.dataSource = this.dataSource;

    this.dermService.getDashboard(this.cohort).subscribe((dashboard) => {
      this.loadData(dashboard);
      console.log(dashboard);
      for (let dermatologyPatient of dashboard.reports) {
        console.log(dermatologyPatient.patientGuid);
        this.guidList.push(dermatologyPatient.patientGuid);
      }
      // console.log(this.guidList);
    });
  }

  private loadData(dashboard: CohortDashboard) {
    let data = dashboard.reports.map(
      (report: ReportCount, index: number, array: ReportCount[]) => {
        function findPatient(p: PatientDescription) {
          return p.uuid == report.patientGuid;
        }
        function findDate(r: ReportTime) {
          return r.patientGuid == report.patientGuid;
        }

        let patient = dashboard.selectedPatients.patients.find(findPatient);

        return {
          name: patient?.patientId ?? patient?.name ?? patient?.uuid,
          guid: report.patientGuid,
          date: dashboard.mostRecentReport.find(findDate)?.date,
          count: report.count,
          study: patient?.studyId,
          cohort: patient?.cohortId,
        };
      }
    );
    this.dataSource.data = data;
    this.table.dataSource = this.dataSource;
  }

  csvDownload() {
    console.log('download csv');
    console.log(this.dataSource.data);
    for (let item of this.dataSource.data) {
      if (item.guid) {
        this.guidArray.push(item.guid);
      }
    }
    console.log(this.guidArray);
  }
}
