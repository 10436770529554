import { Component, Input, OnInit } from '@angular/core';
import { PatientService } from '../patient.service';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgFor, NgIf } from '@angular/common';
import { PatientDemographics } from '../patient';
import {
  MindcapPatientStatus,
  MindcapPhaseService,
} from '../services/mindcap-phase.service';
import { Router, RouterLink } from '@angular/router';
import { ScheduleReminderComponent } from '../Schedule-Reminder/Schedule-Reminder.component';
import { TestListComponent } from '../test-list/test-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PatientCalendarComponent } from '../patient-calendar/patient-calendar.component';
import { SelectionButtonInfo } from '../selection.service';
import { KeycloakService } from 'keycloak-angular';
import { MindcapEventGraphComponent } from '../Graphs/mindcap-event-graph/mindcap-event=graph.component';
import { SpiderChartComponent } from '../Graphs/spider-chart/spider-chart.component';
import { MindcapService } from '../mindcap.service';
import {MindcapGraphsComponent} from "../Graphs/mindcap-graphs/mindcap-graphs.component";

@Component({
  selector: 'app-patient-mindcap-home',
  templateUrl: './patient-mindcap-home.component.html',
  styleUrls: ['./patient-mindcap-home.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    RouterLink,
    ScheduleReminderComponent,
    MatTabsModule,
    PatientCalendarComponent,
    NgIf,
    NgFor,
    TestListComponent,
    MindcapEventGraphComponent,
    SpiderChartComponent,
    MindcapGraphsComponent
  ],
})
export class PatientMindcapHomeComponent implements OnInit {
  @Input('patientId')
  patientId: string = '';

  @Input('currentPatient')
  selectedPatient?: PatientDemographics;

  @Input('selectedPatients')
  selectedPatients?: SelectionButtonInfo[];

  status!: MindcapPatientStatus;

  phases: string[] = [
    'Intro',
    'Practice Numbers & Symbols',
    'Practice Novascan & Trails',
    'Normal',
    'Testing',
    'Done',
  ];

  constructor(
    private mindcapPhaseService: MindcapPhaseService,
    private mindcapService: MindcapService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.mindcapPhaseService
      .getPhaseInfo(this.patientId)
      .subscribe((status: MindcapPatientStatus) => (this.status = status));
  }

  protected readonly JSON = JSON;

  changePhase(phase: number) {
    this.mindcapPhaseService.changePhase(this.patientId, phase).subscribe();
  }

  patientSelected(selected: SelectionButtonInfo) {
    this.router.navigate(['/home/patient'], {
      queryParams: {
        id: selected.guid,
      },
    });
  }

  triggerCalculation() {
    this.mindcapService.updateMetrics(this.patientId).subscribe((x) => {
      console.log(`Updating metrics for ${this.patientId}, Got ${x}`);
    });
  }
}
