import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ClinicianDescription } from './patient';
import { environment } from 'src/environments/environment';
import { NetworkErrorService } from './network-error.service';

@Injectable({
  providedIn: 'root',
})
export class ClinicianService {
  private permissionsUrl = environment.permissionsUrl;

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {}

  // /** GET patients from the server */
  getClinicianProfile(uuid: string): Observable<ClinicianDescription> {
    let params = new HttpParams();
    params = params.append('uuid', uuid);
    var x = this.http
      .get<ClinicianDescription>(this.permissionsUrl + '/account/show', {
        params,
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError<ClinicianDescription>(
            'getClinicianProfile',
            undefined,
          ),
        ),
      );
    return x;
  }

  getAllClinicians(
    exclude: ClinicianDescription[],
  ): Observable<ClinicianDescription[]> {
    let params = new HttpParams();
    var x = this.http
      .get<ClinicianDescription[]>(this.permissionsUrl + '/account/index', {
        params,
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError<ClinicianDescription[]>(
            'getAllClinicians',
            undefined,
          ),
        ),
        map((arr) =>
          arr.filter((clin) => {
            let inc = !exclude.find((x) => x.uuid === clin.uuid);
            return inc;
          }),
        ),
        map((results) =>
          results.sort((a, b) => {
            let A = a.firstName ? a.firstName : `@${a.username}`;
            let B = b.firstName ? b.firstName : `@${b.username}`;
            if (A == B) return 0;
            return A < B ? -1 : 1;
          }),
        ),
      );
    return x;
  }

  AddClinicianToCohort(username: string, cohortUuid: string) {
    let params = new HttpParams();
    params = params.append('cohortUuid', cohortUuid);
    params = params.append('username', username);

    var x = this.http
      .get(this.permissionsUrl + '/cohort/GrantAccess', { params })
      .pipe(
        catchError(
          this.networkErrorService.handleError(
            'AddClinicianToCohort',
            undefined,
          ),
        ),
      );
    return x;
  }

  removeClinicianFromCohort(cohortId: string, username: string) {
    let params = new HttpParams();
    params = params.append('cohortId', cohortId);
    params = params.append('username', username);

    return this.http
      .get(environment.permissionsUrl + '/cohort/RemoveAccess', { params })
      .pipe(
        tap((_) => this.networkErrorService.log('removeClinicianFromCohort')),
        catchError(
          this.networkErrorService.handleError(
            'removeClinicianFromCohort',
            undefined,
          ),
        ),
      );
  }
}
