import { Component, Inject } from '@angular/core';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { PatientService } from '../../patient.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CohortService } from '../../cohort.service';
import { ClinicianDescription, CohortDescription } from '../../patient';
import { async, BehaviorSubject, Observable } from 'rxjs';
import { FilterService } from '../../filter.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClinicianSelectComponent } from '../clinician-select/clinician-select.component';
import { MatIconModule } from '@angular/material/icon';
import { ClinicianService } from '../../clinician.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { BreadcrumbService } from 'xng-breadcrumb';

export interface DialogData {
  cohort: Observable<CohortDescription>;
}

@Component({
  selector: 'app-cohort-admin',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatAutocompleteModule,
    ClinicianSelectComponent,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
  templateUrl: './cohort-admin.component.html',
  styleUrls: ['./cohort-admin.component.scss'],
})
export class CohortAdminComponent {
  cohortSource: BehaviorSubject<CohortDescription | null> =
    new BehaviorSubject<CohortDescription | null>(null);
  cohort: Observable<CohortDescription | null> =
    this.cohortSource.asObservable();

  protected cohortId: string = '';

  constructor(
    private route: ActivatedRoute,
    private cohortService: CohortService,
    private clinicianService: ClinicianService,
    public filterService: FilterService,
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    this.cohortId = this.route.snapshot.paramMap.get('id') || '';
    let cohortObs = this.cohortService.getCohortInfo(this.cohortId);

    cohortObs.subscribe((cohortDescription) => {
      this.cohortSource.next(cohortDescription);
      this.breadcrumbService.set(
        '@cohortName',
        'Cohort: ' + cohortDescription.treatment
      );
    });
    console.log(cohortObs);
  }

  addClinician(event: ClinicianDescription) {
    console.log('Completed adding ' + event.username + ' to the cohort');
    this.cohortService.getCohortInfo(this.cohortId).subscribe((x) => {
      this.cohortSource.next(x);
    });
  }

  removeClinician(username: string) {
    this.clinicianService
      .removeClinicianFromCohort(this.cohortId, username)
      .subscribe();
  }

  openRenameDialog(): void {
    const renameDialogRef = this.dialog.open(CohortRenameDialog, {
      data: { cohort: this.cohort },
    });

    renameDialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');

      this.cohortService.renameCohort(result.cohort, result.name).subscribe({
        next: (res) => this.cohortSource.next(res),
        error: (e) => {},
      }); // end subscibe to renameCohort
    }); // end subscribe to dialog afterClosed
  }

  onRename(result: any) {}
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'rename-dialog.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    AsyncPipe,
    NgIf,
  ],
})
export class CohortRenameDialog {
  constructor(
    public dialogRef: MatDialogRef<CohortRenameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
