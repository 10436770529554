import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NetworkErrorService {
  constructor(private _snackBar: MatSnackBar) {}

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(
    operation: string = 'operation',
    result?: T,
    ignoreStatus: Number = 0,
  ) {
    return (error: any): Observable<T> => {
      if (error.status != ignoreStatus) {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);

        this._snackBar.open(
          `${operation} failed, see log for details`,
          'Dismiss',
          {
            duration: 3000,
          },
        );
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  public log(message: string) {
    console.log(message);
  }
}
