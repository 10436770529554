import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { BodyMapModule } from '@nxthealth/bodymap';

import { BodyPart } from '@nxthealth/bodymap';

import { ReportService } from '../services/report.service';

import { MatButtonModule } from '@angular/material/button';

import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { find } from 'rxjs';

interface BodypartPicture {
  bodyPart: string;
  displayName: string;
  pictures: string[];
  // aiDiagnosis: string;
  // drDriagnosis: string;
  // notes: string;
}

interface ReportTemplate {
  frontSelectedBodyParts: BodyPart[];
  backSelectedBodyParts: BodyPart[];
  onset: string;
  symptoms: string[];
  medicalHistory: string[];
  allergies: string[];
  medications: string[];
  bodypartPictures: BodypartPicture[];
  reportSummary: string;
  physioSymptoms: string[];
}

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatListModule,
    BodyMapModule,
    CommonModule,
    NgFor,
    NgIf,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
  ],
})
export class ReportPageComponent implements OnInit {
  report?: any | undefined = undefined;

  patientId?: string;
  reportId?: number;

  aiDiagnosis = '';
  drDiagnosis = '';
  notes = '';

  picLabels: any[] = [];

  avgLabels: any[] = [];

  finalAvgLabels: any[] = [];
  topAvgLabels: any[] = [];

  template: ReportTemplate = {
    frontSelectedBodyParts: [],
    backSelectedBodyParts: [],
    onset: '',
    symptoms: [],
    medicalHistory: [],
    allergies: [],
    medications: [],
    bodypartPictures: [],
    reportSummary: '',
    physioSymptoms: [],
  };

  constructor(
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe({
      next: (val: any) => {
        if (val['patientGuid'] && val['reportId']) {
          this.patientId = val['patientGuid'];
          this.reportId = parseInt(val['reportId']);
          if (this.patientId && this.reportId) {
            this.reportService
              .getReport(this.patientId, this.reportId)
              .then((report: any) => {
                console.log(report);
                let bodyPartsMap: Map<BodyPart, string[]> = new Map();
                for (const pic of report.bodyPartPictures) {
                  const urls = bodyPartsMap.get(pic.bodyPartLocation);
                  console.log(pic.notes);
                  if (pic.drDiagnosis) {
                    this.drDiagnosis = pic.drDiagnosis.name;
                  } else {
                    this.drDiagnosis = 'None';
                  }
                  if (pic.aiDiagnosis) {
                    this.aiDiagnosis = pic.aiDiagnosis.name;
                  } else {
                    this.aiDiagnosis = 'None';
                  }

                  this.notes = pic.notes;
                  if (urls) {
                    bodyPartsMap.set(pic.bodyPartLocation, [...urls, pic.url]);
                    console.log(
                      'IF BLOCK: ' + pic.bodyPartLocation + ' PIC: ' + pic.url
                    );
                  } else {
                    bodyPartsMap.set(pic.bodyPartLocation, [pic.url]);
                    console.log(
                      'ELSE BLOCK: ' + pic.bodyPartLocation + ' PIC: ' + pic.url
                    );
                  }
                  this.picLabels.push({
                    topLabels: [
                      pic.diffDiagScores.labels[0],
                      pic.diffDiagScores.labels[1],
                      pic.diffDiagScores.labels[2],
                    ],
                    topScores: [
                      pic.diffDiagScores.diffScores[0],
                      pic.diffDiagScores.diffScores[1],
                      pic.diffDiagScores.diffScores[2],
                    ],
                  });

                  for (let i = 0; i < pic.diffDiagScores.labels.length; i++) {
                    this.avgLabels.push({
                      label: pic.diffDiagScores.labels[i],
                      score: pic.diffDiagScores.diffScores[i],
                    });
                  }
                  console.log(this.avgLabels);
                }

                bodyPartsMap.forEach((urls: string[], bodyPart: BodyPart) => {
                  if (bodyPart.startsWith('front')) {
                    this.template.frontSelectedBodyParts.push(bodyPart);
                  } else {
                    this.template.backSelectedBodyParts.push(bodyPart);
                  }

                  this.template.bodypartPictures.push({
                    bodyPart: bodyPart,
                    displayName: this.humanReadableBodyPartName(bodyPart),
                    pictures: urls,
                  });
                });

                if (report.onsets.length != 0) {
                  this.template.onset = report.onsets[0].name;
                }

                if (report.symptoms.length != 0) {
                  this.template.symptoms = report.symptoms.map(
                    (symptom: any) => symptom.name
                  );
                }

                this.template.reportSummary = report.summary;

                if (report.physioSymptoms.length != 0) {
                  this.template.physioSymptoms = report.physioSymptoms;
                }

                this.report = report;
                this.calculateTotalAvg();
              })
              .catch((e) => console.warn(e));
          }
        } else {
          console.error('Missing Patient UUID and/or Report ID!');
          throw new Error('Missing Patient UUID and/or Report ID!');
        }
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {},
    });
  }

  private humanReadableBodyPartName(bodyPart: string) {
    const name = bodyPart.split('_');
    name.forEach((e, i, a) => (a[i] = e[0].toUpperCase() + e.slice(1)));
    return name.join(' ');
  }

  openDialog(pic: any): void {
    console.log(pic);
    const dialogRef = this.dialog.open(ReportPageComponentDialog, {
      data: {
        picUrl: pic,
        drDiagnosis: this.drDiagnosis,
        aiDiagnosis: this.aiDiagnosis,
        notes: this.notes,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  calculateTotalAvg() {
    const mpoxResults = this.avgLabels.filter(function (entry: any) {
      return entry.label === 'Mpox';
    });
    let mpoxTotal = 0;
    for (let entry in mpoxResults) {
      mpoxTotal += mpoxResults[entry].score;
    }
    this.finalAvgLabels.push({
      label: 'Mpox',
      avgScore: mpoxTotal / mpoxResults.length,
    });

    const psoriasisResults = this.avgLabels.filter(function (entry: any) {
      return entry.label === 'Psoriasis';
    });
    let psoriasisTotal = 0;
    for (let entry in psoriasisResults) {
      psoriasisTotal += psoriasisResults[entry].score;
    }
    this.finalAvgLabels.push({
      label: 'Psoriasis',
      avgScore: psoriasisTotal / psoriasisResults.length,
    });

    const acneResults = this.avgLabels.filter(function (entry: any) {
      return entry.label === 'Acne';
    });
    let acneTotal = 0;
    for (let entry in acneResults) {
      acneTotal += acneResults[entry].score;
    }
    this.finalAvgLabels.push({
      label: 'Acne',
      avgScore: acneTotal / acneResults.length,
    });

    const seborrheicKeratosisResults = this.avgLabels.filter(function (
      entry: any
    ) {
      return entry.label === 'Seborrheic Keratosis';
    });
    let seborrheicKeratosisResultsTotal = 0;
    for (let entry in seborrheicKeratosisResults) {
      seborrheicKeratosisResultsTotal +=
        seborrheicKeratosisResults[entry].score;
    }
    this.finalAvgLabels.push({
      label: 'Seborrheic Keratosis',
      avgScore:
        seborrheicKeratosisResultsTotal / seborrheicKeratosisResults.length,
    });

    const tineaResults = this.avgLabels.filter(function (entry: any) {
      return entry.label === 'Tinea';
    });
    let tineaTotal = 0;
    for (let entry in tineaResults) {
      tineaTotal += tineaResults[entry].score;
    }
    this.finalAvgLabels.push({
      label: 'Tinea',
      avgScore: tineaTotal / tineaResults.length,
    });

    const eczemaResults = this.avgLabels.filter(function (entry: any) {
      return entry.label === 'Eczema';
    });
    let eczemaTotal = 0;
    for (let entry in eczemaResults) {
      eczemaTotal += eczemaResults[entry].score;
    }
    this.finalAvgLabels.push({
      label: 'Eczema',
      avgScore: eczemaTotal / eczemaResults.length,
    });

    const actinicKeratosisResults = this.avgLabels.filter(function (
      entry: any
    ) {
      return entry.label === 'Actinic Keratosis';
    });
    let actinicKeratosisResultsTotal = 0;
    for (let entry in actinicKeratosisResults) {
      actinicKeratosisResultsTotal += actinicKeratosisResults[entry].score;
    }
    this.finalAvgLabels.push({
      label: 'Actinic Keratosis',
      avgScore: actinicKeratosisResultsTotal / actinicKeratosisResults.length,
    });
    console.log(this.finalAvgLabels);

    this.finalAvgLabels.sort(function (a, b) {
      return b.avgScore - a.avgScore;
    });

    this.topAvgLabels = this.finalAvgLabels.slice(0, 3);

    console.log(this.topAvgLabels);
  }
}

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    // MatFormFieldModule,
    // MatInputModule,
    // FormsModule,
    MatButtonModule,
  ],
})
export class ReportPageComponentDialog {
  constructor(
    public dialogRef: MatDialogRef<ReportPageComponentDialog>, // @Inject(MAT_DIALOG_DATA) public data: DialogData
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
