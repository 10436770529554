// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-rect {
  width: 40px;
  height: 20px;
  display: inline-block;
}
.color-rect.green {
  background-color: #00ff00;
}
.color-rect.yellow {
  background-color: #ffff00;
}
.color-rect.red {
  background-color: #ff0000;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/Cognitive/cognitive-dashboard/cognitive-dashboard.component.scss"],"names":[],"mappings":"AAMA;EACI,WAAA;EACA,YAAA;EACA,qBAAA;AALJ;AAOI;EACI,yBAAA;AALR;AAQI;EACI,yBAAA;AANR;AASI;EACI,yBAAA;AAPR;;AAWA;EACI,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,aAAA;AARJ","sourcesContent":["$colors: (\n    green: #00ff00,\n    yellow: #ffff00,\n    red: #ff0000\n);\n\n.color-rect {\n    width: 40px;\n    height: 20px;\n    display: inline-block;\n\n    &.green {\n        background-color: map-get($colors, green);\n    }\n\n    &.yellow {\n        background-color: map-get($colors, yellow);\n    }\n\n    &.red {\n        background-color: map-get($colors, red);\n    }\n}\n\n.button-row {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
