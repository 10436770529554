import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientListComponent } from './patient-list/patient-list.component';
import { DataSetUploadComponent } from './data-set-upload/data-set-upload.component';
import { PatientAdminComponent } from './admin/patient-admin/patient-admin.component';
import { DataSetListComponent } from './data-set-list/data-set-list.component';
import { CohortAdminComponent } from './admin/cohort-admin/cohort-admin.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { ClinicianAdminComponent } from './admin/clinician-admin/clinician-admin.component';
import { NewPatientComponent } from './admin/new-patient/new-patient.component';
import { NewClinicianComponent } from './admin/new-clinician/new-clinician.component';
import { HomeComponent } from './home/home.component';
import { StudyComponent } from './study/study.component';
import { TrainingRequestListComponent } from './training-request-list/training-request-list.component';
import { NewStudyComponent } from './admin/new-study/new-study.component';
import { StudyAdminComponent } from './admin/study-admin/study-admin.component';
import { NewCohortComponent } from './admin/new-cohort/new-cohort.component';
import { CohortComponent } from './cohort/cohort.component';
import { Role, RoleGuard } from './guards/role.guard';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ReportPageComponent } from './report-page/report-page.component';
import { PatientHomeComponent } from './patient-home/patient-home.component';
import { TestResultComponent } from './test-result/test-result.component';
import { SurveyListComponent } from './survey-list/survey-list.component';
import { SurveyDetailsComponent } from './survey-details/survey-details.component';
import { ClinicianListComponent } from './admin/clinician-list/clinician-list.component';
import { TherapyComponent } from './vestibular/therapy/therapy.component';
import { VorResultsComponent } from './vestibular/vor-results/vor-results.component';
import { SvvResultsComponent } from './vestibular/svv-results/svv-results.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [RoleGuard],
        data: {
          roles: [Role.Clinician],
          breadcrumb: {skip: true},
        }
      },
      {
        path: 'home',
        data: {
          breadcrumb: {
            info: 'home',
          },
        },
        children: [
          {
            path: '',
            component: HomeComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.Clinician],
              // breadcrumb: {
              //   info: 'home',
              // },
            },
          },
          {
            path: 'patient',
            data: {
              breadcrumb: {
                label: 'Patient List',
              },
            },
            children: [
              {
                path: '',
                component: PatientListComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                },
              },
              {
                path: ':patientGuid',
                data: {
                  breadcrumb: {
                    label: 'Patient',
                    alias: 'patientListName',
                  },
                },
                children: [
                  {
                    path: '',
                    component: PatientHomeComponent,
                    canActivate: [RoleGuard],
                    data: {
                      roles: [Role.Clinician],
                    },
                  },
                  {
                    path: ':reportId',
                    component: ReportPageComponent,
                    canActivate: [RoleGuard],
                    data: {
                      roles: [Role.Clinician],
                      breadcrumb: {
                        label: 'Patient Report',
                      },
                    },
                  },

                  {
                    path: 'surveyList/:guid',
                    data: {
                      breadcrumb: {
                        label: 'Survey List',
                      },
                    },
                    children: [
                      {
                        path: '',
                        component: SurveyListComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                        },
                      },
                      {
                        path: 'surveyDetails/:id',
                        component: SurveyDetailsComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                          breadcrumb: {
                            label: 'Survey',
                            alias: 'surveyName',
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'datasetlist',
            data: {
              breadcrumb: {
                label: 'Dataset List',
              },
            },
            children: [
              {
                path: '',
                component: DataSetListComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                },
              },
              {
                path: 'dataset',
                component: DataSetUploadComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  breadcrumb: {
                    label: 'Dataset Upload',
                  },
                },
              },
              {
                path: 'trainingrequestlist',
                component: TrainingRequestListComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  breadcrumb: {
                    label: 'Training Request List',
                  },
                },
              },
            ],
          },
          {
            path: 'admin',
            data: {
              breadcrumb: {
                label: 'Admin',
              },
            },
            children: [
              {
                path: '',
                component: AdminHomeComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                },
              },
              {
                path: 'study/:id',
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                },
                children: [
                  {
                    path: '',
                    data: {
                      roles: [Role.Clinician],
                      breadcrumb: {
                        label: 'Study',
                        alias: 'studyName',
                      },
                    },
                    children: [
                      {
                        path: '',
                        component: StudyAdminComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                        },
                      },
                      {
                        path: 'createFavorite/:isFavorite',
                        component: TherapyComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                          breadcrumb: {
                            label: 'Create Favorite',
                            alias: 'createFavorite',
                          },
                        },
                      },
                    ],
                  },
                  {
                    path: 'cohort/:id',
                    canActivate: [RoleGuard],
                    data: {
                      roles: [Role.Clinician],
                    },
                    children: [
                      {
                        path: '',
                        component: CohortAdminComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                          breadcrumb: {
                            label: 'Cohort',
                            alias: 'cohortName',
                          },
                        },
                      },
                      {
                        path: 'patient/:patientGuid',
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                        },
                        children: [
                          {
                            path: '',
                            component: PatientAdminComponent,
                            canActivate: [RoleGuard],
                            data: {
                              roles: [Role.Clinician],
                              breadcrumb: {
                                label: 'Patient',
                                alias: 'patientName',
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'clinician',
                component: ClinicianListComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  breadcrumb: {
                    label: 'Clinician List',
                  },
                },
              },
              {
                path: 'clinician/:id',
                component: ClinicianAdminComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  breadcrumb: {
                    label: 'Clinician',
                    alias: 'clinicianName',
                  },
                },
              },
              {
                path: 'newPatient',
                component: NewPatientComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  breadcrumb: {
                    label: 'New Patient',
                  },
                },
              },
              {
                path: 'newClinician',
                component: NewClinicianComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  breadcrumb: {
                    label: 'New Clinician',
                  },
                },
              },
              {
                path: 'newStudy',
                component: NewStudyComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  breadcrumb: {
                    label: 'New Study',
                  },
                },
              },
              {
                path: 'newCohort',
                component: NewCohortComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  breadcrumb: {
                    label: 'New Cohort',
                  },
                },
              },
            ],
          },
          {
            path: 'test/:id',
            component: TestResultComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [Role.Clinician],
              breadcrumb: {
                label: 'Test',
              },
            },
          },

          // {
          //   path: 'vestibular',
          //   data: {
          //     breadcrumb: {
          //       label: 'Vestibular',
          //     },
          //   },
          //   children: [
          //     {
          //       path: 'therapy/create/:patientGuid',
          //       component: TherapyComponent,
          //       canActivate: [RoleGuard],
          //       data: {
          //         roles: [Role.Clinician],
          //         breadcrumb: {
          //           label: 'Create Therapy',
          //         },
          //       },
          //     },
          //     {
          //       path: 'therapy/:patientGuid/:id/:activity',
          //       component: TherapyComponent,
          //       canActivate: [RoleGuard],
          //       data: {
          //         roles: [Role.Clinician],
          //         breadcrumb: {
          //           label: 'Edit Therapy',
          //         },
          //       },
          //     },
          //   ],
          // },
          {
            path: 'study/:id',
            canActivate: [RoleGuard],
            data: {
              roles: [Role.Clinician],
              breadcrumb: {
                label: 'Study',
                alias: 'studyName',
              },
            },
            children: [
              {
                path: '',
                component: StudyComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Role.Clinician],
                  // breadcrumb: {
                  //   alias: 'studyName',
                  // },
                },
              },
              {
                path: 'cohort/:id',
                canActivate: [RoleGuard],
                data: {
                  breadcrumb: {
                    roles: [Role.Clinician],
                    label: 'Cohort',
                    alias: 'cohortName',
                  },
                },
                children: [
                  {
                    path: '',
                    component: CohortComponent,
                    canActivate: [RoleGuard],
                    data: {
                      roles: [Role.Clinician],
                    },
                  },
                  {
                    path: 'patient/:patientGuid',
                    canActivate: [RoleGuard],
                    data: {
                      roles: [Role.Clinician],
                      breadcrumb: {
                        label: 'Patient',
                        alias: 'patientListName',
                      },
                    },
                    children: [
                      {
                        path: '',
                        component: PatientHomeComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                        },
                      },
                      {
                        path: ':reportId',
                        component: ReportPageComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                          breadcrumb: {
                            label: 'Patient Report',
                          },
                        },
                      },

                      {
                        path: 'therapy/create',
                        component: TherapyComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                          breadcrumb: {
                            label: 'Create Therapy',
                          },
                        },
                      },
                      {
                        path: 'therapy/:patientGuid/:therapyId/:activity',
                        component: TherapyComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                          breadcrumb: {
                            label: 'Edit Therapy',
                          },
                        },
                      },

                      {
                        path: 'vorresults/:reportId',
                        component: VorResultsComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                          breadcrumb: {label: 'VOR Result'},
                        },
                      },
                      {
                        path: 'svvresults/:reportId',
                        component: SvvResultsComponent,
                        canActivate: [RoleGuard],
                        data: {
                          roles: [Role.Clinician],
                          breadcrumb: {label: 'Svv Test Result'},
                        },
                      },

                      {
                        path: 'surveyList/:guid',
                        data: {
                          breadcrumb: {
                            label: 'Survey List',
                          },
                        },
                        children: [
                          {
                            path: '',
                            component: SurveyListComponent,
                            canActivate: [RoleGuard],
                            data: {
                              roles: [Role.Clinician],
                            },
                          },
                          {
                            path: 'surveyDetails/:id',
                            component: SurveyDetailsComponent,
                            canActivate: [RoleGuard],
                            data: {
                              roles: [Role.Clinician],
                              breadcrumb: {
                                label: 'Survey',
                                alias: 'surveyName',
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'error',
            component: ErrorPageComponent,
          },
        ],
      },
      {
          path: 'error',
          component: ErrorPageComponent,
        },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
