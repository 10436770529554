// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile-format {
  position: absolute;
  left: 5px;
}

.error-div {
  margin-top: 50px;
  text-align: center;
}

.icon-text {
  display: flex;
  align-items: center;
}

.error-circle {
  margin-top: 12px;
  border-radius: 50%;
  width: 215px;
  height: 215px;
  border: 2px solid black;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/vestibular/vor-results/vor-results.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;AACJ","sourcesContent":[".tile-format {\n    position: absolute;\n    left: 5px;\n}\n\n.error-div {\n    margin-top: 50px;\n    text-align: center;\n}\n\n.icon-text {\n    display: flex;\n    align-items: center;\n}\n\n.error-circle {\n    margin-top: 12px;\n    border-radius: 50%;\n    width: 215px;\n    height: 215px;\n    border: 2px solid black;\n    justify-content: center\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
