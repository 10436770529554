import { Component } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { CohortDescription, PatientDescription } from '../patient';
import { CohortService } from '../cohort.service';
import { PatientService } from '../patient.service';
import { CognitiveDashboardComponent } from '../Cognitive/cognitive-dashboard/cognitive-dashboard.component';
import { DermatologyCohortDashboardComponent } from '../derm/dermatology-cohort-dashboard/dermatology-cohort-dashboard.component';
import { VestibularCohortDashboardComponent } from '../vestibular/vestibular-cohort-dashboard/vestibular-cohort-dashboard.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatExpansionModule} from "@angular/material/expansion";
import {Observable} from "rxjs";
import {FeatureServiceService} from "../feature-service.service";
import {StudyService} from "../study.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-cohort',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    NgFor,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatButtonToggleModule,
    RouterLink,
    MatTooltipModule,
    MatCardModule,
    CognitiveDashboardComponent,
    DermatologyCohortDashboardComponent,
    VestibularCohortDashboardComponent,
    MatSidenavModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './cohort.component.html',
  styleUrls: ['./cohort.component.scss'],
})
export class CohortComponent {
  cohortId: string = '';

  cohort!: CohortDescription;
  cohortFilteredPatients: PatientDescription[] = [];
  sidenavOpen = false;

    activeFeatureObs : Observable<String> = this.featureService.activeFeatureObs
    activeFeature : String= ""

  constructor(
    private route: ActivatedRoute,
    public cohortService: CohortService,
    public patientService: PatientService,
    private breadcrumbService: BreadcrumbService,
    private featureService: FeatureServiceService,
    protected studyService: StudyService
  ) {
    // @ts-ignore
    this.cohortId = this.route.snapshot.paramMap.get('id') || '';

    this.cohortService.getCohortInfo(this.cohortId).subscribe((x) => {
      this.cohort = x;
      this.cohortFilteredPatients = x.patients;
      this.breadcrumbService.set('@cohortName', 'Cohort: ' + x.treatment);
    });
  }

  ngOnInit() {
    console.log(this.cohort!);
    for (let item in this.cohort) {
      console.log(item);
    }
    console.log(this.cohort);
    // this.breadcrumbService.set(
    //   '@cohortName',
    //   'Cohort: ' + this.cohort['treatment']
    // );
    this.activeFeatureObs.subscribe( x => { this.activeFeature = x } )

  }

    featureChanged( feature : String )
  {
    if ( this.featureService.activeFeature != feature ) {
      this.featureService.activeFeature = feature
    }
  }


  toggleSidenavButton() {
    this.sidenavOpen = !this.sidenavOpen;
  }

  filterPatients(event: Event) {
    const filterText = (event.target as HTMLInputElement).value;
    var newCohortFilteredPatients: PatientDescription[] = [];
    this.cohort.patients.forEach((patient) => {
      const patientDisplayName =
        this.patientService.getDisplayNameDesc(patient);
      if (patientDisplayName.toLowerCase().includes(filterText.toLowerCase())) {
        newCohortFilteredPatients.push(patient);
      }
    });
    this.cohortFilteredPatients = newCohortFilteredPatients;
  }
}
