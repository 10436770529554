// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
}

.clinician {
  max-width: 1000px;
  margin: auto;
  margin-top: 3%;
  margin-left: 3%;
}

.card-header {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/clinician-admin/clinician-admin.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":[":host {\n  flex-grow: 1;\n}\n\n.clinician {\n  max-width: 1000px;\n  margin: auto;\n  margin-top: 3%;\n  margin-left: 3%;\n}\n\n.card-header {\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
