import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgIf, NgFor, formatDate } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { VestibularService } from '../vestibular.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexStroke,
  ApexGrid,
  ApexYAxis,
  ApexXAxis,
  ApexPlotOptions,
  ApexTooltip,
  NgApexchartsModule,
  ApexAnnotations,
  ApexMarkers,
} from 'ng-apexcharts';
import { MatCardModule } from '@angular/material/card';
import { any } from 'video.js/dist/types/utils/events';
import { PatientService } from 'src/app/patient.service';
import * as saveAs from 'file-saver';

export type BarChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  annotations: ApexAnnotations;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: any[];
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  markers: ApexMarkers;
};

export interface DataSetTable {
  patientName: string;
  patientId: string;
  diagnosis: string;
  exerciseDate: string;
  visitNumber: string;
}

export interface svvSubjectiveData {
  startingRot: any;
  enteredRot: any;
  headsetVert: any;
}

export interface svvTherapyReport {
  dateCreated: Date;
  exerciseStartTime: Date;
  exerciseEndTime: Date;
  type: string;
  therapy: {
    comment: string;
    description: string;
    effectiveStart: string;
    effectiveEnd: string;
    dateCreated: string;
    sessionNumber: number;
    numTrials: number;
    rotationSpeed: number;
    usingFrame: boolean;
    frameDegrees: number;
  };
  trials: [
    {
      dotDirection: number;
      frameRot: number;
      timeStart: string;
      timeEnd: string;
      startingRot: number;
      enteredRot: number;
      headsetVert: number;
      isDots: boolean;
      isFrame: boolean;
      durationInSeconds: number;
    },
    {
      dotDirection: number;
      frameRot: number;
      timeStart: string;
      timeEnd: string;
      startingRot: number;
      enteredRot: number;
      headsetVert: number;
      isDots: boolean;
      isFrame: boolean;
    },
    {
      dotDirection: number;
      frameRot: number;
      timeStart: string;
      timeEnd: string;
      startingRot: number;
      enteredRot: number;
      headsetVert: number;
      isDots: boolean;
      isFrame: boolean;
    }
  ];
}

export interface SvvTrialData {
  dotDirection: number;
  enteredRot: number;
  frameRot: number;
  headsetVert: number;
  isDots: boolean;
  isFrame: boolean;
  startingRot: number;
  timeEnd: string;
  timeStart: string;
}

export interface SvvTrialDataSource {
  mean: number;
  standardDev: number;
  min: number;
  max: number;
  pic: string;
}

const THRESHOLD_VALUE = 3;

@Component({
  selector: 'app-svv-results',
  standalone: true,
  templateUrl: './svv-results.component.html',
  styleUrls: ['./svv-results.component.scss'],
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgIf,
    NgFor,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule,
    MatCheckboxModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatGridListModule,
    RouterLink,
    NgApexchartsModule,
    MatCardModule,
  ],
})
export class SvvResultsComponent implements OnInit {
  @ViewChild('barChart')
  barChart!: ChartComponent;

  dataSource = new MatTableDataSource<DataSetTable>();
  selection = new SelectionModel<DataSetTable>(true, []);
  loading = true;
  displayedColumns: string[] = ['name', 'id', 'diagnosis', 'date', 'visit'];

  public svvResultsRangeBarStandard?: BarChartOptions;
  public svvResultsRangeBarFrame?: BarChartOptions;
  public svvResultsRangeBarStaticDots?: BarChartOptions;
  public svvResultsRangeBarDynamicDots?: BarChartOptions;
  svvSubjectiveDataSource = new MatTableDataSource<svvSubjectiveData>();
  svvSubjectiveDisplayedColumns: string[] = [
    'startingRot',
    'enteredRot',
    'headsetVert',
  ];

  svvTrialDataSourceStandard = new MatTableDataSource<SvvTrialDataSource>();
  svvTrialDataSourceFrame = new MatTableDataSource<SvvTrialDataSource>();
  svvTrialDataSourceStaticDots = new MatTableDataSource<SvvTrialDataSource>();
  svvTrialDataSourceDynamicDots = new MatTableDataSource<SvvTrialDataSource>();
  svvTrialDisplayedColumns: string[] = ['orientation', 'meanStdev', 'range'];

  patientId = '';
  //TODO: use interface mapping
  dateCreated: Date = new Date();
  exerciseStartTime: Date = new Date();
  exerciseEndTime: Date = new Date();
  comment = '';
  description = '';
  effectiveStart = '';
  effectiveEnd = '';
  sessionNumber = 0;
  numTrials = 0;
  rotationSpeed = 0;
  usingFrame = true;
  frameDegrees = 0;
  timeStart = '';
  timeEnd = '';
  startingRot = 0;
  enteredRot = 0;
  headsetVert = 0;

  reportId = 0;
  protected mean: number = 0;
  protected stdDev: number = 0;
  newMax = 0;
  newMin = 0;

  displayName: string = '';
  patientName: string = '';

  positiveStandardArray: number[] = [];
  negativeStandardArray: number[] = [];
  positiveFrameArrayPositiveStarting: number[] = [];
  positiveFrameArrayNegativeStarting: number[] = [];
  negativeFrameArrayPositiveStarting: number[] = [];
  negativeFrameArrayNegativeStarting: number[] = [];
  positiveStaticDots: number[] = [];
  negativeStaticDots: number[] = [];
  positiveDotsArrayPositiveStarting: number[] = [];
  positiveDotsArrayNegativeStarting: number[] = [];
  negativeDotsArayPositiveStarting: number[] = [];
  negativeDotsArrayNegativeStarting: number[] = [];

  standardAvg: number = 0;
  frameAvg: number = 0;
  staticDotsAvg: number = 0;
  dynamicDotsAvg: number = 0;

  constructor(
    public vestibularService: VestibularService,

    private route: ActivatedRoute,
    private patientService: PatientService,
    private cdr: ChangeDetectorRef
  ) {
    this.patientId = this.route.snapshot.paramMap.get('patientGuid') || '';
    this.reportId = +(this.route.snapshot.paramMap.get('reportId') || '-1');

    this.svvResultsRangeBarStandard = {
      title: { text: '' },
      series: [],
      chart: {
        type: 'rangeBar',
        height: 1050,
        toolbar: {
          show: true,
        },
        sparkline: {
          enabled: false,
        },
      },
      annotations: {
        yaxis: [
          {
            y: '5',
            strokeDashArray: 0,
            borderColor: '#111',
            borderWidth: 2,
            offsetY: 36.5,
            opacity: 1,
          },
          {
            y: '7',
            strokeDashArray: 0,
            borderColor: '#111',
            borderWidth: 2,
            offsetY: 36.5,
            opacity: 1,
          },
          {
            y: '11',
            strokeDashArray: 0,
            borderColor: '#111',
            borderWidth: 2,
            offsetY: 36.5,
            opacity: 1,
          },
        ],
        points: [],
      },

      colors: ['#6fc276'],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        formatter: function (val: any) {
          return val.toFixed(1);
        },
      },
      stroke: {},

      grid: {
        show: true,
        row: {
          colors: ['#D3D3D3', '#FFFFFF'],
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          left: -20,
          right: -2,
        },
      },
      yaxis: {
        labels: {
          show: true,
          // formatter: function (value) {
          //   return value + '°';
          // },
        },
      },
      tooltip: {
        custom: ({ dataPointIndex, w }) => {
          let s = w.config.series[0].data[dataPointIndex];
          let goals = w.config.series[0].data[dataPointIndex].goals;
          let std = this.calcStdDev([s.y[0], s.y[1]]);
          return `<div class="apexcharts-tooltip-rangebar"><div>
        <span class="category">Range: </span>
        <span class="value start-value">${parseFloat(s.y[0].toFixed(1))}°</span>
        <span class="separator">to</span>
        <span class="value end-value">${parseFloat(s.y[1].toFixed(1))}°</span>
        <br />
        <span class="category">Mean: </span>
        <span class="value">${parseFloat(goals[0].value.toFixed(1))}</span>
        <span class="separator">±</span>
        <span>${parseFloat(std.toFixed(1))}</span>
        </div></div>`;
        },
      },

      xaxis: {
        title: {
          text: '°',
        },
        labels: {
          show: true,
          formatter: function (value) {
            return value + '°';
          },
        },
      },
      markers: {
        size: [],
      },
    };
  }

  ngOnInit() {
    document.body.scrollTop = 0;
    this.loadTable();
    // this.chartOptions.series = [
    //   {
    //     data: [this.headMotionSlow, this.headMotionFast],
    //   },
    // ];
    this.patientService
      .getPatientProfile(this.patientId)
      .subscribe((patient) => {
        if (patient != null) {
          this.displayName = this.patientService.getDisplayName(patient, true);
          this.patientName = this.patientService.getDisplayName(patient);
        }
      });
  }

  downloadSvv(report: number) {
    this.vestibularService
      .getSvv(report, this.patientName)
      .subscribe((blob: Blob | undefined) => {
        console.log(blob);
        if (blob) {
          saveAs(blob, `SVV_${this.displayName}_${this.dateCreated}.csv`);
        }
      });
  }

  calcStdDev(stdDevArray: number[]) {
    let stdDev =
      stdDevArray.reduce((prev, current) => {
        return prev + (current - this.calcMean(stdDevArray)) ** 2;
      }, 0) /
      (stdDevArray.length - 1);

    let tableStdDev = Math.sqrt(stdDev);
    return tableStdDev;
  }
  calcMean(meanArray: number[]) {
    let mean =
      meanArray.reduce((prev, current) => {
        return prev + current;
      }, 0) / meanArray.length;
    return mean;
  }

  calcMin(x: any) {
    this.newMin = x[0];
    console.log(x);
    for (let num in x) {
      console.log(x[num]);
      if (this.newMin > x[num]) {
        this.newMin = x[num];
        console.log(this.newMin);
      }
    }
    this.newMin = Math.ceil(this.newMin / 10) * 10;
    return this.newMin;
  }

  calcMax(x: any) {
    this.newMax = x[0];
    for (let num in x) {
      if (this.newMax < x[num]) {
        this.newMax = x[num];
      }
    }
    console.log(this.newMax);
    this.newMax = Math.ceil(this.newMax / 10) * 10;
    console.log(this.newMax);

    return this.newMax;
  }

  calcAvg(x: any): number {
    for (let item in x) {
      if (x[item] < 0) {
        x[item] = x[item] + 60;
      }
    }
    let sum = x.reduce((a: number, b: number) => a + b, 0);
    let avg = sum / x.length || 0;
    return avg;
  }

  checkThreshold3(data: number[]) {
    let finalColor = '#6fc276';
    if (this.calcMean(data) < -3 || this.calcMean(data) > 3) {
      finalColor = '#FF0000';
    }
    return finalColor;
  }
  checkThreshold5(data: number[]) {
    let finalColor = '#6fc276';
    if (this.calcMean(data) < -5.5 || this.calcMean(data) > 5.5) {
      finalColor = '#FF0000';
    }
    return finalColor;
  }
  checkThreshold13(data: number[]) {
    let finalColor = '#6fc276';
    if (this.calcMean(data) < -13 || this.calcMean(data) > 13) {
      finalColor = '#FF0000';
    }
    return finalColor;
  }

  loadTable() {
    const svvSubjectiveData: svvSubjectiveData[] = [];

    this.vestibularService
      .getSvvTherapyReport(this.reportId)
      .subscribe((svvReport: any) => {
        this.updateChart(svvReport);
        console.log(svvReport);
        // console.log(svvReport.svvReports[0].therapy.frameDegrees);
        // this.dataSource.data = svvReport.svvReports[0].svvReports;
        // console.log(this.dataSource.data);
        this.dateCreated = svvReport.dateCreated;

        this.exerciseStartTime = svvReport.exerciseStartTime; // svvReport.exerciseStartTime;
        this.exerciseEndTime = svvReport.exerciseEndTime;
        this.comment = svvReport.therapy.comment;
        this.description = svvReport.therapy.description;
        this.effectiveStart = svvReport.therapy.effectiveStart;
        this.effectiveEnd = svvReport.therapy.effectiveEnd;
        this.sessionNumber = svvReport.therapy.sessionNumber;
        this.numTrials = svvReport.trials.length;
        this.rotationSpeed = svvReport.therapy.rotationSpeed;
        this.usingFrame = svvReport.therapy.usingFrame;
        this.frameDegrees = svvReport.therapy.frameDegrees;
        // if (svvReport.trials.length) {
        //   this.mean =
        //     svvReport.trials.reduce((prev, current) => {
        //       return prev + current.enteredRot;
        //     }, 0) / svvReport.trials.length;
        //   this.stdDev =
        //     svvReport.trials.reduce((prev, current) => {
        //       return prev + (current.enteredRot - this.mean) ** 2;
        //     }, 0) / svvReport.trials.length;
        //   this.stdDev = Math.sqrt(this.stdDev);
        // }
      });

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
  }

  updateChart(svvReport: svvTherapyReport) {
    const svvTrialArrayStandard: SvvTrialData[] = [];
    const svvTrialArrayFrame: SvvTrialData[] = [];
    const svvTrialArrayStaticDots: SvvTrialData[] = [];
    const svvTrialArrayDynamicDots: SvvTrialData[] = [];
    const standardMeanTrialTime: any[] = [];
    const frameMeanTrialTime: any[] = [];
    const staticDotsMeanTrialTime: any[] = [];
    const dynamicDotsMeanTrialTime: any[] = [];
    svvReport.trials.sort((a, b) => {
      return <any>new Date(a.timeStart) - <any>new Date(b.timeStart);
    });

    for (let item in svvReport.trials) {
      console.log(
        new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
          new Date(svvReport.trials[item].timeStart).getUTCSeconds()
      );
      if (
        svvReport.trials[item].isDots == false &&
        svvReport.trials[item].isFrame == false
      ) {
        svvTrialArrayStandard.push({
          dotDirection: svvReport.trials[item].dotDirection,
          enteredRot: svvReport.trials[item].enteredRot,
          frameRot: svvReport.trials[item].frameRot,
          headsetVert: svvReport.trials[item].headsetVert,
          isDots: svvReport.trials[item].isDots,
          isFrame: svvReport.trials[item].isFrame,
          startingRot: svvReport.trials[item].startingRot,
          timeEnd: svvReport.trials[item].timeEnd,
          timeStart: svvReport.trials[item].timeStart,
        });
        if (svvReport.trials[item].startingRot == 40) {
          this.positiveStandardArray.push(svvReport.trials[item].enteredRot);
          this.positiveStandardArray.sort((a, b) => a - b);
          standardMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        } else if (svvReport.trials[item].startingRot == -40) {
          this.negativeStandardArray.push(svvReport.trials[item].enteredRot);
          this.negativeStandardArray.sort((a, b) => a - b);
          standardMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        }

        this.standardAvg =
          Math.round(this.calcAvg(standardMeanTrialTime) * 10) / 10;
      } else if (svvReport.trials[item].isFrame == true) {
        svvTrialArrayFrame.push({
          dotDirection: svvReport.trials[item].dotDirection,
          enteredRot: svvReport.trials[item].enteredRot,
          frameRot: svvReport.trials[item].frameRot,
          headsetVert: svvReport.trials[item].headsetVert,
          isDots: svvReport.trials[item].isDots,
          isFrame: svvReport.trials[item].isFrame,
          startingRot: svvReport.trials[item].startingRot,
          timeEnd: svvReport.trials[item].timeEnd,
          timeStart: svvReport.trials[item].timeStart,
        });
        if (
          svvReport.trials[item].startingRot == 40 &&
          svvReport.trials[item].frameRot == 28
        ) {
          this.positiveFrameArrayPositiveStarting.push(
            svvReport.trials[item].enteredRot
          );
          this.positiveFrameArrayPositiveStarting.sort((a, b) => a - b);
          frameMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        } else if (
          svvReport.trials[item].startingRot == -40 &&
          svvReport.trials[item].frameRot == 28
        ) {
          this.positiveFrameArrayNegativeStarting.push(
            svvReport.trials[item].enteredRot
          );
          this.positiveFrameArrayNegativeStarting.sort((a, b) => a - b);
          frameMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        } else if (
          svvReport.trials[item].startingRot == 40 &&
          svvReport.trials[item].frameRot == -28
        ) {
          this.negativeFrameArrayPositiveStarting.push(
            svvReport.trials[item].enteredRot
          );
          this.negativeFrameArrayPositiveStarting.sort((a, b) => a - b);
          frameMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        } else if (
          svvReport.trials[item].startingRot == -40 &&
          svvReport.trials[item].frameRot == -28
        ) {
          this.negativeFrameArrayNegativeStarting.push(
            svvReport.trials[item].enteredRot
          );
          this.negativeFrameArrayNegativeStarting.sort((a, b) => a - b);
          frameMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        }

        this.frameAvg = Math.round(this.calcAvg(frameMeanTrialTime) * 10) / 10;
      }
      if (
        svvReport.trials[item].isDots == true &&
        svvReport.trials[item].dotDirection == 0
      ) {
        svvTrialArrayStaticDots.push({
          dotDirection: svvReport.trials[item].dotDirection,
          enteredRot: svvReport.trials[item].enteredRot,
          frameRot: svvReport.trials[item].frameRot,
          headsetVert: svvReport.trials[item].headsetVert,
          isDots: svvReport.trials[item].isDots,
          isFrame: svvReport.trials[item].isFrame,
          startingRot: svvReport.trials[item].startingRot,
          timeEnd: svvReport.trials[item].timeEnd,
          timeStart: svvReport.trials[item].timeStart,
        });
        if (svvReport.trials[item].startingRot == 40) {
          this.positiveStaticDots.push(svvReport.trials[item].enteredRot);
          this.positiveStaticDots.sort((a, b) => a - b);
          staticDotsMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        } else if (svvReport.trials[item].startingRot == -40) {
          this.negativeStaticDots.push(svvReport.trials[item].enteredRot);
          this.negativeStaticDots.sort((a, b) => a - b);
          staticDotsMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        }
        this.staticDotsAvg =
          Math.round(this.calcAvg(staticDotsMeanTrialTime) * 10) / 10;
      }
      if (
        svvReport.trials[item].isDots == true &&
        (svvReport.trials[item].dotDirection == -1 ||
          svvReport.trials[item].dotDirection == 1)
      ) {
        svvTrialArrayDynamicDots.push({
          dotDirection: svvReport.trials[item].dotDirection,
          enteredRot: svvReport.trials[item].enteredRot,
          frameRot: svvReport.trials[item].frameRot,
          headsetVert: svvReport.trials[item].headsetVert,
          isDots: svvReport.trials[item].isDots,
          isFrame: svvReport.trials[item].isFrame,
          startingRot: svvReport.trials[item].startingRot,
          timeEnd: svvReport.trials[item].timeEnd,
          timeStart: svvReport.trials[item].timeStart,
        });
        if (
          svvReport.trials[item].startingRot == 40 &&
          svvReport.trials[item].dotDirection == 1
        ) {
          this.positiveDotsArrayPositiveStarting.push(
            svvReport.trials[item].enteredRot
          );
          this.positiveDotsArrayPositiveStarting.sort((a, b) => a - b);
          dynamicDotsMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        } else if (
          svvReport.trials[item].startingRot == -40 &&
          svvReport.trials[item].dotDirection == 1
        ) {
          this.positiveDotsArrayNegativeStarting.push(
            svvReport.trials[item].enteredRot
          );
          this.positiveDotsArrayNegativeStarting.sort((a, b) => a - b);
          dynamicDotsMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        } else if (
          svvReport.trials[item].startingRot == 40 &&
          svvReport.trials[item].dotDirection == -1
        ) {
          this.negativeDotsArayPositiveStarting.push(
            svvReport.trials[item].enteredRot
          );
          this.negativeDotsArayPositiveStarting.sort((a, b) => a - b);
          dynamicDotsMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        } else if (
          svvReport.trials[item].startingRot == -40 &&
          svvReport.trials[item].dotDirection == -1
        ) {
          this.negativeDotsArrayNegativeStarting.push(
            svvReport.trials[item].enteredRot
          );
          this.negativeDotsArrayNegativeStarting.sort((a, b) => a - b);
          dynamicDotsMeanTrialTime.push(
            new Date(svvReport.trials[item].timeEnd).getUTCSeconds() -
              new Date(svvReport.trials[item].timeStart).getUTCSeconds()
          );
        }
        this.dynamicDotsAvg =
          Math.round(this.calcAvg(dynamicDotsMeanTrialTime) * 10) / 10;
      }
    }

    const standardTrialArray: SvvTrialDataSource[] = [];
    const frameTrialArray: SvvTrialDataSource[] = [];
    const staticDotsTrialArray: SvvTrialDataSource[] = [];
    const dynamicDotsTrialArray: SvvTrialDataSource[] = [];

    this.calcMin([
      this.positiveStandardArray[0],
      this.negativeStandardArray[0],
      this.positiveFrameArrayPositiveStarting[0],
      this.positiveFrameArrayNegativeStarting[0],
      this.negativeFrameArrayPositiveStarting[0],
      this.negativeFrameArrayNegativeStarting[0],
      this.negativeStaticDots[0],
      this.positiveStaticDots[0],
      this.negativeStaticDots[0],
      this.positiveDotsArrayPositiveStarting[0],
      this.positiveDotsArrayNegativeStarting[0],
      this.negativeDotsArayPositiveStarting[0],
      this.negativeDotsArrayNegativeStarting[0],
    ]);

    this.calcMax([
      this.positiveStandardArray[1],
      this.negativeStandardArray[1],
      this.positiveFrameArrayPositiveStarting[1],
      this.positiveFrameArrayNegativeStarting[1],
      this.negativeFrameArrayPositiveStarting[1],
      this.negativeFrameArrayNegativeStarting[1],
      this.negativeStaticDots[1],
      this.positiveStaticDots[1],
      this.negativeStaticDots[1],
      this.positiveDotsArrayPositiveStarting[1],
      this.positiveDotsArrayNegativeStarting[1],
      this.negativeDotsArayPositiveStarting[1],
      this.negativeDotsArrayNegativeStarting[1],
    ]);

    standardTrialArray.push(
      {
        mean: this.calcMean(this.positiveStandardArray),
        standardDev: this.calcStdDev(this.positiveStandardArray),
        min: this.positiveStandardArray[0],
        max: this.positiveStandardArray[1],
        pic: '../../../assets/vestibular/svvPictures/r_cw.png',
      },
      {
        mean: this.calcMean(this.negativeStandardArray),
        standardDev: this.calcStdDev(this.negativeStandardArray),
        min: this.negativeStandardArray[0],
        max: this.negativeStandardArray[1],
        pic: '../../../assets/vestibular/svvPictures/r_ccw.png',
      }
    );
    frameTrialArray.push(
      {
        mean: this.calcMean(this.positiveFrameArrayPositiveStarting),
        standardDev: this.calcStdDev(this.positiveFrameArrayPositiveStarting),
        min: this.positiveFrameArrayPositiveStarting[0],
        max: this.positiveFrameArrayPositiveStarting[1],
        pic: '../../../assets/vestibular/svvPictures/f_cw_r_cw.png',
      },
      {
        mean: this.calcMean(this.positiveFrameArrayNegativeStarting),
        standardDev: this.calcStdDev(this.positiveFrameArrayNegativeStarting),
        min: this.positiveFrameArrayNegativeStarting[0],
        max: this.positiveFrameArrayNegativeStarting[1],
        pic: '../../../assets/vestibular/svvPictures/f_cw_r_ccw.png',
      },
      {
        mean: this.calcMean(this.negativeFrameArrayPositiveStarting),
        standardDev: this.calcStdDev(this.negativeFrameArrayPositiveStarting),
        min: this.negativeFrameArrayPositiveStarting[0],
        max: this.negativeFrameArrayPositiveStarting[1],
        pic: '../../../assets/vestibular/svvPictures/f_ccw_r_cw.png',
      },
      {
        mean: this.calcMean(this.negativeFrameArrayNegativeStarting),
        standardDev: this.calcStdDev(this.negativeFrameArrayNegativeStarting),
        min: this.negativeFrameArrayNegativeStarting[0],
        max: this.negativeFrameArrayNegativeStarting[1],
        pic: '../../../assets/vestibular/svvPictures/f_ccw_r_ccw.png',
      }
    );
    staticDotsTrialArray.push(
      {
        mean: this.calcMean(this.positiveStaticDots),
        standardDev: this.calcStdDev(this.positiveStaticDots),
        min: this.positiveStaticDots[0],
        max: this.positiveStaticDots[1],
        pic: '../../../assets/vestibular/svvPictures/d_s_r_cw.png',
      },
      {
        mean: this.calcMean(this.negativeStaticDots),
        standardDev: this.calcStdDev(this.negativeStaticDots),
        min: this.negativeStaticDots[0],
        max: this.negativeStaticDots[1],
        pic: '../../../assets/vestibular/svvPictures/d_s_r_ccw.png',
      }
    );
    dynamicDotsTrialArray.push(
      {
        mean: this.calcMean(this.positiveDotsArrayPositiveStarting),
        standardDev: this.calcStdDev(this.positiveDotsArrayPositiveStarting),
        min: this.positiveDotsArrayPositiveStarting[0],
        max: this.positiveDotsArrayPositiveStarting[1],
        pic: '../../../assets/vestibular/svvPictures/d_cw_r_cw.png',
      },
      {
        mean: this.calcMean(this.positiveDotsArrayNegativeStarting),
        standardDev: this.calcStdDev(this.positiveDotsArrayNegativeStarting),
        min: this.positiveDotsArrayNegativeStarting[0],
        max: this.positiveDotsArrayNegativeStarting[1],
        pic: '../../../assets/vestibular/svvPictures/d_cw_r_ccw.png',
      },
      {
        mean: this.calcMean(this.negativeDotsArayPositiveStarting),
        standardDev: this.calcStdDev(this.negativeDotsArayPositiveStarting),
        min: this.negativeDotsArayPositiveStarting[0],
        max: this.negativeDotsArayPositiveStarting[1],
        pic: '../../../assets/vestibular/svvPictures/d_ccw_r_cw.png',
      },
      {
        mean: this.calcMean(this.negativeDotsArrayNegativeStarting),
        standardDev: this.calcStdDev(this.negativeDotsArrayNegativeStarting),
        min: this.negativeDotsArrayNegativeStarting[0],
        max: this.negativeDotsArrayNegativeStarting[1],
        pic: '../../../assets/vestibular/svvPictures/d_ccw_r_ccw.png',
      }
    );

    this.svvTrialDataSourceStandard.data = standardTrialArray;
    this.svvTrialDataSourceFrame.data = frameTrialArray;
    this.svvTrialDataSourceStaticDots.data = staticDotsTrialArray;
    this.svvTrialDataSourceDynamicDots.data = dynamicDotsTrialArray;

    if (this.svvResultsRangeBarStandard) {
      if (Math.abs(this.newMin) < Math.abs(this.newMax)) {
        this.newMin = -this.newMax;
      } else if (Math.abs(this.newMin) > Math.abs(this.newMax)) {
        this.newMax = Math.abs(this.newMin);
      }
      this.svvResultsRangeBarStandard.xaxis = {
        tickAmount: (this.newMax / 10) * 2,
        min: this.newMin,
        max: this.newMax,
        labels: {
          show: true,
          style: {
            colors: [],
            fontSize: '16px',

            fontWeight: 400,
          },
          formatter: (value) => {
            return value + '°';
          },
        },
      };
    }

    console.log(this.positiveStandardArray);
    this.svvResultsRangeBarStandard?.series?.push({
      data: [
        {
          x: '12',
          y: this.positiveStandardArray,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.positiveStandardArray),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold3(this.positiveStandardArray),
        },
        {
          x: '11',
          y: this.negativeStandardArray,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.negativeStandardArray),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold3(this.negativeStandardArray),
        },
        {
          x: '10',
          y: this.positiveFrameArrayPositiveStarting,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.positiveFrameArrayPositiveStarting),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold5(
            this.positiveFrameArrayPositiveStarting
          ),
        },
        {
          x: '9',
          y: this.positiveFrameArrayNegativeStarting,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.positiveFrameArrayNegativeStarting),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold5(
            this.positiveFrameArrayNegativeStarting
          ),
        },
        {
          x: '8',
          y: this.negativeFrameArrayPositiveStarting,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.negativeFrameArrayPositiveStarting),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold5(
            this.negativeFrameArrayPositiveStarting
          ),
        },
        {
          x: '7',
          y: this.negativeFrameArrayNegativeStarting,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.negativeFrameArrayNegativeStarting),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold5(
            this.negativeFrameArrayNegativeStarting
          ),
        },
        {
          x: '6',
          y: this.positiveStaticDots,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.positiveStaticDots),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold3(this.positiveStaticDots),
        },
        {
          x: '5',
          y: this.negativeStaticDots,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.negativeStaticDots),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold3(this.negativeStaticDots),
        },
        {
          x: '4',
          y: this.positiveDotsArrayPositiveStarting,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.positiveDotsArrayPositiveStarting),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold13(
            this.positiveDotsArrayPositiveStarting
          ),
        },
        {
          x: '3',
          y: this.positiveDotsArrayNegativeStarting,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.positiveDotsArrayNegativeStarting),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold13(
            this.positiveDotsArrayNegativeStarting
          ),
        },
        {
          x: '2',
          y: this.negativeDotsArayPositiveStarting,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.negativeDotsArayPositiveStarting),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold13(
            this.negativeDotsArayPositiveStarting
          ),
        },
        {
          x: '1',
          y: this.negativeDotsArrayNegativeStarting,
          goals: [
            {
              name: 'Mean',
              value: this.calcMean(this.negativeDotsArrayNegativeStarting),
              strokeColor: '#054347',
            },
          ],
          fillColor: this.checkThreshold13(
            this.negativeDotsArrayNegativeStarting
          ),
        },
      ],
    });
  }

  protected readonly formatDate = formatDate;
}
