import { Component, Input, ViewChild } from '@angular/core';
import { StudyDescription } from '../../filter.service';
import { CommonModule, formatDate } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { StudyService } from '../../study.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { VorFavoritesService } from '../vor-favorites.service';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { therapyReport } from '../vor-results/vor-results.component';
import { svvTherapyReport } from '../svv-results/svv-results.component';
import { Therapy, TherapyList } from '../vestibular.service';

@Component({
  selector: 'app-vestibular-study-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    RouterLink,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
  ],
  templateUrl: './vestibular-study-dashboard.component.html',
  styleUrls: ['./vestibular-study-dashboard.component.scss'],
})
export class VestibularStudyDashboardComponent {
  @Input('study')
  public study!: StudyDescription;
  studyId: number = 0;
  columns: string[] = ['name'];

  dataSource = new MatTableDataSource<Therapy>();

  @ViewChild('paginator', { static: false }) set paginator(
    value: MatPaginator
  ) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild('sort') sort: MatSort | null = null;

  constructor(
    private route: ActivatedRoute,
    public studyService: StudyService,
    public vorFavoritesService: VorFavoritesService
  ) {
    // @ts-ignore
    this.studyId = +this.route.snapshot.paramMap.get('id') || 0;
    this.dataSource.sort = this.sort;

    vorFavoritesService
      .loadFavoritesForStudy(this.studyId)
      .subscribe((favs: any) => {
        favs['favoriteTherapies'].sort((a: any, b: any) => {
          return b.id - a.id;
        });
        this.dataSource.data = favs['favoriteTherapies'];
      });
    // }
  }
}
