// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
}

.role {
  background: lightgray;
  border-radius: 10px;
  padding: 3px;
  margin-left: 5px;
}

.patientList {
  column-count: 3;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/cohort-admin/cohort-admin.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAGA;EACE,qBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AAAF;;AAIA;EACE,eAAA;AADF","sourcesContent":[":host {\n  flex-grow: 1;\n}\n\n\n.role {\n  background: lightgray;\n  border-radius: 10px;\n  padding: 3px;\n  margin-left: 5px;\n}\n\n\n.patientList {\n  column-count: 3;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
