import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { Study } from '../filter.service';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StudyService } from '../study.service';

@Component({
  selector: 'app-study-summary',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RouterLink,
    MatTooltipModule,
  ],
  templateUrl: './study-summary.component.html',
  styleUrls: ['./study-summary.component.scss'],
})
export class StudySummaryComponent {
  @Input('study')
  study!: Study;

  constructor(public studyService: StudyService) {}
}
