import { Component, Input, OnInit } from '@angular/core';
import { TestList } from '../patient';
import { PatientService } from '../patient.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { MindcapService } from '../mindcap.service';

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
  styleUrls: ['./test-list.component.css'],
  standalone: true,
  imports: [
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgFor,
  ],
})
export class TestListComponent implements OnInit {
  @Input('selectedPatients')
  selectedPatients: string = '';

  public testList: TestList | undefined;

  displayedColumns: string[] = ['type', 'complete', 'time', 'id'];

  constructor(private mindcapService: MindcapService) {}

  ngOnInit() {
    this.getTestList();
  }

  getTestList(): void {
    this.mindcapService
      .getTestList(this.selectedPatients)
      .subscribe((testList) => {
        this.testList = testList;
      });
  }
}
