import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PatientDescription } from '../patient';
import { ListPatients, PatientService } from '../patient.service';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface PatientCohortTableData {
  patientId: number;
  uuid: string;
  cohortName: string;
  cohortId: string;
  studyName: string;
  username: string;
  studyId: string;
}

const PATIENT_DATA: PatientCohortTableData[] = [];
@Component({
  selector: 'patient-list-component',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    NgIf,
    NgFor,
    MatProgressSpinnerModule,
  ],
})
export class PatientListComponent implements OnInit, AfterViewInit {
  patientList: ListPatients | undefined;

  displayedColumns: string[] = ['patientId', 'cohortName', 'actions', 'edit'];

  dataSource = new MatTableDataSource<PatientCohortTableData>();
  loading = true;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(public patientService: PatientService) {}
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getDashboard();
  }

  getDashboard(): void {
    this.patientService.getPatients().subscribe((patients: ListPatients) => {
      this.patientList = patients;
      this.getTableData();
      this.loading = false;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTableData(): void {
    const filteredArray: PatientCohortTableData[] = [];
    if (this.patientList != undefined) {
      this.patientList?.patients.forEach((patient: PatientDescription) =>
        filteredArray.push({
          patientId: patient.id,
          uuid: patient.uuid,
          cohortName: patient.cohortName || '-',
          cohortId: patient.cohortId || '-',
          studyName: patient.studyName || '-',
          username: patient.patientId,
          studyId: patient.studyId,
        }),
      );
      this.dataSource.data = filteredArray;
      this.loading = false;
    }
  }
}
