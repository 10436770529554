import { Component } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { StudyService } from '../study.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { StudyDescription } from '../filter.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { DermatologyStudyDashboardComponent } from '../derm/dermatology-study-dashboard/dermatology-study-dashboard.component';
import { VestibularStudyDashboardComponent } from '../vestibular/vestibular-study-dashboard/vestibular-study-dashboard.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Role } from '../guards/role.guard';
import { KeycloakService } from 'keycloak-angular';
import {MatExpansionModule} from "@angular/material/expansion";
import {PatientMdermReportListComponent} from "../patient-mderm-report-list/patient-mderm-report-list.component";
import {FeatureServiceService} from "../feature-service.service";
import {Observable} from "rxjs";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-study',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    NgFor,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatButtonToggleModule,
    RouterLink,
    MatTooltipModule,
    MatCardModule,
    DermatologyStudyDashboardComponent,
    VestibularStudyDashboardComponent,
    MatExpansionModule,
    PatientMdermReportListComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss'],
})
export class StudyComponent {
  studyId: number = 0;

  study!: StudyDescription;






activeFeatureObs : Observable<String> = this.featureService.activeFeatureObs
  activeFeature : String= ""

  constructor(
    private route: ActivatedRoute,
    public studyService: StudyService,
    private breadcrumbService: BreadcrumbService,
    public keycloakService: KeycloakService,
  private featureService: FeatureServiceService
  ) {
    // @ts-ignore
    this.studyId = +this.route.snapshot.paramMap.get('id') || 0;

    this.studyService.loadStudy(this.studyId).subscribe((x) => {
      this.study = x;
      this.breadcrumbService.set('home/study/:id', 'Study: ' + x.name);
    });
    this.activeFeatureObs.subscribe( x => { this.activeFeature = x } )
  }

  featureChanged( feature : String )
  {
    if ( this.featureService.activeFeature != feature ) {
      this.featureService.activeFeature = feature
    }
  }


  toggleFeature(event: any, feature: string) {
    let isOn = event.source.checked;
    this.studyService
      .toggleFeature(this.studyId, feature, isOn)
      .subscribe((x) => {
        this.study = x;
      });
  }

  protected readonly Role = Role;
}
