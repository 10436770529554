import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  KeycloakAngularModule,
  KeycloakService,
  KeycloakEventType,
} from 'keycloak-angular';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';

import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DataSetListComponent } from './data-set-list/data-set-list.component';
import { CommonModule } from '@angular/common';
import { DataSetListComponentDialog } from './data-set-list/data-set-list.component';
import { PatientProfilePopupComponent } from './patient-profile-popup/patient-profile-popup.component';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorPageComponent } from './error-page/error-page.component';
// import { BodyMapModule } from '@nxthealth/bodymap';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import {
  CalendarModule,
  DateAdapter,
} from '@nxthealth/angular-calendar-noscarf';
import { adapterFactory } from '@nxthealth/angular-calendar-noscarf/date-adapters/moment';
import * as moment from 'moment';
import { TestResultComponent } from './test-result/test-result.component';
import { TestListComponent } from './test-list/test-list.component';
import { SurveyListComponent } from './survey-list/survey-list.component';
import { SurveyDetailsComponent } from './survey-details/survey-details.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { Router, RouterModule } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { VorResultsComponent } from './vestibular/vor-results/vor-results.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { VjsPlayerComponent } from './vjs-player/vjs-player.component';
import { MindcapGraphsComponent } from './Graphs/mindcap-graphs/mindcap-graphs.component';
import { MindcapEventGraphComponent } from './Graphs/mindcap-event-graph/mindcap-event=graph.component';
import { SpiderChartComponent } from './Graphs/spider-chart/spider-chart.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

function initializeKeycloak(keycloak: KeycloakService, router: Router) {
  handleAuthSuccess(keycloak, router);
  return () =>
    keycloak.init({
      config: {
        realm: 'nxthealth',
        url: environment.keycloak.authUrl,
        clientId: 'nxthealth-dashboard',
      },
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },

      shouldAddToken: (request) => {
        const { method, url } = request;

        const isGetRequest = 'GET' === method.toUpperCase();
        const acceptablePaths = ['/assets', '/clients/public'];
        const isAcceptablePathMatch = acceptablePaths.some((path) =>
          url.includes(path)
        );

        return true;
      },

      loadUserProfileAtStartUp: true,
    });
}

function handleAuthSuccess(keycloak: KeycloakService, router: Router) {
  keycloak.keycloakEvents$.subscribe({
    next: (event) => {
      if (event.type === KeycloakEventType.OnAuthSuccess) {
        const alreadyLoggedIn = sessionStorage.getItem('alreadyLoggedIn');

        if (!alreadyLoggedIn || alreadyLoggedIn === 'false') {
          sessionStorage.setItem('alreadyLoggedIn', 'true');
          router.navigate(['/home']);
        }
      }
    },
  });
}

@NgModule({
  declarations: [AppComponent, NavBarComponent, ErrorPageComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    DataSetListComponent,
    DataSetListComponentDialog,
    MatDialogModule,
    MatMenuModule,
    TestListComponent,
    TestResultComponent,
    SurveyListComponent,
    SurveyDetailsComponent,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgApexchartsModule,
    BreadcrumbModule,
    RouterModule,
    MatMomentDateModule,
    MatGridListModule,
    VjsPlayerComponent,
    MindcapEventGraphComponent,
    SpiderChartComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, Router],
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
