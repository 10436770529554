// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-tooltip-line-break {
  white-space: pre-line;
}

.spinner-div {
  height: 300px !important;
  min-height: 75% !important;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  align-self: center;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/vestibular/vestibular-cohort-dashboard/vestibular-cohort-dashboard.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACE,wBAAA;EACA,0BAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,qBAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;AACF","sourcesContent":["::ng-deep .mat-tooltip-line-break{\n    white-space: pre-line;\n    }\n\n.spinner-div {\n  height: 300px !important;\n  min-height: 75% !important;\n  flex-wrap: wrap;\n  flex-direction: column;\n  display: flex;\n  align-self: center;\n  align-content: center;\n  align-items: center;\n  vertical-align: middle;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
