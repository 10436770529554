// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `parent {
  width: 100%; /* OR like 95% to make shadows visible */
  height: 100%; /* OR like 95% to make shadows visible */
}

app-study-summary {
  width: calc(100% - 70px);
  height: calc(100% - 70px);
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: stretch;
}`, "",{"version":3,"sources":["webpack://./src/app/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA,EAAA,wCAAA;EACA,YAAA,EAAA,wCAAA;AACF;;AAEA;EACE,wBAAA;EACA,yBAAA;AACF;;AAEA;EACI,aAAA;EACA,eAAA;EACA,qBAAA;EACA,6BAAA;EACA,oBAAA;AACJ","sourcesContent":["parent {\n  width: 100%;  /* OR like 95% to make shadows visible */\n  height: 100%; /* OR like 95% to make shadows visible */\n}\n\napp-study-summary {\n  width: calc(100% - 70px);\n  height: calc(100% - 70px);\n}\n\n.tiles {\n    display: flex;\n    flex-wrap: wrap;\n    align-content: center;\n    justify-content: space-evenly;\n    align-items: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
