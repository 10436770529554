import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { DashboardService } from '../../dashboard.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClinicianService } from '../../clinician.service';
import { ClinicianDescription } from '../../patient';

export interface TableData {
  clinicianId: number;
  uuid: string;
  firstName: string;
  lastName: string;
  username: string;
  isPartnerAdmin: boolean;
}

const CLINICIAN_DATA: TableData[] = [];

@Component({
  selector: 'app-clinician-list',
  templateUrl: './clinician-list.component.html',
  styleUrls: ['./clinician-list.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    NgIf,
    NgFor,
    MatProgressSpinnerModule,
  ],
})
export class ClinicianListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'clinicianId',
    'lastName',
    'firstName',
    'role',
    'edit',
  ];
  // dataToDisplay = [...PATIENT_DATA];
  dataSource = new MatTableDataSource<TableData>();
  loading = true;

  dashboard: ClinicianDescription[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(
    private clinicianService: ClinicianService,
    private dashboardService: DashboardService,
  ) {}
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getDashboard();
  }

  getDashboard(): void {
    this.clinicianService.getAllClinicians([]).subscribe((dashboard) => {
      this.dashboard = dashboard;
      this.getTableData();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTableData(): void {
    const filteredArray: TableData[] = [];
    if (this.dashboard != undefined) {
      this.dashboard.forEach((clinician: ClinicianDescription) =>
        filteredArray.push({
          firstName: clinician?.firstName,
          lastName: clinician?.lastName,
          clinicianId: clinician?.id,
          uuid: clinician?.uuid,
          username: clinician?.username,
          isPartnerAdmin: clinician?.isPartnerAdmin,
        }),
      );
      this.dataSource.data = filteredArray;
      this.loading = false;
    }
  }
}
