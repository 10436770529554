// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  font-size: 14px;
  width: 100%;
}

.button-row {
  display: table-cell;
  max-width: 600px;
}

.button-row .mat-mdc-button-base {
  margin: 8px 8px 8px 0;
}

.spinner-div {
  height: 75% !important;
  min-height: 75% !important;
}

.spinner {
  height: 50vh;
  position: relative;
  margin-top: 20%;
  margin-left: 48%;
}`, "",{"version":3,"sources":["webpack://./src/app/patient-list/patient-list.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,sBAAA;EACA,0BAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AACF","sourcesContent":[".mat-mdc-form-field {\n  font-size: 14px;\n  width: 100%;\n}\n\n.button-row {\n  display: table-cell;\n  max-width: 600px;\n}\n\n.button-row .mat-mdc-button-base {\n  margin: 8px 8px 8px 0;\n}\n\n.spinner-div {\n  height: 75% !important;\n  min-height: 75% !important;\n}\n\n.spinner {\n  height: 50vh;\n  position: relative;\n  margin-top: 20%;\n  margin-left: 48%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
