import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BreadcrumbModule} from "xng-breadcrumb";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {RouterOutlet} from "@angular/router";
import {SystemMessage, SystemMessageService} from "../system-message.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [CommonModule, BreadcrumbModule, MatCardModule, MatIconModule, RouterOutlet],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  messages: Observable<SystemMessage[]>;

  constructor(
    private systemMessageSerivce: SystemMessageService
  ) {
    this.messages = systemMessageSerivce.messages;
  }
}
