import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NetworkErrorService } from '../../network-error.service';
import { CohortsByStudy } from '../../filter.service';
import { catchError, Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CohortDescription, PatientDescription } from '../../patient';

const cohortDashboardUrl: string = environment.mdermUrl + '/home/dashboard';

export interface ReportCount {
  count: number;
  patientGuid: string;
}

export interface ReportTime {
  date: Date;
  patientGuid: string;
}

export interface CohortDashboard {
  reports: ReportCount[];
  selectedPatients: { patients: PatientDescription[] };
  mostRecentReport: ReportTime[];
}

@Injectable({
  providedIn: 'root',
})
export class DermatologyCohortDashboardService {
  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService,
  ) {}

  getDashboard(cohort: CohortDescription): Observable<CohortDashboard> {
    let params = new HttpParams();
    params = params.append('selectedCohorts', cohort.uuid);

    return this.http.get<CohortDashboard>(cohortDashboardUrl, { params }).pipe(
      tap((_) => this.networkErrorService.log('fetched cohort dashboard')),
      catchError(
        this.networkErrorService.handleError<CohortDashboard>(
          'Mderm: getCohortDashboard',
          undefined,
        ),
      ),
    );
  }
}
