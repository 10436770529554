import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {SvvTherapy, Therapy, TherapyList} from "./vestibular.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NetworkErrorService} from "../network-error.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VorFavoritesService {

  favorites: any[] = [];

  private vestibularUrl = environment.vestibularUrl;
  private therapiesUrl = `${this.vestibularUrl}/therapy`; // URL to web api

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService
  )
  {

  }

  loadFavoritesForStudy(studyId: number) {
    // todo: cache favorites
    return this.getFavorites( studyId.toString() );
  }


    createFavorite(therapy: Therapy, studyId: String): Observable<SvvTherapy> {
    return this.http
      .post<SvvTherapy>(
        this.therapiesUrl + '/createFavorite?study=' + studyId,
        therapy
      )
      .pipe(
        catchError(
          this.networkErrorService.handleError<SvvTherapy>(
            'Therapy',
            undefined,
            404
          )
        )
      );
  }

    getFavorites(studyId: string): Observable<TherapyList> {
    let params = new HttpParams();
    params = params.append('study', studyId);

    return this.http
      .get<TherapyList>(this.therapiesUrl + '/listFavorite', {
        params,
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError<TherapyList>(
            'TherapyList',
            undefined,
            404
          )
        )
      );
  }

}
