import { Component, ViewChild } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { StudyService } from '../../study.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { StudyDescription } from '../../filter.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  Therapy,
  VestibularService,
} from 'src/app/vestibular/vestibular.service';
import { VorFavoritesService } from '../../vestibular/vor-favorites.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-study-admin',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    NgFor,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatButtonToggleModule,
    RouterLink,
    MatTooltipModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './study-admin.component.html',
  styleUrls: ['./study-admin.component.scss'],
})
export class StudyAdminComponent {
  studyId: number = 0;

  study!: StudyDescription;

  studyString: string = '';

  columns: string[] = ['name'];

  dataSource = new MatTableDataSource<Therapy>();

  @ViewChild(MatPaginator, { static: false }) set paginator(
    value: MatPaginator
  ) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(
    private route: ActivatedRoute,
    public studyService: StudyService,
    private breadcrumbService: BreadcrumbService,
    private vestibularService: VestibularService,
    public vorFavoritesService: VorFavoritesService
  ) {
    // @ts-ignore
    this.studyId = +this.route.snapshot.paramMap.get('id') || 0;
    this.studyString = this.route.snapshot.paramMap.get('id') || '';

    this.studyService.loadStudy(this.studyId).subscribe((x) => {
      this.study = x;
      console.log(this.study);
      this.breadcrumbService.set('@studyName', 'Study: ' + x?.name);
    });

    vorFavoritesService
      .loadFavoritesForStudy(this.studyId)
      .subscribe((favs: any) => {
        favs['favoriteTherapies'].sort((a: any, b: any) => {
          return b.id - a.id;
        });
        this.dataSource.data = favs['favoriteTherapies'];
      });
  }

  toggleFeature(event: any, feature: string) {
    let isOn = event.source.checked;
    this.studyService
      .toggleFeature(this.studyId, feature, isOn)
      .subscribe((x) => {
        this.study = x;
      });
  }
}
