import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataSetListService {
  constructor(private http: HttpClient) {}

  getData(): Observable<HttpEvent<any>> {
    const req = new HttpRequest(
      'GET',
      `${environment.mdermUrl}/dataset/index`,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.http.request(req);
  }

  getURL(dataSetId: string): Observable<HttpEvent<any>> {
    const req = new HttpRequest(
      'GET',
      `${environment.mdermUrl}/dataset/downloadDataset?id=${dataSetId}`,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.http.request(req);
  }

  createTrainingRequest(
    modelGenerationList: any,
    modelName: any,
    modelComment?: any
  ): Observable<HttpEvent<any>> {
    let postData = {
      dataSetIds: modelGenerationList,
      name: modelName,
      comment: modelComment,
    };
    const req = new HttpRequest(
      'POST',
      `${environment.mdermUrl}/api/createTrainingRequest`,
      postData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  updateDataset(
    dataSetId: string,
    name: string,
    source: string,
    comment: string,
    pointOfContact: string,
    isDeidentified: boolean
  ): Observable<HttpEvent<any>> {
    let updateData = {
      name: name,
      source: source,
      comments: comment,
      pointOfContact: pointOfContact,
      isDeidentified: isDeidentified,
    };
    const req = new HttpRequest(
      'PATCH',
      `${environment.mdermUrl}/api/patchDataset/${dataSetId}`,
      updateData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.http.request(req);
  }

  deleteTrainingRequest(dataSetId: any): Observable<HttpEvent<any>> {
    const req = new HttpRequest(
      'DELETE',
      `${environment.mdermUrl}/api/deleteDataset/${dataSetId}`,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.http.request(req);
  }
}
