import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { StudyCreateService } from '../study-create.service';
import { Router } from '@angular/router';

export class NewStudy {
  name: string = 'New Study';
  notes: string = 'No Notes';
}

@Component({
  selector: 'app-new-study',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatOptionModule,
    MatSelectModule,
  ],
  templateUrl: './new-study.component.html',
  styleUrls: ['./new-study.component.scss'],
})
export class NewStudyComponent {
  public study: NewStudy = new NewStudy();

  loading: boolean = false;
  error: string = '';

  constructor(
    public studyCreateService: StudyCreateService,
    public router: Router
  ) {}

  create(): void {
    this.loading = true;

    this.studyCreateService.createStudy(this.study).subscribe({
      next: (x) => {
        this.loading = false;
        this.router.navigate(['/home', 'admin']);
      },
      error: (x) => {
        this.loading = false;
        this.error = 'Error creating study';
      },
    });
  }
}
