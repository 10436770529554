// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
}

.mat-expansion-panel-header-title {
  font-weight: bold;
  font-size: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/patient-home/patient-home.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAGA;EACE,iBAAA;EACA,gBAAA;AAAF","sourcesContent":[":host {\n  flex-grow: 1;\n}\n\n\n.mat-expansion-panel-header-title {\n  font-weight: bold;\n  font-size: 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
