import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BodyPart } from '@nxthealth/bodymap';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export abstract class Report {
  id: number;

  constructor(id: number) {
    this.id = id;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private readonly MDERM_URL = environment.mdermUrl

  constructor(private httpClient: HttpClient) {}

  getReports(patientId: string): Promise<Report[]>  {
    let params = new HttpParams();
    params = params.append("patientGuid", patientId);
    const url = `${this.MDERM_URL}/api/getReportsForPatient`;
    return new Promise((resolve, reject) => {
      lastValueFrom(this.httpClient.get(url, {params}))
      .then((resp: any) => {
        console.log(resp)
        if (resp.reports && Array.isArray(resp.reports)) {
          resolve(resp.reports as Report[])
        }
        // resolve(resp as Report[])
      })
      .catch(e => reject(e))
    })
  }

  getReport(patientId: string, reportId: number): Promise<Report> {
    return new Promise((resolve, reject) => {
      this.getReports(patientId)
      .then(resp => {
        const report: any = resp.find(report => report.id === reportId)
        if (report) {
          const params = new HttpParams().appendAll({
            patientGuid: patientId,
            reportId: reportId
          })
          const url = `${this.MDERM_URL}/api/getImagesForReport`;
          lastValueFrom(this.httpClient.get(url, {params}))
          .then((resp: any) => {
            if (resp.bodyPartPictures && Array.isArray(resp.bodyPartPictures)) {
              report.bodyPartPictures = resp.bodyPartPictures
              resolve(report)
            } else {
              reject(new Error('This report does not have any pictures!'))
            }
          })
          .catch(e => console.warn(e))
        } else {
          reject(new Error(`Report with ID '${reportId} not found!`))
        }
      })
      .catch(e => reject(e))
    })
  }
}
