import { Injectable } from '@angular/core';
import { ClinicianDescription, Patient, PatientDemographics } from '../patient';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NetworkErrorService } from '../network-error.service';
import { Observable, empty, EMPTY, of } from 'rxjs';

export interface MindcapCalendarEvent {
  title: string;
  start: Date;
  end: Date;
}

export interface MindcapCalendarWeek {
  title: string;
  start: Date;
  end: Date;
}

export interface PhaseChange {
  title: string;
  start: Date;
  end: Date;
}

export interface InsufficientDays {
  title: string;
  start: Date;
}

export interface MindcapCalendar {
  events: MindcapCalendarEvent[];
  weeks: MindcapCalendarWeek[];
  phaseChanges: PhaseChange[];
  insufficientDays: InsufficientDays[];
  chemoStart: Date;
  chemoEnd: Date;
  studyStart: Date;
  nextPromis: Date;
  nextPromis2: Date;
  nextBadgePopup: Date;
  finalUsability: Date;
  baselineDate: Date;
}

@Injectable({
  providedIn: 'root',
})
export class MindcapPatientCalendarService {
  constructor(
    protected http: HttpClient,
    protected networkErrorService: NetworkErrorService,
  ) {}

  LoadCalendar(patientGuid: string): Observable<MindcapCalendar> {
    if (patientGuid) {
      let params = new HttpParams();
      params = params.append('guid', patientGuid);
      var x = this.http
        .get<MindcapCalendar>(environment.mindcapUrl + '/patient/calendar', {
          params,
        })
        .pipe(
          catchError(
            this.networkErrorService.handleError<MindcapCalendar>(
              'LoadCalendar',
              undefined,
            ),
          ),
        );
      return x;
    }
    return EMPTY.pipe();
  }
}
