import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Animations, Patterns, Therapy } from '../vestibular.service';

@Component({
  selector: 'app-vestibular-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vestibular-preview.component.html',
  styleUrls: ['./vestibular-preview.component.scss'],
})
export class VestibularPreviewComponent implements OnInit {
  @Input('therapy')
  therapy?: Therapy;

  @ViewChild('preview')
  preview?: ElementRef;

  @ViewChild('previewAnim')
  previewAnim?: ElementRef;

  // @ViewChild('previewAnim')
  // anim?: ElementRef;

  @ViewChild('previewWindow')
  window?: ElementRef;

  @ViewChild('optotype')
  optotype?: ElementRef;

  @Input()
  background: string = '';

  ngOnInit() {}

  protected readonly Patterns = Patterns;
  protected readonly Animations = Animations;
}
