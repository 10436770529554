import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NetworkErrorService } from '../network-error.service';
import { PatientDemographics } from '../patient';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export interface MindcapPatientStatus {
  info: MindcapPhaseInfo;
  baseline: MindcapBaselineInfo;
  goldBadges: MindcapBadgeSummary;
  silverBadges: MindcapBadgeSummary;
  trophy: MindcapTrophy;
  phaseChanges: MindcapPhaseChange[];
  weeks: MindcapWeek[];

  practiceTestCount: number;
  normalTestCount: number;
  testingTestCount: number;
  testingStart: Date;
  sunday: Date;
}

export interface MindcapPhaseInfo {
  numbersPracticeCount: number;
  nextPromis2: Date;
  adjusting: number;
  finalUsabilityTaken: Date;
  symbolsInstructionsSeen: boolean;
  symbolsLastPracticePassed: boolean;
  trailsInstructionsSeen: boolean;
  symbolsPracticePassDate: Date;
  numTestsToBaseline: number;
  normalIntroSeen: boolean;
  nextPromis1: Date;
  symbolsPracticeCount: number;
  novascanInstructionsSeen: boolean;
  timeout: number;
  chemoStart: Date;
  studyStart: Date;
  isTrackingImprovement: boolean;
  numbersPracticePassDate: Date;
  novascanPracticePassDate: Date;
  numbersInstructionsSeen: boolean;
  baselineDate: Date;
  trailsFullCount: number;
  trailsPracticePassDate: Date;
  ctInstructionsSeen: boolean;
  trailsLastPracticePassed: boolean;
  numbersPracticePass: string;
  numbersLastPracticePassed: boolean;
  nextBadgePopup: Date;
  novascanLastPracticePassed: boolean;
  trailsPracticePass: string;
  novascanPracticePass: string;
  testingIntroSeen: boolean;
  currentPhase: number;
  chemoEnd: Date;
  finalUsability: Date;
  trailsPracticeCount: number;
  novascanPracticeCount: number;
  hasLoggedIn: boolean;
  symbolsPracticePass: string;
}

export interface MindcapBaselineInfo {
  numbers_speed: number;
  numbers_thru: number;
  numbers_acc: number;

  symbols_speed: number;
  symbols_thru: number;
  symbols_acc: number;

  trails_a_speed: number;
  trails_b_speed: number;

  symbolsMetricsCompleted: number;
  numbersMetricsCompleted: number;

  numbers_speed_history_stab: number[];
  numbers_speed_history_tau: number[];
  numbers_thru_history_stab: number[];
  numbers_thru_history_tau: number[];

  symbols_speed_history_stab: number[];
  symbols_speed_history_tau: number[];
  symbols_thru_history_stab: number[];
  symbols_thru_history_tau: number[];

  runs: string[];
}

export interface MindcapTrophy {
  awarded: Date;
  level: number;
}

export interface MindcapDateRange {
  start: Date;
  end: Date;
}

export interface MindcapBadgeSummary {
  sunday: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  range: MindcapDateRange;
}

export interface MindcapPhaseChange {
  newPhase: number;
  oldPhase: number;
  nextPhase: number;
  timestamp: Date;
  timestamp_exit: Date;
  source: string;
}

export interface MindcapWeek {
  start: Date;
  end: Date;
  index: number;
  silver: number;
  gold: number;
  trophy: boolean;
  goldDaysThisWeek: number;
  badges: MindcapBadge[];
}

export interface MindcapBadge {
  awarded: Date;
  // 0 = silver
  // 1 = gold
  type: number;
}

@Injectable({
  providedIn: 'root',
})
export class MindcapPhaseService {
  phaseInfoUrl: string = environment.mindcapUrl + '/patient/show';
  phaseChangeUrl: string = environment.mindcapUrl + '/mindCapTest/forcePhase';

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService
  ) {}

  getPhaseInfo(patientGuid: string): Observable<MindcapPatientStatus> {
    let params = new HttpParams();
    params = params.append('guid', patientGuid);

    return this.http
      .get<MindcapPatientStatus>(this.phaseInfoUrl, { params })
      .pipe(
        catchError(
          this.networkErrorService.handleError<MindcapPatientStatus>(
            'MindcapPatientStatus',
            undefined,
            404
          )
        )
      );
  }

  changePhase(patientGuid: string, phase: number): Observable<string> {
    let params = new HttpParams();
    params = params.append('guid', patientGuid);
    params = params.append('phase', phase);

    return this.http
      .get<string>(this.phaseChangeUrl, { params })
      .pipe(
        catchError(
          this.networkErrorService.handleError<string>(
            'MindcapPatientStatus',
            undefined
          )
        )
      );
  }
}
