import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButton, MatButtonModule } from '@angular/material/button';
import {
  Animations,
  Contrast,
  Exercises,
  Patterns,
  Size,
  Speed,
  Therapies,
  Therapy,
  VestibularService,
} from '../vestibular.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { VestibularPreviewComponent } from '../vestibular-preview/vestibular-preview.component';
import { Background } from '../background';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { VorFavoritesService } from '../vor-favorites.service';

@Component({
  selector: 'app-vor-editor',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    ReactiveFormsModule,
    VestibularPreviewComponent,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
  ],
  templateUrl: './vor-editor.component.html',
  styleUrls: ['./vor-editor.component.scss'],
})
export class VorEditorComponent implements OnInit, OnChanges {
  @ViewChild('preview')
  preview?: VestibularPreviewComponent;

  @ViewChild('save')
  saveButton?: MatButton;

  @Input()
  patientGuid: string = '';

  @Input()
  therapyId?: number;

  @Input('mode')
  mode: Therapies = Therapies.Vorx1Exercise;

  @Input()
  studyId: string = '';

  @Output('modeChange') modeChange = new EventEmitter<Therapies>();

  isVertical: boolean = true;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mode'] && !this.therapyId) {
      console.log(`Mode changing, setting to ${changes['mode'].currentValue}`);
      this.mode = changes['mode'].currentValue;
      console.log(this.mode);
      if (this.mode == 0 && this.therapy) {
        this.therapy.speed = 80;
      } else if (this.mode == 1 && this.therapy) {
        this.therapy.speed = 50;
      } else if (this.mode == 3 && this.therapy) {
        this.therapy.speed = 0;
      }
    }
  }

  therapy?: Therapy;

  showBgSize: boolean = false;
  showBgContrast: boolean = false;
  showBackgroundDirection: boolean = false;
  showBackgroundSpeed: boolean = false;

  // Animations
  vertical: boolean = false;
  horizontal: boolean = false;
  rotate: boolean = false;
  reversed: boolean = false;

  useMediumSize: boolean = false;

  background: string = '';

  creationMode: boolean = false;

  backgroundOptions: Background[] = [
    new Background(
      'No Pattern',
      Patterns.NoPattern,
      'empty',
      false,
      false,
      false,
      false
    ),
    new Background(
      'Vertical Bars',
      Patterns.VerticalBars,
      'vert_stripes',
      false,
      true,
      true,
      true
    ),
    new Background(
      'Horizontal Bars',
      Patterns.HorizontalBars,
      'horiz_stripes',
      false,
      true,
      true,
      true
    ),
    new Background(
      'Checkerboard',
      Patterns.Checkerboard,
      'checker',
      false,
      true,
      true,
      false
    ),
    new Background(
      'Contrasting',
      Patterns.Contrasting,
      'complex',
      true,
      true,
      true,
      false
    ),
    new Background('Dots', Patterns.Dots, 'dots', false, false, true, false),
  ];

  fontColors = [
    {
      name: 'black',
      value: '#000000',
    },
    {
      name: 'white',
      value: '#ffffff',
    },
    {
      name: 'dark green',
      value: '#476534',
    },
    {
      name: 'orange',
      value: '#ff8000',
    },
    {
      name: 'teal',
      value: '#266280',
    },
    {
      name: 'red',
      value: '#ff0000',
    },
    {
      name: 'yellow',
      value: '#ffff00',
    },
    {
      name: 'green',
      value: '#008000',
    },
    {
      name: 'blue',
      value: '#0000ff',
    },
    {
      name: 'pink',
      value: '#ee82ee',
    },
  ];

  backgroundColors = [
    {
      name: 'black',
      value: '#000000',
    },
    {
      name: 'white',
      value: '#ffffff',
    },
    {
      name: 'light green',
      value: '#eeffe3',
    },
    {
      name: 'orange',
      value: '#ff8000',
    },
    {
      name: 'teal',
      value: '#266280',
    },
    {
      name: 'red',
      value: '#ff0000',
    },
    {
      name: 'yellow',
      value: '#ffff00',
    },
    {
      name: 'green',
      value: '#008000',
    },
    {
      name: 'blue',
      value: '#0000ff',
    },
    {
      name: 'pink',
      value: '#ee82ee',
    },
  ];

  selectedColor = '';
  transparentBackground = 'transparent';

  constructor(
    private router: Router,
    private vestibularService: VestibularService,
    private route: ActivatedRoute,
    private vorFavoritesService: VorFavoritesService
  ) {}

  ngOnInit() {
    console.log(this.patientGuid);
    document.body.scrollTop = 0;
    if (this.therapyId) {
      console.log('THERAPY ID GETTING PASSED');
      this.vestibularService
        .getTherapy(this.therapyId)
        .subscribe((therapy: any) => {
          this.creationMode = false;
          switch (therapy?.exerciseType) {
            case Exercises.VerticalVor:
            case Exercises.HorizontalVor:
              this.mode = +Therapies.Vorx1Exercise;
              break;
            case Exercises.HorizontalVor2:
            case Exercises.VerticalVor2:
              this.mode = +Therapies.VorCancellation;
              break;
            case Exercises.GenericExercise:
              this.mode = +Therapies.GenericExercise;
              break;
          }
          this.modeChange.emit(this.mode);
          this.isVertical =
            therapy.exerciseType == Exercises.VerticalVor2 ||
            therapy.exerciseType == Exercises.VerticalVor;
          this.therapy = therapy;
          this.updateBackgrounds();
        });
    } else {
      console.log('NO THERAPY ID GETTING PASSED');
      this.creationMode = true;
      // Create Therapy
      this.therapy = {
        id: -1,
        background: 0,
        backgroundColor: '#eeffe3',
        backgroundContrast: Contrast.Low,
        backgroundDirection: Animations.None,
        backgroundPattern: Patterns.NoPattern,
        backgroundSize: Size.Small,
        backgroundSpeed: Speed.Slow,
        comment: '',
        description: '',
        distance: 3,
        dosage: 3,
        duration: 30,
        // effectiveEnd: new Date(),
        effectiveEnd: null,
        effectiveStart: '2023-11-01T18:17:20Z',
        exerciseType:
          this.mode == Therapies.Vorx1Exercise
            ? Exercises.VerticalVor
            : Exercises.VerticalVor2,
        fontColor: '#476534',
        fontSize: 14,
        optoType: 'X',
        recordVideo: false,
        speed: this.mode == Therapies.Vorx1Exercise ? 80 : 50,
        uploadVideo: false,
        // id: -1,
        // patient: val['patientGuid'],
        patientGuid: this.patientGuid,
        copiedFrom: null,
        dateCreated: '2023-11-01T18:17:20Z',
        lastUpdated: '2023-11-01T18:17:20Z',
      };
      console.log(this.therapy.exerciseType);
      this.isVertical = true;

      this.updateBackgrounds();
    }
  }

  updateBackgrounds() {
    for (let back of this.backgroundOptions) {
      back.update(
        this.therapy?.backgroundContrast ?? 0,
        this.therapy?.backgroundSize ?? 0
      );
    }

    let pattern = this.therapy?.backgroundPattern ?? 0;
    this.showBgSize = this.backgroundOptions[pattern].useSize;
    this.showBgContrast = this.backgroundOptions[pattern].useContrast;
    this.showBackgroundDirection = this.backgroundOptions[pattern].useDirection;

    this.vertical = false;
    this.horizontal = false;
    this.rotate = false;

    if (
      pattern == Patterns.VerticalBars ||
      pattern == Patterns.Checkerboard ||
      pattern == Patterns.Contrasting ||
      pattern == Patterns.Dots
    ) {
      this.vertical = true;
    }

    if (
      pattern == Patterns.HorizontalBars ||
      pattern == Patterns.Checkerboard ||
      pattern == Patterns.Contrasting ||
      pattern == Patterns.Dots
    ) {
      this.horizontal = true;
    }

    if (
      pattern == Patterns.Checkerboard ||
      pattern == Patterns.Contrasting ||
      pattern == Patterns.Dots
    ) {
      this.rotate = true;
    }

    this.reversed = this.therapy?.backgroundDirection == 1;
    this.showBackgroundSpeed = this.vertical || this.horizontal || this.rotate;

    this.background =
      this.backgroundOptions[this.therapy?.backgroundPattern ?? 0].src;

    this.useMediumSize =
      this.backgroundOptions[
        this.therapy?.backgroundPattern ?? 0
      ].useMediumSize;
  }

  onSave() {
    if (this.therapy) {
      if (this.saveButton) {
        let saveButton = this.saveButton;
        saveButton.disabled = true;

        switch (+this.mode) {
          case Therapies.Vorx1Exercise:
            this.therapy.exerciseType = this.isVertical
              ? Exercises.VerticalVor
              : Exercises.HorizontalVor;
            break;
          case Therapies.VorCancellation:
            this.therapy.exerciseType = this.isVertical
              ? Exercises.VerticalVor2
              : Exercises.HorizontalVor2;
            break;
          case Therapies.GenericExercise:
            this.therapy.exerciseType = Exercises.GenericExercise;
            break;
        }

        this.vestibularService.save(this.therapy).subscribe({
          next: (x) => {
            saveButton.disabled = false;
            this.router.navigate(['../../../../'], { relativeTo: this.route });
          },

          error: (x) => {
            saveButton.disabled = false;
          },
        });
      }
    }
  }

  onCreate() {
    if (this.therapy && this.patientGuid == undefined) {
      switch (+this.mode) {
        case Therapies.Vorx1Exercise:
          this.therapy.exerciseType = this.isVertical
            ? Exercises.VerticalVor
            : Exercises.HorizontalVor;
          break;
        case Therapies.VorCancellation:
          this.therapy.exerciseType = this.isVertical
            ? Exercises.VerticalVor2
            : Exercises.HorizontalVor2;
          break;
        case Therapies.GenericExercise:
          this.therapy.exerciseType = Exercises.GenericExercise;
          break;
      }
      this.vorFavoritesService
        .createFavorite(this.therapy, this.studyId)
        .subscribe({
          next: (x) => {
            if (this.saveButton) {
              this.saveButton.disabled = false;
            }
            this.router.navigate(['/home/study', this.studyId]);
          },
          error: (x) => {
            if (this.saveButton) {
              this.saveButton.disabled = false;
            }
          },
        });
    }
    if (this.therapy && this.patientGuid != undefined) {
      switch (+this.mode) {
        case Therapies.Vorx1Exercise:
          this.therapy.exerciseType = this.isVertical
            ? Exercises.VerticalVor
            : Exercises.HorizontalVor;
          break;
        case Therapies.VorCancellation:
          this.therapy.exerciseType = this.isVertical
            ? Exercises.VerticalVor2
            : Exercises.HorizontalVor2;
          break;
        case Therapies.GenericExercise:
          this.therapy.exerciseType = Exercises.GenericExercise;
          break;
      }
      this.vestibularService.createTherapy(this.therapy).subscribe({
        next: (x) => {
          if (this.saveButton) {
            this.saveButton.disabled = false;
          }
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: (x) => {
          if (this.saveButton) {
            this.saveButton.disabled = false;
          }
        },
      });
    }
  }

  onColorChange(event: Event) {
    this.selectedColor = (event.target as HTMLInputElement).value;
    console.log(this.selectedColor);
  }

  protected readonly Animations = Animations;
  protected readonly Speed = Speed;
  protected readonly Size = Size;
  protected readonly Contrast = Contrast;
  protected readonly Therapies = Therapies;
}
