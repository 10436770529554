import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TrainingRequestListService {


  constructor(private http: HttpClient) { }

  getData(): Observable<HttpEvent<any>> {

    const req = new HttpRequest('GET', `${environment.mdermUrl}/api/getTrainingRequests`, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  getURL(requestId: string): Observable<HttpEvent<any>> {

    const req = new HttpRequest('GET', `${environment.mdermUrl}/api/getTrainingRequestDataURLs/${requestId}`, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }
}
