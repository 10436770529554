import {Component, Input, OnInit} from '@angular/core';
import {MindcapService} from "../../mindcap.service";
import {MindcapEventGraphComponent} from "../mindcap-event-graph/mindcap-event=graph.component";
import {SpiderChartComponent} from "../spider-chart/spider-chart.component";
import {Subject} from "rxjs";
import {MindcapPatientCharts} from "../../patient";

@Component({
  selector: 'app-mindcap-graphs',
  templateUrl: './mindcap-graphs.component.html',
  styleUrls: ['./mindcap-graphs.component.scss'],
  imports: [
    MindcapEventGraphComponent,
    SpiderChartComponent
  ],
  standalone: true
})
export class MindcapGraphsComponent implements OnInit {
  @Input('patientGuid')
  patientGuid: string = '';

  loadData: Subject<MindcapPatientCharts> = new Subject<MindcapPatientCharts>();

  lineSelected: Subject<number> = new Subject<number>();



  constructor(
    private mindcapService: MindcapService
  ) {}


  ngOnInit() {
    this.mindcapService.getPatientCharts(this.patientGuid).subscribe((x : MindcapPatientCharts) => {
      console.log(`Updating Spider Chart for ${this.patientGuid}, Got ${x}`);
      this.loadData.next( x );
    });
  }


  onLineSelected( index : any )
  {
    this.lineSelected.next( index )
  }

}
