// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
}

.cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
}

.card {
  padding: 5px;
}

@media (min-width: 1200px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
.wrap-text {
  text-wrap: wrap;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.responsive-grid {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  display: grid;
  gap: 5px;
}

h4 {
  height: 5px;
}

.float-left {
  float: left;
  margin: 0;
  padding: 0;
}

.float-right {
  float: right;
  margin: 0;
  padding-right: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/report-page/report-page.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAGA;EACE,iBAAA;EACA,cAAA;EACA,aAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE;IAAS,qCAAA;EACT;AACF;AACA;EACE,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,4DAAA;EACA,aAAA;EACA,QAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;EACA,SAAA;EACA,UAAA;AACF;;AAGA;EACE,YAAA;EACF,SAAA;EACA,mBAAA;AAAA","sourcesContent":[":host {\n  flex-grow: 1;\n}\n\n\n.cards {\n  max-width: 1200px;\n  margin: 0 auto;\n  display: grid;\n}\n\n.card {\n  padding: 5px;\n}\n\n@media (min-width: 1200px) {\n  .cards { grid-template-columns: repeat(2, 1fr); }\n}\n\n.wrap-text {\n  text-wrap: wrap;\n}\n\n.container {\n  max-width: 1200px;\n  margin: 0 auto;\n}\n\n.responsive-grid {\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  display: grid;\n  gap: 5px;\n}\n\nh4 {\n  height: 5px;\n}\n\n.float-left {\n  float:left;\n  margin:0;\n  padding:0;\n\n}\n\n.float-right {\n  float:right;\nmargin: 0;\npadding-right: 50px;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
