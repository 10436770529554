import { Component, Input, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { PatientService } from '../patient.service';
import { SurveyDetails, SurveyResponses } from '../patient';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MindcapService } from '../mindcap.service';

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.css'],
  standalone: true,
  imports: [
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgFor,
  ],
})
export class SurveyDetailsComponent implements OnInit {
  @Input('id')
  surveyId: number = 0;
  patientId: string = '';
  surveyName = '';

  public survey: SurveyResponses | undefined;

  displayedColumns: string[] = [
    'number',
    'question',
    'responseText',
    'responseChoices',
  ];

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private breadcrumbService: BreadcrumbService,
    private mindcapService: MindcapService,
  ) {
    // @ts-ignore
    this.surveyId = +this.route.snapshot.paramMap.get('id') || 0;
    this.patientId = this.route.snapshot.paramMap.get('guid') || '';
    this.mindcapService.getSurveysForPatient(this.patientId).subscribe((x) => {
      this.surveyName = x.responses![this.surveyId - 1].surveyName;
      console.log(this.surveyName);
      this.breadcrumbService.set('@surveyName', 'Survey: ' + this.surveyName);
    });
  }

  ngOnInit() {
    this.getTestDetails();
  }

  getTestDetails(): void {
    this.mindcapService
      .getSurveyResponses(this.surveyId)
      .subscribe((response) => {
        this.survey = response;
        console.log(this.survey);
      });
  }
}
