import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PatientList } from './patient';
import { PatientService } from './patient.service';
import { NetworkErrorService } from './network-error.service';
import { DermatologyService } from './services/dermatology.service';

export interface MdermReportCount {
  count: number;
  patientGuid: string;
}

export interface MostRecentDates {
  date: string;
  patientGuid: string;
}
export interface MdermDashboard {
  reports: MdermReportCount[];
  selectedPatients: PatientList;
  mostRecentReport: MostRecentDates[];
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private dashboardUrl = `${this.dermatologyService.mdermUrl}/home/dashboard`; // URL to web api

  constructor(
    private http: HttpClient,
    private patientService: PatientService,
    private networkErrorService: NetworkErrorService,
    private dermatologyService: DermatologyService,
  ) {}

  getDashboard(): Observable<MdermDashboard> {
    let params = new HttpParams();
    // params = params.append("selectedStudies", filter.studyId);
    // params = params.append("selectedCohorts", filter.cohortId);
    // params = params.append("filterAction", "selectedCohorts");

    return this.http.get<MdermDashboard>(this.dashboardUrl, { params }).pipe(
      tap((_) => this.networkErrorService.log('fetched dashboard')),
      catchError(
        this.networkErrorService.handleError<MdermDashboard>(
          'getDashboard',
          undefined,
        ),
      ),
    );
  }
}
