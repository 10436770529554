import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CohortDescription, PatientDescription } from '../../patient';
import {
  ActivatedRoute,
  Route,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import { CohortService } from '../../cohort.service';
import { PatientService } from '../../patient.service';
import {
  CognitiveDashboardTableData,
  MindcapService,
} from '../../mindcap.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { PatientCohortTableData } from '../../patient-list/patient-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {
  SelectionButtonInfo,
  SelectionService,
} from 'src/app/selection.service';

@Component({
  selector: 'app-cognitive-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    RouterLink,
    MatCheckboxModule,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './cognitive-dashboard.component.html',
  styleUrls: ['./cognitive-dashboard.component.scss'],
})
export class CognitiveDashboardComponent implements OnInit, AfterViewInit {
  @Input('cohort')
  cohort!: CohortDescription;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  DATA: CognitiveDashboardTableData[] = [];

  dataSource = new MatTableDataSource<CognitiveDashboardTableData>();
  displayedColumns: string[] = [
    'selection',
    'patientName',
    'engagement',
    'stage',
    'lastChange',
    'lastOnset',
    'lastEnd',
    'duration',
  ];

  selectionButtons: SelectionButtonInfo[] = [];

  constructor(
    private router: Router,
    public cohortService: CohortService,
    public patientService: PatientService,
    public mindcapService: MindcapService,
    private selectionService: SelectionService,
  ) {
    this.dataSource.data = this.DATA;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    // if (this.cohort) {
    //   this.mindcapService.getDashboard(this.cohort).subscribe((dashboard) => {
    //     this.DATA = dashboard;
    //     this.dataSource.data = this.DATA;
    //   });
    // }
    if (this.cohort) {
      this.mindcapService
        .getDashboardNew(this.cohort.uuid)
        .subscribe((dashboard) => {
          if (dashboard) {
            this.DATA = dashboard.patients;
            this.dataSource.data = this.DATA;
          }
        });
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //adds the selected patient to selectionButtons if it was checked and removes it if it was unchecked
  selectionChanged(checked: boolean, patientGuid: string, name: string) {
    let newEntry: SelectionButtonInfo = { name: name, guid: patientGuid };
    if (checked) {
      this.selectionButtons.push(newEntry);
    } else {
      this.selectionButtons = this.selectionButtons.filter(
        (selection) =>
          !(selection.guid == patientGuid && selection.name == name),
      );
    }
  }

  patientSelected(selected: SelectionButtonInfo) {
    this.selectionService.setData(this.selectionButtons);

    this.router.navigate(['/home/patient'], {
      queryParams: {
        id: selected.guid,
      },
    });
  }
}
export { SelectionButtonInfo };
