import { Component, OnInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataSetUploadService } from '../data-set-upload.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-set-upload',
  templateUrl: './data-set-upload.component.html',
  styleUrls: ['./data-set-upload.component.scss'],
  standalone: true,
  imports: [
    MatGridListModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    RouterModule,
  ],
})
export class DataSetUploadComponent implements OnInit {
  selectedFiles?: FileList;
  currentFile?: File;
  preview = '';
  name = '';
  loading = false;
  dataSetName = '';
  dataSetComments = '';
  dataSetSource = '';
  dataSetPOC = '';
  dataSetIsDeidentified = false;

  showErrorMessage = false;
  errorMessage = 'Dataset exists, please try again with a new name';
  showSuccessMessage = false;
  successMessage = 'Dataset uploaded successfully';

  constructor(
    private uploadService: DataSetUploadService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  selectFile(event: any): void {
    this.preview = '';
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        //   this.preview = '';
        this.currentFile = file;

        //   const reader = new FileReader();

        //   reader.onload = (e: any) => {
        //     this.preview = e.target.result;
        //   };

        //   reader.readAsDataURL(this.currentFile);
      }
    }
  }

  upload(): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.uploadService
          .upload(
            this.currentFile,
            this.dataSetName,
            this.dataSetComments,
            this.dataSetSource,
            this.dataSetPOC,
            this.dataSetIsDeidentified
          )
          .subscribe({
            next: (event: any) => {
              this.showSuccessMessage = false;
              this.showErrorMessage = false;
              this.loading = true;
            },
            error: (err: any) => {
              this.showSuccessMessage = false;
              this.loading = false;
              if (
                err.error.message ==
                'Dataset exists, please try again with a new name'
              ) {
                this.showErrorMessage = true;
              } else {
                this.showErrorMessage = true;
                this.errorMessage = 'Server Error';
              }
            },
            complete: () => {
              this.loading = false;
              this.showSuccessMessage = true;
              this.selectedFiles = undefined;
              this.router.navigate(['/home', 'datasetlist']);
            },
          });
      }
    }
  }
}
